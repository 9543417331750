import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { IconButton, Notification, Pagination, AuthAdminView } from './../../../components'
import { Filter, Table, ModalAdd, ModalEdit } from './components'

function PopupPage(props) {
    const [popupList, setPopupList] = useState(null)

    const [isShowModalPopupAdd, setIsShowModalPopupAdd] = useState(false)

    const [partnerGoupList, setPartnerGroupList] = useState([])

    const [popupEditInfo, setPopupEditInfo] = useState({
        isShow: false,
        info: {}
    })

    const [filters, setFilters] = useState({
        keyword: '',
        pageItem: 10,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Popup'
        loadPartnerGroup()
    }, [])

    useEffect(() => {
        const url = `/admin/popup/filter?keyword=${filters.keyword}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setPopupList(data.Result.Data)
            }
        })
    }, [filters])

    const loadPartnerGroup = () => {
        const url = '/admin/partner-group/get-all'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setPartnerGroupList(data.Result)
            }
        })
    }

    const reloadPopup = () => {
        setFilters({
            ...filters,
            pageIndex: 1
        })
    }

    return (
        <Fragment>
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
            />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="popup_add">
                                <IconButton
                                    className="mb-2"
                                    text="Thêm mới"
                                    icon={<IoAddCircleOutline />}
                                    onClick={() => {
                                        setIsShowModalPopupAdd(true)
                                    }} />
                            </AuthAdminView>
                        </div>
                    </div>

                    <Table
                        pageItem={filters.pageItem}
                        pageIndex={filters.pageIndex}
                        onEdit={item => {
                            const url = `/admin/popup/get-by-id?popupId=${item.PopupId}`
                            axios.get(url).then(res => {
                                const data = res.data
                                if (data.IsSuccess) {
                                    setPopupEditInfo({
                                        isShow: true,
                                        info: data.Result
                                    })
                                }
                            })
                        }}
                        onDelete={item => {
                            Notification.confirmHtml('Bạn có chắc muốn xóa popup', item.Title, () => {
                                const url = `/admin/popup/delete?popupId=${item.PopupId}`
                                axios.post(url).then(res => {
                                    const data = res.data
                                    if (data.IsSuccess) {
                                        reloadPopup()
                                        Notification.success('Xóa popup thành công')
                                    }
                                })
                            })
                        }}
                        data={popupList}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>

            <ModalAdd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/popup/create'
                    axios.post(url, formData).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            reloadPopup()
                            setIsShowModalPopupAdd(false)
                            Notification.success('Thêm mới popup thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalPopupAdd(false)
                }}
                isShow={isShowModalPopupAdd}
                partnerGroup={partnerGoupList}
            />

            <ModalEdit
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/popup/update'
                    axios.post(url, formData).then(res => {
                        const data = res.data
                        if (data.IsSuccess) {
                            reloadPopup()
                            setPopupEditInfo({
                                isShow: false,
                                info: {}
                            })
                            Notification.success('Chỉnh sửa popup thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setPopupEditInfo({
                        isShow: false,
                        info: {}
                    })
                }}
                isShow={popupEditInfo.isShow}
                info={popupEditInfo.info}
            />
        </Fragment>
    )
}

export default PopupPage