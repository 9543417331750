import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

function CustomSelect(props) {
    const { options, value, onChange, placeholder, noOptionsMessage, isSearchable, isClearable } = props

    const [currentValue, setCurrentValue] = useState(null)

    useEffect(() => {
        const newValues = options.filter(n => n.value === value)
        if (newValues.length !== 0) {
            setCurrentValue(newValues[0])
        }
        else {
            setCurrentValue(null)
        }
        // eslint-disable-next-line
    }, [value])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="custom-select"
            isSearchable={isSearchable}
            isClearable={isClearable}
            options={options}
            value={currentValue}
            onChange={item => {
                if (item !== null) {
                    onChange(item.value)
                }
                else {
                    onChange(0)
                }
            }}
            placeholder={placeholder}
            noOptionsMessage={() => noOptionsMessage}
        />
    )
}

CustomSelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    isSearchable: PropTypes.bool,
    isClearable: PropTypes.bool,
}

CustomSelect.detaultProps = {
    options: [
        {
            value: 1,
            label: 'Option 1'
        },
        {
            value: 1,
            label: 'Option 2'
        }
    ],
    value: 1,
    onChange: () => { },
    placeholder: '',
    noOptionsMessage: '',
    isSearchable: true,
    isClearable: false
}

export default CustomSelect