import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import { SaveButton, Notification } from "../../../../../components";
import { useEffect } from "react";

const invalidValue = "Giá trị không hợp lệ";

const schema = yup.object().shape({
  AffAmount: yup
    .number()
    .required("Vui lòng nhập giá bán")
    .min(0, invalidValue)
    .max(2_000_000_000, invalidValue),
});

export default function SettingAffiliate(props) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      AffAmount: 0,
    },
  });

  const { setting } = props;

  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (setting !== null) {
      setValue('AffAmount', setting.AffAmount);
    }
    // eslint-disable-next-line
  }, [setting]);

  return (
    <div className="card">
      {/* Begin form */}
      <form
        onSubmit={handleSubmit((formData) => {
          setIsBusy(true);
          const url = "/admin/setting/update-aff";
          axios
            .post(url, formData)
            .then(({ data }) => {
              if (data.IsSuccess) {
                Notification.success("Chỉnh sửa cấu hình affiliate thành công");
                // reset();
              }
            })
            .finally(() => setIsBusy(false));
        })}
      >
        <div className="card-body">
          <h5 className="card-title">Cấu affiliate</h5>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 position-relative">
              <label className="form-label">
                Tiền thưởng <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <Controller
                  name="AffAmount"
                  control={control}
                  render={({ field }) => {
                    return (
                      <NumberFormat
                        {...field}
                        className="form-control"
                        // thousandSeparator={"."}
                        // decimalSeparator={","}
                        allowNegative={false}
                        defaultValue={0}
                        onValueChange={(c) => {
                          field.onChange(c.value);
                        }}
                        decimalScale={0}
                      />
                    );
                  }}
                />
                <span className="input-group-text">VNĐ</span>
              </div>
              {errors.AffAmount && (
                <div className="validation-message">
                  {errors.AffAmount.message}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="button-list">
            <SaveButton type="submit" isBusy={isBusy} />
          </div>
        </div>
      </form>
      {/* End form */}
    </div>
  );
}
