import PropTypes from 'prop-types'
import React from 'react'
import { IoFileTrayOutline } from 'react-icons/io5'

const EmptyRow = props => {
    return (
        <tr className="cursor-default">
            <td colSpan={props.colSpan} className="text-center">
                <IoFileTrayOutline className="fs-1 mt-2" />
                <p>{props.title}</p>
            </td>
        </tr>
    )
}

EmptyRow.propTypes = {
    colSpan: PropTypes.number,
    title: PropTypes.string
}

EmptyRow.defaultProps = {
    colSpan: 1,
    title: 'Không tìm thấy dữ liệu'
}

export default EmptyRow