import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import { IoTrashOutline } from 'react-icons/io5'
import NumberFormat from 'react-number-format'
import * as yup from "yup"
import {
    AvatarRow, CloseButton, Config, CustomSelect, EmptyRow,
    FormatNumber, InputSelect, LoadingRow, Notification, SaveButton
} from './../../../../../components'

const schema = yup.object().shape({
    OrderId: yup.number(),
    CustomerName: yup.string().max(100, 'Tên người nhận quá dài'),
    CustomerPhone: yup.string().max(20, 'Số điện thoại quá dài'),
    CustomerAddress: yup.string().max(200, 'Địa chỉ quá dài'),
    CustomerWardId: yup.number().nullable(),
    WarehouseNote: yup.string().max(1_000, 'Ghi chú gửi kho quá dài'),
    ShippingUnitId: yup.number().required('Vui lòng chọn đơn vị giao hàng'),
    WaybillCode: yup.string().required('Vui lòng nhập mã đơn hàng').max(50, 'Mã đơn hàng quá dài'),
    ShippingWeight: yup.number().max(2_000_000_000, 'Trọng lượng quá lớn'),
    ShippingMethodId: yup.number().required('Vui lòng chọn phương thức vận chuyển'),
    ShippingFeeTypeId: yup.number().required('Vui lòng chọn phí vận chuyển'),
    ShippingFeeAmount: yup.number().max(2_000_000_000, 'Phí vận chuyển quá lớn'),
    ShippingNote: yup.string().max(1_000, 'Ghi chú vận chuyển quá dài'),
    OrderTypeId: yup.number().required('Vui lòng chọn loại đơn hàng'),
    Products: yup.array()
})

const orderTypes = Config.orderTypes

const shippingTypes = Config.shippingTypes

function ModalEdit(props) {
    const { setValue, control, register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ShippingNote: '',
            OrderTypeId: 1,
            ShippingFeeTypeId: 1,
            ShippingFeeAmount: 0,
            ShippingWeight: 0
        }
    })

    const [isBusy, setIsBusy] = useState(false)

    const [provinces, setProvinces] = useState([])

    const [provinceId, setProvinceId] = useState(0)

    const [districts, setDistricts] = useState([])

    const [districtId, setDistrictId] = useState(0)

    const [wards, setWards] = useState([])

    const [shippingUnits, setShippingUnits] = useState([])

    const [shippingMethods, setShippingMethods] = useState([])

    const [tienHang, setTienHang] = useState()

    useEffect(() => {
        const provinceArray = props.provinces.map(item => {
            return {
                value: item.ProvinceId,
                label: `${item.Prefix} ${item.ProvinceName}`
            }
        })
        setProvinces(provinceArray)
    }, [props.provinces])

    useEffect(() => {
        const districtArray = props.districts.map(item => {
            return {
                value: item.DistrictId,
                label: `${item.Prefix} ${item.DistrictName}`
            }
        })
        setDistricts(districtArray)
    }, [props.districts])

    useEffect(() => {
        const wardArray = props.wards.map(item => {
            return {
                value: item.WardId,
                label: `${item.Prefix} ${item.WardName}`
            }
        })
        setWards(wardArray)
    }, [props.wards])

    useEffect(() => {
        const shippingUnitArray = props.shippingUnits.map(item => {
            return {
                value: item.ShippingUnitId,
                label: item.ShippingUnitName
            }
        })
        setShippingUnits(shippingUnitArray)
    }, [props.shippingUnits])

    useEffect(() => {
        const shippingMethodArray = props.shippingMethods.map(item => {
            return {
                value: item.ShippingMethodId,
                label: item.ShippingMethodName
            }
        })
        setShippingMethods(shippingMethodArray)
    }, [props.shippingMethods])

    const renderProduct = () => {
        const products = props.detail.Products
        if (products === null || products === undefined) return <LoadingRow colSpan={6} />
        else if (products.length === 0) return <EmptyRow colSpan={6} />
        else return products.map((item, index) => <tr key={item.ProductId}>
            <td className="text-center">{index + 1}</td>
            <td>
                <AvatarRow
                    url={item.AvatarUrl}
                />
                {item.ProductName}
            </td>
            <td className="text-end text-primary">{item.Quantity}</td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {item.PartnerUnitPrice}
                </FormatNumber>
            </td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {item.TotalAmount}
                </FormatNumber>
            </td>
            <td className="text-end text-danger">
                <IoTrashOutline className="cursor-point" onClick={() => props.removeProduct(item.ProductId)} />
            </td>
        </tr>)
    }

    useEffect(() => {
        const products = props.detail.Products
        if (products !== undefined && products !== null) {
            const tien_hang = products.reduce((prev, current) => prev + current.TotalAmount, 0)
            setTienHang(tien_hang)

            const formProducts = products.map(item => {
                return {
                    ProductId: item.ProductId,
                    Quantity: item.Quantity
                }
            })
            setValue('Products', formProducts)
        }

        // eslint-disable-next-line
    }, [props.detail.Products])

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="xl"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                const detail = props.detail
                setValue('OrderId', detail.OrderId)
                setValue('CustomerName', detail.ReceiverName)
                setValue('CustomerPhone', detail.CustomerPhone)
                setValue('CustomerAddress', detail.CustomerAddress)
                setValue('CustomerWardId', detail.CustomerWardId)
                setValue('WarehouseNote', detail.WarehouseNote)
                setValue('ShippingUnitId', detail.ShippingUnitId)
                setValue('WaybillCode', detail.WaybillCode)
                setValue('ShippingWeight', detail.WeightInGram)
                setValue('ShippingMethodId', detail.ShippingMethodId)
                setValue('ShippingFeeTypeId', detail.ShippingFeeTypeId)
                setValue('ShippingFeeAmount', detail.ShippingFree)
                setValue('ShippingNote', detail.ShippingNote)
                setValue('OrderTypeId', detail.OrderTypeId)
                setProvinceId(detail.CustomerProvinceId)
                setDistrictId(detail.CustomerDistrictId)
                setValue('CustomerWardId', detail.CustomerWardId)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Đặt hàng</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                if (props.detail.Products.length === 0) {
                    Notification.warning('Vui lòng chọn sản phẩm trước khi đặt hàng')
                    return
                }
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="card border-primary border">
                        <div className="card-body">
                            <h5 className="card-title text-primary">Thông tin khách hàng</h5>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Người nhận</label>
                                        <input {...register('CustomerName')} type="text" className="form-control" placeholder="Người nhận" />
                                        {errors.CustomerName && <div className="validation-message">{errors.CustomerName.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Điện thoại</label>
                                        <input {...register('CustomerPhone')} type="text" className="form-control" placeholder="Điện thoại" />
                                        {errors.CustomerPhone && <div className="validation-message">{errors.CustomerPhone.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Địa chỉ</label>
                                        <input {...register('CustomerAddress')} type="text" className="form-control" placeholder="Địa chỉ" />
                                        {errors.CustomerAddress && <div className="validation-message">{errors.CustomerAddress.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position">
                                        <label className="form-label">Tỉnh/Tp</label>
                                        <CustomSelect
                                            options={provinces}
                                            isSearchable={true}
                                            isClearable={true}
                                            value={provinceId}
                                            onChange={val => {
                                                setProvinceId(val)
                                                setDistrictId(null)
                                                setValue('CustomerWardId', null)
                                                props.onProvinceChange(val)
                                            }}
                                            placeholder='Tỉnh/Tp'
                                            noOptionsMessage='Tỉnh/Tp'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position">
                                        <label className="form-label">Quận/Huyện</label>
                                        <CustomSelect
                                            options={districts}
                                            isSearchable={true}
                                            isClearable={false}
                                            value={districtId}
                                            onChange={val => {
                                                setDistrictId(val)
                                                setValue('CustomerWardId', null)
                                                props.onDistrictChange(val)
                                            }}
                                            placeholder='Quận/Huyện'
                                            noOptionsMessage='Quận/Huyện'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position">
                                        <label className="form-label">Phường/Xã</label>
                                        <Controller
                                            control={control}
                                            name="CustomerWardId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <CustomSelect
                                                    options={wards}
                                                    isSearchable={true}
                                                    isClearable={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder='Phường/Xã'
                                                    noOptionsMessage='noOptionsMessage'
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border-primary border">
                        <div className="card-body">
                            <h5 className="card-title text-primary">Danh sách sản phẩm</h5>
                            <div className="relative-position">
                                <div className="table-responsive">
                                    <table className="table table-centered table-avatar mb-2">
                                        <thead>
                                            <tr>
                                                <th className="w-52-px">#</th>
                                                <th>Tên sản phẩm</th>
                                                <th>Số lượng</th>
                                                <th>Giá CTV</th>
                                                <th>Thành tiền</th>
                                                <th className="w-52-px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderProduct()}
                                            <tr>
                                                <td colSpan={4} className="text-end fw-bold">Tổng thành tiền</td>
                                                <td className="text-end text-primary">
                                                    <FormatNumber>
                                                        {tienHang}
                                                    </FormatNumber>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="relative-position mb-2">
                                <label className="form-label">Cộng tác viên thanh toán với kho</label>
                                <div className="table-responsive">
                                    <table className="table table-centered">
                                        <tbody>
                                            <tr>
                                                <td className="text-end w-50-pc">Tiền hàng</td>
                                                <td className="text-primary">
                                                    <FormatNumber>
                                                        {tienHang}
                                                    </FormatNumber>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-end">Phí dropship</td>
                                                <td className="text-primary">
                                                    <FormatNumber>
                                                        {props.detail.DropshipFee}
                                                    </FormatNumber>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-end fw-bold">Cộng tác viên thanh toán</td>
                                                <td className="text-primary">
                                                    <FormatNumber>
                                                        {props.detail.DropshipFee + tienHang}
                                                    </FormatNumber>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="relative-position">
                                <label className="form-label">Ghi chú gửi kho</label>
                                <textarea {...register('WarehouseNote')} rows="2" className="form-control" placeholder="Ghi chú gửi kho"></textarea>
                                {errors.WarehouseNote && <div className="validation-message">{errors.WarehouseNote.message}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="card border-primary border mb-0">
                        <div className="card-body">
                            <h5 className="card-title text-primary mb-2">Vận chuyển</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Đơn vị giao hàng <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="ShippingUnitId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <CustomSelect
                                                    options={shippingUnits}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Đơn vị giao hàng"
                                                    noOptionsMessage="Đơn vị giao hàng"
                                                />
                                            )}
                                        />
                                        {errors.ShippingUnitId && <div className="validation-message">{errors.ShippingUnitId.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Mã vận đơn <span className="text-danger">*</span></label>
                                        <input {...register('WaybillCode')} type="text" className="form-control" placeholder="Mã vận đơn" />
                                        {errors.WaybillCode && <div className="validation-message">{errors.WaybillCode.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Trọng lượng</label>
                                        <div className="input-group">
                                            <Controller
                                                control={control}
                                                name="ShippingWeight"
                                                render={({
                                                    field: { onChange, value }
                                                }) => (
                                                    <NumberFormat
                                                        placeholder='Trọng lượng'
                                                        className="form-control"
                                                        // thousandSeparator={"."}
                                                        // decimalSeparator={","}
                                                        allowNegative={false}
                                                        defaultValue={0}
                                                        decimalScale={0}
                                                        value={value}
                                                        onValueChange={val => onChange(val.value)}
                                                    />
                                                )}
                                            />
                                            <span className="input-group-text">Gram</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Loại đơn <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="OrderTypeId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <InputSelect
                                                    options={orderTypes}
                                                    value={value}
                                                    defaultValue={1}
                                                    name='order-type'
                                                    variant='success'
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                        {errors.OrderTypeId && <div className="validation-message">{errors.OrderTypeId.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Phương thức vận chuyển <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="ShippingMethodId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <CustomSelect
                                                    options={shippingMethods}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder="Phương thức vận chuyển"
                                                    noOptionsMessage="Phương thức vận chuyển"
                                                />
                                            )}
                                        />
                                        {errors.ShippingMethodId && <div className="validation-message">{errors.ShippingMethodId.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Loại phí vận chuyển <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="ShippingFeeTypeId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <InputSelect
                                                    options={shippingTypes}
                                                    value={value}
                                                    defaultValue={1}
                                                    name='shipping-fee-type'
                                                    variant='success'
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                        {errors.ShippingFeeTypeObj && <div className="validation-message">{errors.ShippingFeeTypeObj.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Phí vận chuyển thỏa thuận</label>
                                        <div className="input-group">
                                            <Controller
                                                control={control}
                                                name="ShippingFeeAmount"
                                                render={({
                                                    field: { onChange, value }
                                                }) => (
                                                    <NumberFormat
                                                        placeholder='Phí vận chuyển thỏa thuận'
                                                        className="form-control"
                                                        // thousandSeparator={"."}
                                                        // decimalSeparator={","}
                                                        allowNegative={false}
                                                        defaultValue={0}
                                                        decimalScale={0}
                                                        value={value}
                                                        onValueChange={val => onChange(val.value)}
                                                    />
                                                )}
                                            />
                                            <span className="input-group-text">VNĐ</span>
                                        </div>
                                        {errors.ShippingFeeAmount && <div className="validation-message">{errors.ShippingFeeAmount.message}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="relative-position">
                                <label className="form-label">Ghi chú vận chuyển</label>
                                <textarea {...register('ShippingNote')} rows="2" className="form-control" placeholder="Ghi chú vận chuyển"></textarea>
                                {errors.ShippingNote && <div className="validation-message">{errors.ShippingNote.message}</div>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEdit.propTypes = {
    isShow: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    provinces: PropTypes.array,
    onProvinceChange: PropTypes.func,
    districts: PropTypes.array,
    onDistrictChange: PropTypes.func,
    wards: PropTypes.array,
    shippingUnits: PropTypes.array,
    shippingMethods: PropTypes.array,
    detail: PropTypes.object,
}

ModalEdit.defaultProps = {
    isShow: false,
    onSave: () => { },
    onClose: () => { },
    provinces: [],
    onProvinceChange: () => { },
    districts: [],
    onDistrictChange: () => { },
    wards: [],
    shippingUnits: [],
    shippingMethods: [],
    detail: {}
}

export default ModalEdit