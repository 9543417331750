import { store } from 'react-notifications-component';
import Swal from 'sweetalert2'

const duration = 3_500

const Notification = {
    success: msg => {
        store.addNotification({
            // title: "Wonderful!",
            message: msg,
            type: "success",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: duration,
                pauseOnHover: true
                // onScreen: true
            }
        });
    },
    warning: msg => {
        store.addNotification({
            message: msg,
            type: "warning",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: duration,
                pauseOnHover: true
            }
        });
    },
    danger: msg => {
        store.addNotification({
            message: msg,
            type: "danger",
            insert: "top",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: duration,
                pauseOnHover: true
            }
        });
    },
    confirm: (msg = 'Bạn có chắc muốn thực hiện?', ok, cancel) => {
        Swal.fire({
            // title: 'Xác nhận',
            text: msg,
            icon: 'question',
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Bỏ qua'
        }).then((result) => {
            if (result.isConfirmed) {
                ok();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                if (cancel) {
                    cancel()
                }
            }
        })
    },
    confirmHtml: (msg, objText, ok) => {
        const str = `${msg} <span class="text-danger">${objText}</span> không?`
        Swal.fire({
            html: str,
            icon: 'question',
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Bỏ qua'
        }).then((result) => {
            if (result.isConfirmed) {
                ok();
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }
}

export default Notification