import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoPersonAddOutline } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { Config, SaveButton } from "./../../../components";

const schema = yup.object().shape({
  FullName: yup
    .string()
    .required("Vui lòng nhập họ tên")
    .max(100, "Họ tên quá dài"),
  Phone: yup
    .string()
    .required("Vui lòng nhập số điện thoại")
    .max(20, "Số điện thoại quá dài"),
  Email: yup
    .string()
    .required("Vui lòng nhập email")
    .email("Địa chỉ email không đúng")
    .max(70, "Địa chỉ email quá dài"),
  Password: yup
    .string()
    .required("Vui lòng nhập mật khẩu")
    .max(70, "Mật khẩu quá dài"),
});

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RegisterPage(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const query = useQuery();

  const refCode = query.get("refcode");

  const [message, setMessage] = useState("");

  const history = useHistory();

  const [isBusy, setIsBusy] = useState(false);

  const [refPartnerName, setRefPartnerName] = useState("");

  useEffect(() => {
    if (refCode !== null && refCode !== "") {
      const url = `/partner/partner/get-ref?refCode=${refCode}`;
      axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
          setRefPartnerName(data.Result);
        }
      });
    }
    // eslint-disable-next-line
  }, [refCode]);

  return (
    <div className="auth-fluid">
      <div className="auth-fluid-form-box">
        <div className="align-items-center d-flex h-100">
          <div className="card-body">
            <div className="auth-brand left-0 right-0 text-center">
              <span className="logo-dark">
                <span>
                  <img
                    src="/assets/images/partner-logo.png"
                    alt="Logo"
                    height={Config.partnerLoginLogoHeight}
                  />
                </span>
              </span>
              <span className="logo-light">
                <span>
                  <img
                    src="/assets/images/partner-logo.png"
                    alt="Logo"
                    height={Config.partnerLoginLogoHeight}
                  />
                </span>
              </span>
            </div>

            <h4 className="mt-0">Đăng ký cộng tác viên</h4>

            <form
              onSubmit={handleSubmit((formData) => {
                if (message !== "") setMessage("");
                setIsBusy(true);
                let newFormData = null;
                if (refCode !== "") {
                  newFormData = {
                    ...formData,
                    AffCode: refCode,
                  };
                } else {
                  newFormData = {
                    ...formData,
                  };
                }
                const url = "/partner/partner/register";
                axios.post(url, newFormData).then(({ data }) => {
                  if (data.IsSuccess) {
                    history.replace("/");
                  } else {
                    setMessage(data.Message);
                  }
                  setIsBusy(false);
                });
              })}
            >
              <div className="mb-2 position-relative">
                <label className="form-label">
                  Họ tên <span className="text-danger">*</span>
                </label>
                <input
                  {...register("FullName")}
                  className="form-control"
                  type="text"
                  placeholder="Họ tên"
                />
                {errors.FullName && (
                  <div className="validation-message">
                    {errors.FullName.message}
                  </div>
                )}
              </div>

              <div className="mb-2 position-relative">
                <label className="form-label">
                  Điện thoại <span className="text-danger">*</span>
                </label>
                <input
                  {...register("Phone")}
                  className="form-control"
                  type="text"
                  placeholder="Điện thoại"
                />
                {errors.Phone && (
                  <div className="validation-message">
                    {errors.Phone.message}
                  </div>
                )}
              </div>

              <div className="mb-2 position-relative">
                <label className="form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  {...register("Email")}
                  className="form-control"
                  type="text"
                  placeholder="Email"
                />
                {errors.Email && (
                  <div className="validation-message">
                    {errors.Email.message}
                  </div>
                )}
              </div>

              <div className="mb-2 position-relative">
                <label className="form-label">
                  Mật khẩu <span className="text-danger">*</span>
                </label>
                <input
                  {...register("Password")}
                  className="form-control"
                  type="password"
                  placeholder="Mật khẩu"
                />
                {errors.Password && (
                  <div className="validation-message">
                    {errors.Password.message}
                  </div>
                )}
              </div>

              {refCode !== null ? (
                <div className="mb-2 position-relative">
                  <label className="form-label">Người giới thiệu</label>
                  <input
                    defaultValue={refPartnerName}
                    className="form-control"
                    type="text"
                    placeholder="Người giới thiệu"
                    readOnly
                  />
                </div>
              ) : null}

              {message !== "" ? (
                <div className="mb-2">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="text-center">
                <SaveButton
                  className="w-100"
                  title="Đăng ký"
                  isBusy={isBusy}
                  icon={
                    <IoPersonAddOutline className="mt--3-px me-1 font-17" />
                  }
                />
              </div>

              <footer className="footer footer-alt">
                <p className="text-muted">
                  Bạn đã có tài khoản? Nhấn{" "}
                  <span
                    onClick={() => {
                      history.push("/");
                    }}
                    className="text-danger cursor-point"
                  >
                    <b>vào đây</b>
                  </span>{" "}
                  để đăng nhập
                </p>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
