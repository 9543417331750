import PropTypes from "prop-types";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { CloseButton, EmptyRow } from "./../../../../../components";

function ModalChildren(props) {
  const { partners } = props;

  function renderTable() {
    if (partners.length === 0) return <EmptyRow colSpan={5} />;
    else
      return partners.map((item, index) => (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>{item.FullName}</td>
          <td>{item.Email}</td>
          <td>{item.Phone}</td>
          <td>{item.GroupName}</td>
        </tr>
      ));
  }

  return (
    <Modal
      show={props.isShow}
      onHide={props.onClose}
      backdrop="static"
      size="xl"
      onExited={() => {}}
      onShow={() => {}}
    >
      <Modal.Header closeButton>
        <Modal.Title>Cộng tác viên đã giới thiệu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-centered mb-0">
          <thead>
            <tr>
              <th className="w-52-px">#</th>
              <th>Họ tên</th>
              <th>Điện thoại</th>
              <th>Email</th>
              <th>Nhóm</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <CloseButton onClick={props.onClose} />
      </Modal.Footer>
    </Modal>
  );
}

ModalChildren.propTypes = {
  onClose: PropTypes.func,
  isShow: PropTypes.bool,
  partners: PropTypes.array,
};

ModalChildren.defaultProps = {
  onClose: () => {},
  isShow: false,
  partners: [],
};

export default ModalChildren;
