import React from 'react'
import PropTypes from 'prop-types'

const noImgUrl = '/assets/images/no-img.jpg'

function ShopeeProductAvatar(props) {
    const getImgUrl = () => {
        const url = props.url
        if (url === '' || url === null) return `url(${noImgUrl})`
        else return `url('https://cf.shopee.vn/file/${url}')`
    }

    return (
        <span
            className="bg-avatar me-1 rounded-circle"
            style={{ backgroundImage: getImgUrl() }}
        ></span>
    )
}

ShopeeProductAvatar.propTypes = {
    url: PropTypes.string
}

ShopeeProductAvatar.defaultProps = {
    url: ''
}

export default ShopeeProductAvatar