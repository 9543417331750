import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import NumberFormat from 'react-number-format'
import 'react-quill/dist/quill.snow.css'
import { CloseButton, EmptyRow, ReadExcelButton, SaveButton } from './../../../../../components'

function ModalEditQtyExcel(props) {
    const [isBusy, setIsBusy] = useState(false)

    const [productList, setProductList] = useState([])

    const onNumberChange = (value, productId) => {
        const temp = [...productList]
        const index = temp.findIndex(n => n.ID === productId)
        temp[index].SO_LUONG = value
        setProductList(temp)
    }

    const renderTable = () => {
        if (productList.length === 0) return <EmptyRow colSpan={4} />
        else return productList.map((item, index) => <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.ID}</td>
            <td>{item.TEN_SP}</td>
            <td>
                <NumberFormat
                    placeholder='Số lượng'
                    className="form-control"
                    // thousandSeparator={"."}
                    // decimalSeparator={","}
                    allowNegative={false}
                    defaultValue={item.SO_LUONG}
                    onValueChange={val => onNumberChange(parseInt(val.value), item.ID)}
                />
            </td>
        </tr>)
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="xl"
            onExited={() => {
                setProductList([])
            }}
            onShow={() => {

            }}>
            <Modal.Header closeButton>
                <Modal.Title>Cập nhật số lượng sản phẩm bằng file excel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <table className="table table-centered table-input mb-0">
                        <thead>
                            <tr>
                                <th className="w-52-px">#</th>
                                <th>ID</th>
                                <th>Tên sản phẩm</th>
                                <th className="w-150-px">Số lượng</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTable()}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ReadExcelButton
                    onCompleted={data => {
                        setProductList(data.Result)
                    }}
                />
                <SaveButton type="button" isBusy={isBusy}
                    onClick={() => {
                        props.onSave(productList, setIsBusy)
                    }}
                />
                <CloseButton onClick={props.onClose} />
            </Modal.Footer>
        </Modal >
    )
}

ModalEditQtyExcel.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool
}

ModalEditQtyExcel.defaultPropts = {
    onSave: () => { },
    onClose: () => { },
    isShow: false
}

export default ModalEditQtyExcel