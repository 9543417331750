import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { IoCartOutline } from 'react-icons/io5'
import { CategoryDropdownTree, ClearButton, FormatNumber, IconButton, SearchButton } from './../../../../../components'

const filters = {
    keyword: '',
    catId: 0
}

function Filter(props) {
    const { onSearch, categories } = props

    const [keyword, setKeyword] = useState('')

    const [catId, setCatId] = useState(0)

    const handleSearch = () => {
        onSearch(filters)
    }

    const handleClear = () => {
        setKeyword('')
        filters.keyword = ''
        setCatId(0)
        filters.catId = 0
        onSearch(filters)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
                        <input
                            autoFocus
                            onChange={e => {
                                const value = e.target.value
                                setKeyword(value)
                                filters.keyword = value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                            value={keyword} type="text" className="form-control" autoComplete="off" placeholder="Mã, tên sản phẩm" />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
                        <CategoryDropdownTree
                            options={categories}
                            value={catId}
                            onChange={(value) => {
                                setCatId(value)
                                filters.catId = value
                            }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-7">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                            <ClearButton onClick={handleClear} />
                            <IconButton
                                icon={<IoCartOutline />}
                                className="float-end mb-0"
                                onClick={(setIsBusyOrder) => props.onOrder(setIsBusyOrder)}
                            >
                                Đặt hàng <span className="badge bg-light text-dark"><FormatNumber>{props.countCart}</FormatNumber></span>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func,
    onOrder: PropTypes.func,
    categories: PropTypes.array,
    countCart: PropTypes.number
}

Filter.defaultProps = {
    onSearch: () => { },
    onOrder: () => { },
    categories: [],
    countCart: 0
}

export default Filter