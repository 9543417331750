import React, { Fragment, useEffect, useState } from 'react'
import { Filter } from './components'
import { IconButton, Notification, AuthAdminView } from './../../../components'
import { IoAddCircleOutline } from 'react-icons/io5'
import { Table, ModalAddUser, ModalEditUser, ModalUserSetPwd } from './components'
import axios from 'axios'

function UserPage(props) {
    const [userList, setUserList] = useState(null)

    const [isShowModalUserAdd, setIsShowModalUserAdd] = useState(false)

    const [isShowModalUserEdit, setIsShowModalUserEdit] = useState(false)

    const [infoModalUserSetPwd, setInfoModalUserSetPwd] = useState({
        isShow: false,
        userId: 0
    })

    const [roleList, setRoleList] = useState([])

    const [userEditInfo, setUserEditInfo] = useState({})

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Thành viên'

        loadRole()
    }, [])

    const [filters, setFilters] = useState({
        keyword: '',
        roleId: 0
    })

    useEffect(() => {
        const url = `/admin/user/filter?keyword=${filters.keyword}&roleId=${filters.roleId}`
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                setUserList(data.Result)
            }
        })
    }, [filters])

    const loadRole = () => {
        const url = '/admin/role/get-dropdown'
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                setRoleList(data.Result)
            }
        })
    }

    const reloadUser = () => [
        setFilters({
            ...filters
        })
    ]

    return (
        <Fragment>
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params
                    }
                    setFilters(newFilters)
                }}
                roles={roleList}
            />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="user_add">
                                <IconButton
                                    className="mb-2"
                                    icon={<IoAddCircleOutline />}
                                    text="Thêm mới"
                                    onClick={() => {
                                        setIsShowModalUserAdd(true)
                                    }} />
                            </AuthAdminView>
                        </div>
                    </div>
                    <Table
                        data={userList}
                        onEdit={item => {
                            const url = `/admin/user/get-by-id?id=${item.UserId}`
                            axios.get(url).then(res => {
                                let data = res.data
                                if (data.IsSuccess) {
                                    setUserEditInfo(data.Result)
                                    setIsShowModalUserEdit(true)
                                }
                            })
                        }}
                        onSetPwd={item => {
                            setInfoModalUserSetPwd({
                                isShow: true,
                                userId: item.UserId
                            })
                        }}
                    />
                </div>
            </div>

            <ModalAddUser
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/user/create'
                    axios.post(url, formData).then(res => {
                        const data = res.data
                        if (data.IsSuccess) {
                            reloadUser()
                            setIsShowModalUserAdd(false)
                            Notification.success('Thêm mới thành viên thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalUserAdd(false)
                }}
                isShow={isShowModalUserAdd}
                role={roleList}
            />

            <ModalEditUser
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/user/update'
                    axios.post(url, formData).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            reloadUser()
                            setIsShowModalUserEdit(false)
                            Notification.success('Chỉnh sửa thành viên thành công')
                            setUserEditInfo({})
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setUserEditInfo({})
                    setIsShowModalUserEdit(false)
                }}
                isShow={isShowModalUserEdit}
                role={roleList}
                info={userEditInfo}
            />

            <ModalUserSetPwd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/user/set-password'
                    axios.post(url, formData).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            setInfoModalUserSetPwd({
                                isShow: false,
                                userId: 0
                            })
                            Notification.success('Đặt lại mật khẩu thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setInfoModalUserSetPwd({
                        isShow: false,
                        userId: 0
                    })
                }}
                isShow={infoModalUserSetPwd.isShow}
                userId={infoModalUserSetPwd.userId}
            />
        </Fragment>
    )
}

export default UserPage