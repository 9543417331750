import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { LoadingRow, EmptyRow, FormatNumber, AvatarRow } from './../../../../../components'

function Table(props) {
    const { onAvatarClick } = props

    const renderTable = () => {
        const data = props.data
        if (data === null) return <LoadingRow colSpan={4} />
        else if (data.length === 0) return <EmptyRow colSpan={4} />
        else return data.map((item, index) => {
            const rowSpan = item.Children.length
            return <Fragment key={item.ProductId}>
                <tr>
                    <td className="text-center" rowSpan={rowSpan}>
                        <FormatNumber>
                            {(props.pageItem * (props.pageIndex - 1)) + index + 1}
                        </FormatNumber>
                    </td>
                    <td rowSpan={rowSpan}>
                        <AvatarRow
                            url={item.AvatarUrl}
                            onClick={(urlObj) => {
                                onAvatarClick(urlObj)
                            }}
                            className="cursor-zoom"
                        />
                        {item.ProductName}
                    </td>
                    {renderFirstChild(item.Children)}
                </tr>
                {renderChildren(item.Children)}
            </Fragment>
        })
    }

    const renderFirstChild = (children) => {
        if (children.length !== 0) {
            const child = children[0]
            return <Fragment>
                <td>
                    <AvatarRow
                        url={child.AvatarUrl}
                        onClick={(urlObj) => {
                            onAvatarClick(urlObj)
                        }}
                        className="cursor-zoom"
                    />
                    {child.ProductName}
                </td>
                <td>{child.ProductId}</td>
            </Fragment>
        }
        else return <Fragment></Fragment>
    }

    const renderChildren = (children) => {
        const content = []
        for (let index = 0; index < children.length; index++) {
            const child = children[index];
            if (index === 0) {
                continue
            }
            content.push(<tr key={child.ProductId}>
                <td>
                    <AvatarRow
                        url={child.AvatarUrl}
                        onClick={(urlObj) => {
                            onAvatarClick(urlObj)
                        }}
                        className="cursor-zoom"
                    />
                    {child.ProductName}
                </td>
                <td>{child.ProductId}</td>
            </tr>)
        }
        return content
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0 table-avatar">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Sản phẩm</th>
                        <th>Sản phẩm thuộc tính</th>
                        <th>ID</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
    data: PropTypes.array,
    onAvatarClick: PropTypes.func,
}

Table.defaultPropts = {
    pageItem: 0,
    pageIndex: 0,
    data: null,
    onAvatarClick: () => { }
}

export default Table