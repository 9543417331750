import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { Notification, AuthAdminView, AppContext } from '../../../components'
import { Password, Profile } from './components'

const UserInfoPage = props => {
    const appSettings = useContext(AppContext)

    const [userInfo, setUserInfo] = useState({})

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Thông tin cá nhân'
        loadUserInfo()
    }, [])

    const loadUserInfo = () => {
        const url = '/admin/user/profile-detail'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setUserInfo(data.Result)
            }
        })
    }

    return (
        <div className="row">
            <AuthAdminView right="account_edit_profile">
                <div className="col-md-6">
                    <Profile
                        onSave={(formData, setIsBusy) => {
                            setIsBusy(true)
                            const url = '/admin/user/update-profile'
                            axios.post(url, formData).then(res => {
                                let data = res.data
                                if (data.IsSuccess) {
                                    appSettings.setAdminFullName(formData.FullName)
                                    localStorage.setItem('a-fullName', formData.FullName)
                                    appSettings.setAdminAvatarUrl(formData.AvatarUrl)
                                    localStorage.setItem('a-avatarUrl', formData.AvatarUrl)
                                    Notification.success('Thay đổi thông tin thành công')
                                }
                                setIsBusy(false)
                            })
                        }}
                        data={userInfo}
                    />
                </div>
            </AuthAdminView>
            <AuthAdminView right="account_change_password">
                <div className="col-md-6">
                    <Password
                        onSave={(formData, callBack, setIsBusy) => {
                            setIsBusy(true)
                            const url = '/admin/user/change-password'
                            axios.post(url, formData).then(res => {
                                let data = res.data
                                if (data.IsSuccess) {
                                    Notification.success('Thay đổi mật khẩu thành công')
                                    callBack()
                                }
                                setIsBusy(false)
                            })
                        }}
                    />
                </div>
            </AuthAdminView>
        </div>
    )
}

export default UserInfoPage