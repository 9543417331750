import PropTypes from 'prop-types';
import React from 'react';
import { GiBroom } from 'react-icons/gi';

const ClearButton = (props) => {
    return (
        <button onClick={props.onClick} type="button" className="btn btn-warning">
            <GiBroom className="me-1 mt--2-px font-17" />Xóa Tìm kiếm
        </button>
    )
}

ClearButton.propTypes = {
    onClick: PropTypes.func
}

ClearButton.defaultProps = {
    onClick: () => { }
}

export default ClearButton