import React, { Fragment, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { SiMicrosoftexcel } from 'react-icons/si'
import axios from 'axios'

const maxSizeInBute = 5_242_880 // 5MB

const url = '/admin/storage/excel-to-json'

function ReadExcelButton(props) {
    const [isBusy, setIsBusy] = useState(false)

    const fileInput = useRef(null)

    const handleTrigger = () => {
        fileInput.current.click()
    }

    const handleUpload = (e) => {
        let files = e.target.files
        if (files.length !== 0) {
            let file = files[0]
            let name = file.name
            let sizeInByte = file.size
            if (sizeInByte > maxSizeInBute) {
                return
            }
            setIsBusy(true)
            const formData = new FormData()
            formData.append('formFile', file)
            formData.append('fileName', name)
            axios.post(url, formData).then(({ data }) => {
                if (props.onCompleted) {
                    props.onCompleted(data)
                }
                setIsBusy(false)
            })
        }
    }

    const renderIcon = () => {
        if (isBusy) return <div className="spinner-border spinner-border-sm me-1" role="status"></div>
        else return <SiMicrosoftexcel className="mt--3-px me-1 font-17" />
    }

    return (
        <Fragment>
            <input
                style={{ display: 'none' }}
                type="file"
                accept=".xlsx"
                ref={fileInput}
                onChange={handleUpload}
            />
            <button
                className={'btn btn-' + props.variant}
                type="button"
                onClick={handleTrigger}
            >
                {renderIcon()}
                Chọn file excel
            </button>
        </Fragment>
    )
}

ReadExcelButton.propTypes = {
    onCompleted: PropTypes.func,
    variant: PropTypes.string
}

ReadExcelButton.defaultProps = {
    onCompleted: () => { },
    variant: 'success'
}

export default ReadExcelButton