import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    UserName: yup.string().required('Vui lòng nhập tài khoản').max(70, 'Tài khoản quá dài'),
    Password: yup.string().required('Vui lòng nhập mật khẩu').max(70, 'Mật khẩu quá dài')
})

function ModalEdit(props) {
    const { onSave, onClose } = props

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={onClose} backdrop="static"
            onExited={() => {
                reset()
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới tài khoản shopee</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tài khoản <span className="text-danger">*</span></label>
                        <input {...register('UserName')} type="text" className="form-control" placeholder="Tài khoản" />
                        {errors.UserName && <div className="validation-message">{errors.UserName.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Mật khẩu <span className="text-danger">*</span></label>
                        <input {...register('Password')} type="password" className="form-control" placeholder="Mật khẩu" />
                        {errors.Password && <div className="validation-message">{errors.Password.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEdit.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
}

ModalEdit.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false
}

export default ModalEdit