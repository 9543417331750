import React from 'react'
import PropTypes from 'prop-types'

function DetailRow(props) {
    const { className, title, titleColumn, childrenColumn } = props

    const titleColumnString = () => `col-sm-${titleColumn}`

    const childrenColumnString = () => `col-sm-${childrenColumn}`

    return (
        <div className={className}>
            <div className="border-bottom">
                <div className="row gx-2 pt-2 pb-2">
                    <div className={titleColumnString()}>
                        <p className="fw-bold mb-0">{title}</p>
                    </div>
                    <div className={childrenColumnString()}>
                        <p className="mb-0">{props.children}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

DetailRow.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleColumn: PropTypes.number,
    childrenColumn: PropTypes.number,
}

DetailRow.defaultProps = {
    className: "col-sm-12 col-md-6 col-lg-6 col-xl-4",
    title: 'Title',
    titleColumn: 4,
    childrenColumn: 8
}

export default DetailRow