import PropTypes from 'prop-types'
import React from 'react'
import { IoSearchOutline } from 'react-icons/io5'

const SearchButton = (props) => {
    const { onClick, type } = props

    return (
        <button onClick={onClick} className="btn btn-primary" type={type}>
            <IoSearchOutline className="me-1 mt--2-px font-17" />Tìm kiếm
        </button>
    )
}

SearchButton.propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string
}

SearchButton.defaultProps = {
    onClick: () => { },
    type: 'button'
}

export default SearchButton