import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { EmptyRow, FormatNumber, LoadingRow, Pagination } from '../../../../../components'

const OrderTable = (props) => {
    const { orders, pagination } = props

    const renderTable = () => {
        if (orders === null) return <LoadingRow colSpan={7} />
        else if (orders.length === 0) return <EmptyRow colSpan={7} />
        else return orders.map(order => <tr key={order.OrderId}>
            <td>{order.OrderId}</td>
            <td>{order.CustomerName}</td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {order.Products.length}
                </FormatNumber>
            </td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {order.SumOutputPrice}
                </FormatNumber>
            </td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {order.SumPartnerPrice}
                </FormatNumber>
                <span> + </span>
                <span className="text-success">
                    <FormatNumber>
                        {order.PartnerDropShipFee}
                    </FormatNumber>
                </span>
            </td>
            <td>
                {renderStatus(order.OrderStatusId, order.OrderStatusName)}
            </td>
            <td>
                {order.CreatedDate}
                <small className="text-muted"> {order.CreatedTime}</small>
            </td>
        </tr>)
    }

    const renderStatus = (orderStatusId, orderStatusName) => {
        let className = ''
        if (orderStatusId !== 5) {
            className = orderStatusId === 1 ? 'badge bg-success min-w-50-px' : 'badge bg-danger min-w-50-px'
        }
        else {
            className = orderStatusId === 1 ? 'badge bg-success min-w-50-px' : 'badge bg-danger min-w-50-px'
        }
        return <span className={className}>{orderStatusName}</span>
    }

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-centered mb-0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Người nhận</th>
                            <th className='text-end'>Số lượng</th>
                            <th className='text-end'>Tổng</th>
                            <th className='text-end'>Thu CTV</th>
                            <th>Trạng thái</th>
                            <th>Ngày</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable()}
                    </tbody>
                </table>
            </div>
            <Pagination
                totalPage={pagination.totalPage}
                currentPage={pagination.pageIndex}
                onPageChange={(pIndex) => {
                    props.onPageChange(pIndex)
                }}
            />
        </Fragment>
    )
}

OrderTable.propTypes = {
    orders: PropTypes.array,
    pagination: PropTypes.object,
    onPageChange: PropTypes.func,
}

OrderTable.defaultProps = {
    orders: [],
    pagination: {},
    onPageChange: () => { }
}

export default OrderTable