import React from 'react'
import { IoBanOutline } from 'react-icons/io5'
import PropTypes from 'prop-types'

const CloseButton = props => {
    const handleOnClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    return (
        <button type="button" className="btn btn-light" onClick={handleOnClick}>
            <IoBanOutline className="mt--3-px me-1 font-17" />
            {props.title}
        </button>
    )
}

CloseButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func
}

CloseButton.defaultProps = {
    title: 'Đóng',
    onClick: () => { }
}

export default CloseButton