import React from 'react'
import PropTypes from 'prop-types'

function Message(props) {
    const { message } = props

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="header-title mb-3 text-primary">Thông báo từ hệ thống</h4>
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </div>
        </div>
    )
}

Message.propTypes = {
    message: PropTypes.string
}

Message.defaultProps = {
    message: ''
}

export default Message