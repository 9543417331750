import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoEllipsisVertical } from 'react-icons/io5'
import { EmptyRow, LoadingRow, ShopeeProductAvatar } from './../../../../../components'

function Table(props) {
    const { shops, onEdit } = props

    function renderTable() {
        if (shops === null) return <LoadingRow colSpan={6} />
        else if (shops.length === 0) return <EmptyRow colSpan={6} />
        else return shops.map((item, index) => <tr key={item.PartnerShopId}>
            <td className="text-center">{index + 1}</td>
            <td>{item.UserName}</td>
            <td>
                <ShopeeProductAvatar
                    url={item.Portrait}
                />
                {item.ShopName}
            </td>
            <td>{item.ShopId}</td>
            <td>
                {item.CreatedDate}
                <small className="text-muted"> {item.CreatedTime}</small>
            </td>
            <td>
                <div className="dropdown float-end">
                    <button className="dropdown-toggle arrow-none card-drop btn-drop" data-bs-toggle="dropdown" aria-expanded="false">
                        <IoEllipsisVertical className="fs-4 text-primary" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                        <button onClick={() => onEdit(item)} className="dropdown-item"><FiEdit className="mn-btn-icon" />Chỉnh sửa</button>
                    </div>
                </div>
            </td>
        </tr>)
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered table-avatar mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Tài khoản</th>
                        <th>Tên shop</th>
                        <th>ID shop</th>
                        <th>Ngày tạo</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    shops: PropTypes.array,
    onEdit: PropTypes.func,
}

Table.defaultProps = {
    shops: [],
    onEdit: () => { }
}

export default Table