import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { BiRun } from 'react-icons/bi'
import { IoCopyOutline, IoCubeOutline, IoFileTrayOutline, IoReloadOutline } from 'react-icons/io5'
import { Notification } from '../../../components'
import { Message, OrderBlock, PartnerInfo, Revenue } from './components'

function DashboardPage(props) {

    const [refLink, setRefLink] = useState('')

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Tổng quan'
        loadNewOrder()
        loadInProcessOrder()
        loadShippingOrder()
        loadProductCount()
        loadRevenue()
        loadPartnerInfo()
        loadMessage()
    }, [])

    const [newOrder, setNewOrder] = useState({
        value: 0,
        percent: 0
    })

    const [inProcessOrder, setInProcessOrder] = useState({
        value: 0,
        percent: 0
    })

    const [shippingOrder, setShippingOrder] = useState({
        value: 0,
        percent: 0
    })

    const [countProduct, setCountProduct] = useState({
        value: 0,
        percent: 0
    })

    const [revenue, setRevenue] = useState([])

    const [partnerInfo, setPartnerInfo] = useState({
        balance: 0,
        countCustomer: 0,
        orderToday: 0,
        groupName: '',
        freeShipQty: 0
    })

    const [message, setMessage] = useState({
        isShow: false,
        message: ''
    })

    function loadNewOrder() {
        const url = '/partner/dashboard/new-order'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setNewOrder({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadInProcessOrder() {
        const url = '/partner/dashboard/in-process-order'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setInProcessOrder({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadShippingOrder() {
        const url = '/partner/dashboard/shipping-order'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setShippingOrder({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadProductCount() {
        const url = '/partner/dashboard/count-product'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setCountProduct({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadRevenue() {
        const url = '/partner/dashboard/revenue-income'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setRevenue(data.Result)
            }
        })
    }

    function loadPartnerInfo() {
        const url = '/partner/dashboard/info'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setPartnerInfo({
                    balance: data.Result.Balance,
                    countCustomer: data.Result.CountCustomer,
                    orderToday: data.Result.OrderToday,
                    groupName: data.Result.PartnerGroupName,
                    freeShipQty: data.Result.FreeShipQty
                })

                const link = `${window.location.origin}/partner/register?refcode=${data.Result.PartnerCode.toLowerCase()}`
                setRefLink(link)
            }
        })
    }

    function loadMessage() {
        const url = '/partner/dashboard/message'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setMessage({
                    isShow: data.Result.IsShow,
                    message: data.Result.Message
                })
            }
        })
    }

    function renderMessage() {
        if (message.isShow === false) return ''
        else return <Message message={message.message} />
    }

    return (
        <div className="row">
            <div className="col-xl-5 col-lg-6">
                <div className="row">
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<IoFileTrayOutline />}
                            header="Đơn tạo mới"
                            val={newOrder.value}
                            percent={newOrder.percent}
                        />
                    </div>
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<IoReloadOutline />}
                            header="Đang xử lý"
                            val={inProcessOrder.value}
                            percent={inProcessOrder.percent}
                        />
                    </div>
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<BiRun />}
                            header="Đã gửi vận chuyển"
                            val={shippingOrder.value}
                            percent={shippingOrder.percent}
                        />
                    </div>
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<IoCubeOutline />}
                            header="Số lượng sản phẩm"
                            val={countProduct.value}
                            percent={countProduct.percent}
                        />
                    </div>
                </div>
            </div>
            <div className="col-xl-7 col-lg-6">
                <Revenue
                    data={revenue}
                />
            </div>
            <div className="col-12">
                <PartnerInfo
                    info={partnerInfo}
                />
            </div>
            <div className="col-12">
                {renderMessage()}
            </div>
            <div className='col-12'>
                <div className="card">
                    <div className="card-body">
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text">Link giới thiệu</span>
                            <input readOnly defaultValue={refLink} type="text" className="form-control" autoComplete="off" placeholder="Link giới thiệu" />
                            <CopyToClipboard text={refLink} onCopy={() => {
                                Notification.success('Đã sao chép link giới thiệu')
                            }}>
                                <span className="input-group-text text-primary" style={{ cursor: 'pointer' }}><IoCopyOutline className='me-1' />Sao chép</span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardPage