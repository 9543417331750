import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

function Pagination(props) {
    const pageBreak = () => Math.floor(props.displayPage / 2)

    const startPage = () => {
        if (props.totalPage > props.displayPage) {
            if (props.currentPage > props.displayPage - pageBreak()) {
                return props.currentPage - pageBreak < 1 ? 1 : props.currentPage - pageBreak()
            }
            else return 1
        }
        else return 1
    }

    const endPage = () => {
        if (props.totalPage > props.displayPage) {
            if (props.currentPage > props.displayPage - pageBreak()) {
                if (props.currentPage < props.totalPage - pageBreak())
                    return pageBreak() % 2 === 0 ? props.currentPage + pageBreak() : props.currentPage + (pageBreak() - 1)
                else return props.totalPage
            }
            else return props.displayPage
        }
        else return props.totalPage
    }

    const isCanPrev = () => props.currentPage > 1

    const isCanNext = () => props.currentPage < props.totalPage

    const isCanFirst = () => props.currentPage > 1

    const isCanLast = () => props.currentPage < props.totalPage

    const isRender = () => {
        if (props.totalPage > 1) return true
        else if (!props.isHideIfOnePage) return true
        return false
    }

    const onPageClick = (pageIndex) => {
        onChange(pageIndex)
    }

    const onPrevClick = () => {
        onChange(props.currentPage - 1)
    }

    const onNextClick = () => {
        onChange(props.currentPage + 1)
    }

    const onFirstClick = () => {
        onChange(1)
    }

    const onLastClick = () => {
        onChange(props.totalPage)
    }

    const onChange = (currentPage) => {
        props.onPageChange(currentPage)
    }

    const renderHtml = () => {
        if (isRender()) {
            return (
                <nav className="mt-3 float-end">
                    <ul className="pagination pagination-rounded mb-0">
                        <li className={'page-item first' + (!isCanFirst() ? ' disabled' : '')}>
                            <button className="page-link" onClick={onFirstClick}>←</button>
                        </li>
                        <li className={'page-item' + (!isCanPrev() ? ' disabled' : '')}>
                            <button className="page-link" aria-label="Previous" onClick={onPrevClick}>
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>
                        {renderNum()}
                        <li className={'page-item' + (!isCanNext() ? ' disabled' : '')}>
                            <button className="page-link" aria-label="Next" onClick={onNextClick}>
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>
                        <li className={'page-item last' + (!isCanLast() ? ' disabled' : '')}>
                            <button className="page-link" onClick={onLastClick}>→</button>
                        </li>
                    </ul >
                </nav >
            )
        }
    }

    const renderNum = () => {
        const start = startPage()
        const end = endPage() + 1
        const html = []
        for (let i = start; i < end; i++) {
            html.push(<li className={'page-item' + (i === props.currentPage ? ' active' : '')} key={i}>
                <button className="page-link" onClick={() => onPageClick(i)}>{i}</button>
            </li>)
        }
        return html
    }

    return (
        <Fragment>
            {renderHtml()}
        </Fragment>
    )
}

Pagination.propTypes = {
    onPageChange: PropTypes.func,
    totalPage: PropTypes.number,
    currentPage: PropTypes.number,
    displayPage: PropTypes.number,
    isHideIfOnePage: PropTypes.bool
}

Pagination.defaultProps = {
    onPageChange: () => { },
    totalPage: 10,
    currentPage: 1,
    displayPage: 5,
    isHideIfOnePage: true
}

export default Pagination