import React from 'react'
import PropTypes from 'prop-types'
import { LoadingRow, EmptyRow, ShopeeProductAvatar } from './../../../../../components'

function ShopeeTable(props) {
    const { shops } = props

    function renderTable() {
        if (shops === null) return <LoadingRow colSpan={5} />
        else if (shops.length === 0) return <EmptyRow colSpan={5} />
        else return shops.map((item, index) => <tr key={item.PartnerShopId}>
            <td className="text-center">{index + 1}</td>
            <td>
                <ShopeeProductAvatar
                    url={item.Portrait}
                />
                {item.ShopName}
            </td>
            <td>{item.ShopId}</td>
            <td>{item.UserName}</td>
            <td>
                {item.CreatedDate}
                <small className="text-muted"> {item.CreatedTime}</small>
            </td>
        </tr>)
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Tên shop</th>
                        <th>ID shop</th>
                        <th>Tài khoản</th>
                        <th>Ngày tạo</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

ShopeeTable.propTypes = {
    shops: PropTypes.array
}

ShopeeTable.defaultProps = {
    shops: []
}

export default ShopeeTable