import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { EmptyRow, FormatNumber, LoadingRow, Pagination } from '../../../../../components'

function BalanceTable(props) {
    const { balanceHistories, pagination } = props

    function renderTable() {
        if (balanceHistories === null) return <LoadingRow colSpan={6} />
        else if (balanceHistories.length === 0) return <EmptyRow colSpan={6} />
        else return balanceHistories.map((item, index) => <tr key={item.PartnerBalanceHistoryId}>
            <td className="text-center">
                <FormatNumber>
                    {(props.pageItem * (props.pageIndex - 1)) + index + 1}
                </FormatNumber>
            </td>
            <td>
                {item.Detail}
                {renderObjectValue(item.ObjectValue)}
            </td>
            <td>{item.Note}</td>
            <td className={item.Amount >= 0 ? 'text-end text-primary' : 'text-end text-danger'}>
                <FormatNumber>
                    {item.Amount}
                </FormatNumber>
            </td>
            <td>
                {renderType(item.Amount)}
            </td>
            <td>
                {item.CreatedDate}
                <small className="text-muted"> {item.CreatedTime}</small>
            </td>
        </tr>)
    }

    const renderObjectValue = (objectValue) => {
        if (objectValue !== '') return <span className="text-primary"> #{objectValue}</span>
        else return ''
    }

    const renderType = (amount) => {
        if (amount >= 0) return <span className="badge bg-success min-w-50-px">Cộng</span>
        else return <span className="badge bg-warning min-w-50-px">Trừ</span>
    }

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-centered mb-0">
                    <thead>
                        <tr>
                            <th className="w-52-px">#</th>
                            <th>Giao dịch</th>
                            <th>Ghi chú</th>
                            <th className='text-end'>Số tiền</th>
                            <th>Loại</th>
                            <th>Ngày giao dịch</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable()}
                    </tbody>
                </table>
            </div>
            <Pagination
                totalPage={pagination.totalPage}
                currentPage={pagination.pageIndex}
                onPageChange={(pIndex) => {
                    props.onPageChange(pIndex)
                }}
            />
        </Fragment>
    )
}

BalanceTable.propTypes = {
    balanceHistories: PropTypes.array,
    pagination: PropTypes.object,
    onPageChange: PropTypes.func,
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
}

BalanceTable.defaultProps = {
    balanceHistories: [],
    pagination: {},
    onPageChange: () => { },
    pageItem: 0,
    pageIndex: 0
}

export default BalanceTable