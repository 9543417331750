import React from 'react'
import PropTypes from 'prop-types'
import { FormatNumber } from './../../../../../components'
import { IoExtensionPuzzleOutline } from 'react-icons/io5'

function InfoBlock(props) {
    const { icon, value, title } = props

    return (
        <div className="card widget-flat mb-0">
            <div className="card-body">
                <div className="float-end">
                    <span className="widget-icon">
                        {icon}
                    </span>
                </div>
                <h5 className="text-muted fw-normal mt-0" title="Growth">{title}</h5>
                <h3 className="mt-3 mb-3">
                    <FormatNumber>{value}</FormatNumber>
                </h3>
            </div>
        </div>
    )
}

InfoBlock.propTypes = {
    icon: PropTypes.element,
    value: PropTypes.number,
    title: PropTypes.string,
}

InfoBlock.defaultProps = {
    icon: <IoExtensionPuzzleOutline />,
    value: 0,
    title: 'Title'
}

export default InfoBlock