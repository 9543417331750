import React, { useEffect, useState } from 'react'
import { ClearButton, SearchButton } from './../../../../../components'
import PropTypes from 'prop-types'
import Select from 'react-select'

const statusOptions = [
    {
        value: 1,
        label: 'Hoạt động'
    },
    {
        value: 2,
        label: 'Tạm khóa'
    }
]

const filters = {
    keyword: '',
    groupId: 0,
    statusId: 0
}

const Filter = props => {
    const { onSearch } = props

    const [keyword, setKeyword] = useState('')

    const [groupOptions, setGroupOptions] = useState([])

    useEffect(() => {
        let lst = props.partnerGroup.map(item => {
            return {
                value: item.GroupId,
                label: item.GroupName
            }
        })
        setGroupOptions(lst)
    }, [props.partnerGroup])

    const [group, setGroup] = useState(0)

    const [status, setStatus] = useState(0)

    const handleSearch = () => {
        onSearch(filters)
    }

    const handleClearSearch = () => {
        setKeyword('')
        filters.keyword = ''
        setGroup(null)
        filters.groupId = 0
        setStatus(null)
        filters.statusId = 0
        onSearch(filters)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
                        <input
                            autoFocus
                            onChange={e => {
                                const value = e.target.value
                                setKeyword(value)
                                filters.keyword = value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                            value={keyword} type="text" className="form-control" autoComplete="off" placeholder="Họ tên, email, điện thoại" />
                    </div>
                    <div className="col-sm-4 col-md-8 col-lg-4 col-xl-4 col-xxl-3">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            placeholder='Nhóm cộng tác viên'
                            options={groupOptions}
                            value={group}
                            onChange={item => {
                                if (item !== null) {
                                    setGroup(item)
                                    filters.groupId = item.value
                                }
                                else {
                                    setGroup(item)
                                    filters.groupId = 0
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            placeholder='Trạng thái'
                            options={statusOptions}
                            value={status}
                            onChange={item => {
                                if (item !== null) {
                                    setStatus(item)
                                    filters.statusId = item.value
                                }
                                else {
                                    setStatus(null)
                                    filters.statusId = 0
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-12 col-xl-12 col-xxl-5">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                            <ClearButton onClick={handleClearSearch} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    partnerGroup: PropTypes.array,
    onSearch: PropTypes.func,
    onClear: PropTypes.func
}

Filter.defaultProps = {
    partnerGroup: [],
    onSearch: () => { }
}

export default Filter