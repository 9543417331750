import React from 'react'
import PropTypes from 'prop-types'
import { IoExtensionPuzzleOutline } from 'react-icons/io5'
import { AuthAdminView } from '..'

function ActionItem(props) {
    const { title, icon, onClick, className, right } = props

    return (
        <AuthAdminView right={right}>
            <button
                onClick={onClick}
                className={'dropdown-item action-item ' + className}
            >
                {icon}
                {title}
            </button>
        </AuthAdminView>
    )
}

ActionItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    className: PropTypes.string,
    right: PropTypes.string,
}

ActionItem.defaultProps = {
    title: 'Title',
    icon: <IoExtensionPuzzleOutline />,
    onClick: () => { },
    className: '',
    right: ''
}

export default ActionItem