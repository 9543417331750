import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ClearButton, SearchButton } from './../../../../../components'

const filters = {
    keyword: ''
}

function Filter(props) {

    const { onSearch } = props

    const [keyword, setKeyword] = useState('')

    const handleSearch = () => {
        onSearch(filters)
    }

    const handleClear = () => {
        setKeyword('')
        filters.keyword = ''
        onSearch(filters)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                        <input value={keyword}
                            autoFocus
                            onChange={e => {
                                const value = e.target.value
                                setKeyword(value)
                                filters.keyword = value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                            type="text" className="form-control" autoComplete="off" placeholder="Tiêu đề" />
                    </div>
                    <div className="col-sm-8 col-md-8 col-lg-9 col-xl-9 col-xxl-8">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                            <ClearButton onClick={handleClear} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func
}

Filter.propTypes = {
    onSearch: () => { }
}

export default Filter
