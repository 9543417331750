import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoTicketOutline, IoTrashOutline } from 'react-icons/io5'
import { ActionItem, ActionRow, EmptyRow, FormatNumber, LoadingRow } from './../../../../../components'

function Table(props) {
    const renderTable = () => {
        let data = props.data
        if (data === null) return <LoadingRow colSpan={6} />
        else if (data.length === 0) return <EmptyRow colSpan={6} />
        else return data.map((item, index) => <tr key={item.RoleId}>
            <td>{index + 1}</td>
            <td>{item.RoleName}</td>
            <td>{item.RoleNote}</td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {item.UserCount}
                </FormatNumber>
            </td>
            <td>
                {item.CreatedDate}
                <small className="text-muted"> {item.CreatedTime}</small>
            </td>
            <td>
                <ActionRow>
                    <ActionItem right="role_edit" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                    <ActionItem right="role_permission" onClick={() => props.onPermission(item)} icon={<IoTicketOutline />} title='Phân quyền' />
                    <ActionItem right="role_delete" onClick={() => props.onDelete(item)} icon={<IoTrashOutline />} title='Xóa' className='text-danger' />
                </ActionRow>
            </td>
        </tr>)
    }

    return (
        <div className="table-responseive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Vai trò</th>
                        <th>Ghi chú</th>
                        <th className='text-end'>Thành viên</th>
                        <th>Ngày tạo</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onPermission: PropTypes.func,
    data: PropTypes.array
}

Table.defaultProps = {
    onSave: () => { },
    onDelete: () => { },
    onPermission: () => { },
    data: null
}

export default Table