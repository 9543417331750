import React, { Fragment, useEffect, useState } from 'react'
import { Filter, Table } from './components'
import axios from 'axios'
import { Pagination } from './../../../components'

function BalanceHistoryPage(props) {
    const [histories, setHistories] = useState(null)

    const [filters, setFilters] = useState({
        fromDate: '',
        toDate: '',
        pageItem: 10,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Lịch sử giao dịch'
    }, [])

    useEffect(() => {
        const url = `/partner/balance-history/filter?fromDate=${filters.fromDate}&toDate=${filters.toDate}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setHistories(data.Result.Data)
            }
        })
    }, [filters])

    return (
        <Fragment>
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
            />
            <div className="card">
                <div className="card-body">
                    <Table
                        pageItem={filters.pageItem}
                        pageIndex={filters.pageIndex}
                        data={histories}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default BalanceHistoryPage