import React, { useState } from 'react';
import { IoExtensionPuzzleOutline } from 'react-icons/io5'
import PropTypes from 'prop-types'

const IconButton = props => {
    const [isBusy, setIsBusy] = useState(false)

    const btnClassName = () => {
        return `btn btn-${props.variant} btn-icon ${props.className}`
    }

    const handleClick = () => {
        if (props.onClick) {
            props.onClick(setIsBusy)
        }
    }

    const renderIcon = () => {
        if (isBusy) {
            return <div className="spinner-border spinner-border-sm me-1" role="status"></div>;
        }
        else {
            return props.icon
        }
    }

    const renderContent = () => {
        if (props.children !== undefined) return props.children
        else return props.text
    }

    return (
        <button onClick={handleClick} className={btnClassName()} disabled={isBusy} type={props.type}>
            {renderIcon()}
            {renderContent()}
        </button>
    )
}

IconButton.propTypes = {
    icon: PropTypes.element,
    text: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
}

IconButton.defaultProps = {
    icon: <IoExtensionPuzzleOutline />,
    text: 'Text',
    variant: 'primary',
    onClick: () => { },
    className: '',
    type: "button"
}

export default IconButton