import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { IconButton, Notification } from './../../../components'
import { ModalAdd, ModalEdit, Table } from './components'

function PartnerShopPage(props) {
    const [shops, setShops] = useState(null)

    const [isShowModalAdd, setIsShowModalAdd] = useState(false)

    const [modalEditInfo, setModalEditInfo] = useState({
        isShow: false,
        data: {}
    })

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Tài khoản shopee'
        loadShop()
    }, [])

    function loadShop() {
        const url = '/partner/partner-shop/get-all'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setShops(data.Result)
            }
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="row mb-2">
                        <div className="col-12">
                            <IconButton text="Thêm mới" icon={<IoAddCircleOutline />} onClick={() => {
                                setIsShowModalAdd(true)
                            }} />
                        </div>
                    </div>
                    <Table
                        shops={shops}
                        onEdit={shop => {
                            const url = `/partner/partner-shop/get-by-id?id=${shop.PartnerShopId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setModalEditInfo({
                                        isShow: true,
                                        data: data.Result
                                    })
                                }
                            })
                        }}
                    />
                </div>
            </div>
            <ModalAdd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/partner/partner-shop/create'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadShop()
                            setIsShowModalAdd(false)
                            Notification.success('Thêm mới tài khoản shop thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalAdd(false)
                }}
                isShow={isShowModalAdd}
            />
            <ModalEdit
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/partner/partner-shop/update'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadShop()
                            setModalEditInfo({
                                isShow: false,
                                data: {}
                            })
                            Notification.success('Chỉnh sửa tài khoản shop thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setModalEditInfo({
                        isShow: false,
                        data: {}
                    })
                }}
                data={modalEditInfo.data}
                isShow={modalEditInfo.isShow}
            />
        </Fragment>
    )
}

PartnerShopPage.propTypes = {

}

PartnerShopPage.defaultProps = {

}

export default PartnerShopPage