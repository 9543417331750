import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { Notification, AuthAdminView, IconButton } from '../../../components';
import { ModalAdd, ModalEdit, Table } from './components';

const ShippingUnitPage = props => {
    useEffect(() => {
        document.getElementById('page-header').innerText = 'Đơn vị vận chuyển';
        loadShippingUnit();
    }, []);

    // State trạng thái đóng mở modal thêm mới đơn vị vận chuyển
    const [isShowModalShippingUnitAdd, setIsShowModalShippingUnitAdd] = useState(false);

    // State trạng thái đóng mở modal chỉnh sửa đơn vị vận chuyển
    const [isShowModalShippingUnitEdit, setIsShowModalShippingUnitEdit] = useState(false);

    // State lưu danh sách đơn vị vận chuyển
    const [shippingUnit, setShippingUnit] = useState(null);

    // State lưu thông tin chi tiết đơn vị giao hàng cho chỉnh sửa
    const [shippingUnitEditInfo, setShippingUnitEditInfo] = useState({});

    // Load danh sách đơn vị vận chuyển
    const loadShippingUnit = () => {
        const url = '/admin/shipping-unit/get-all';
        axios.get(url).then(res => {
            let data = res.data;
            if (data.IsSuccess) {
                setShippingUnit(data.Result);
            }
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="shipping_unit_add">
                                <IconButton
                                    className="mb-2"
                                    text="Thêm mới"
                                    icon={<IoAddCircleOutline />}
                                    onClick={() => {
                                        setIsShowModalShippingUnitAdd(true)
                                    }}
                                />
                            </AuthAdminView>
                        </div>
                    </div>
                    <Table
                        data={shippingUnit}
                        onEdit={item => {
                            const url = `/admin/shipping-unit/get-by-id?id=${item.ShippingUnitId}`;
                            axios.get(url).then(res => {
                                let data = res.data;
                                if (data.IsSuccess) {
                                    setShippingUnitEditInfo(data.Result);
                                    setIsShowModalShippingUnitEdit(true);
                                }
                            })
                        }}
                        onDelete={item => {
                            Notification.confirmHtml('Bạn có chắc muốn xóa đơn vị vận chuyển', item.ShippingUnitName, () => {
                                const url = `/admin/shipping-unit/delete?id=${item.ShippingUnitId}`;
                                axios.post(url).then(res => {
                                    let data = res.data;
                                    if (data.IsSuccess) {
                                        loadShippingUnit();
                                        Notification.success('Xóa đơn vị giao hàng thành công');
                                    }
                                })
                            })
                        }} />
                </div>
            </div>

            {/* Modal thêm mới đơn vị giao hàng */}
            <ModalAdd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/shipping-unit/create';
                    axios.post(url, formData).then(res => {
                        let data = res.data;
                        if (data.IsSuccess) {
                            loadShippingUnit();
                            setIsShowModalShippingUnitAdd(false);
                            Notification.success('Thêm mới đơn vị giao hàng thành công');
                        }
                        setIsBusy(false)
                    });
                }}
                onClose={() => {
                    setIsShowModalShippingUnitAdd(false)
                }}
                isShow={isShowModalShippingUnitAdd} />

            {/* Modal chỉnh sửa đơn vị giao hàng */}
            <ModalEdit
                data={shippingUnitEditInfo}
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/shipping-unit/update';
                    axios.post(url, formData).then(res => {
                        let data = res.data;
                        if (data.IsSuccess) {
                            setShippingUnitEditInfo({});
                            loadShippingUnit();
                            setIsShowModalShippingUnitEdit(false);
                            Notification.success('Chỉnh sửa đơn vị giao hàng thành công');
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setShippingUnitEditInfo({});
                    setIsShowModalShippingUnitEdit(false);
                }}
                isShow={isShowModalShippingUnitEdit} />
        </Fragment>
    );
}

export default ShippingUnitPage;