import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoEllipseSharp, IoTrashOutline } from 'react-icons/io5'
import { ActionItem, ActionRow, EmptyRow, FormatNumber, LoadingRow } from '../../../../../components'

function Table(props) {

    function renderTable() {
        let data = props.data
        if (data === null) return <LoadingRow colSpan={6} />
        else if (data.length === 0) return <EmptyRow colSpan={6} />
        else return data.map((item, index) => <tr key={item.PopupId}>
            <td className="text-center">
                <FormatNumber>
                    {(props.pageItem * (props.pageIndex - 1)) + index + 1}
                </FormatNumber>
            </td>
            <td>{item.Title}</td>
            <td>{renderType(item.TypeId)}</td>
            <td className='text-end text-primary'>
                <FormatNumber>
                    {item.CountPartner}
                </FormatNumber>
            </td>
            <td>
                {item.CreatedDate}
                <small className="text-muted">{item.CreatedTime}</small>
            </td>
            <td>
                <ActionRow>
                    <ActionItem right="popup_edit" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                    <ActionItem right="popup_delete" onClick={() => props.onDelete(item)} icon={<IoTrashOutline />} title='Xóa' className='text-danger' />
                </ActionRow>
            </td>
        </tr>)
    }

    function renderType(typeId) {
        if (typeId === 1) return <><IoEllipseSharp className="text-info mt--3-px" /> Cộng tác viên</>
        else if (typeId === 2) return <><IoEllipseSharp className="text-primary mt--3-px" /> Cộng tác viên chưa kích hoạt</>
        else return <><IoEllipseSharp className="text-warning mt--3-px" /> Nhóm cộng tác viên</>
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Tiêu đề</th>
                        <th>Loại</th>
                        <th className='text-end'>CTV nhận</th>
                        <th>Ngày tạo</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    data: PropTypes.array,
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
}

Table.defaultProps = {
    onEdit: () => { },
    onDelete: () => { },
    data: null,
    pageItem: 0,
    pageIndex: 0,
}

export default Table