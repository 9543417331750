import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoEllipsisVertical, IoTrashOutline } from 'react-icons/io5'
import { EmptyRow, LoadingRow, FormatNumber } from '../../../../../components'

const Table = props => {
    const renderTable = () => {
        const data = props.data
        if (data === null) return <LoadingRow colSpan={6} />
        else if (data.length === 0) return <EmptyRow colSpan={6} />
        else return data.map((item, index) => <tr key={item.CustomerId}>
            <td className="text-center">
                <FormatNumber>
                    {(props.pageItem * (props.pageIndex - 1)) + index + 1}
                </FormatNumber>
            </td>
            <td>{item.FullName}</td>
            <td>{item.Phone}</td>
            <td>{item.Address}</td>
            <td>
                {item.CreatedDate}
                <small className="text-muted"> {item.CreatedTime}</small>
            </td>
            <td>
                <div className="dropdown float-end">
                    <button className="dropdown-toggle arrow-none card-drop btn-drop" data-bs-toggle="dropdown" aria-expanded="false">
                        <IoEllipsisVertical className="fs-4 text-primary" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-end">
                        <button onClick={() => props.onEdit(item)} className="dropdown-item"><FiEdit className="mn-btn-icon" />Chỉnh sửa</button>
                        <button onClick={() => props.onDelete(item)} className="dropdown-item text-danger"><IoTrashOutline className="mn-btn-icon" />Xóa</button>
                    </div>
                </div>
            </td>
        </tr>)
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Họ tên</th>
                        <th>Điện thoại</th>
                        <th>Địa chỉ</th>
                        <th>Ngày tạo</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
    data: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
}

Table.defaultProps = {
    pageItem: 0,
    pageIndex: 0,
    data: null,
    onEdit: () => { },
    onDelete: () => { }
}

export default Table