import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as yup from "yup"
import { CategoryDropdownTree, CloseButton, Config, InputSelect, SaveButton, UploadImg } from './../../../../../components'

const invalidValue = 'Giá trị không hợp lệ'

const schema = yup.object().shape({
    ProductName: yup.string().required('Vui lòng nhập tên sản phẩm').max(200, 'Tên sản phẩm quá dài'),
    ProductCode: yup.string().required('Vui lòng nhập mã sản phẩm').max(50, 'Mã sản phẩm quá dài'),
    CategoryId: yup.number().min(1, 'Vui lòng chọn nhóm sản phẩm'),
    InputPrice: yup.number().min(0, invalidValue),
    OutputPrice: yup.number().required('Vui lòng nhập giá bán').min(0, invalidValue),
    StatusId: yup.number().oneOf([1, 2, 3], invalidValue),
    Detail: yup.string().max(4_000, 'Chi tiết sản phẩm quá dài'),
    AvatarUrl: yup.string().max(2_000, 'Đường dẫn ảnh quá dài'),
    IsApplyForAll: yup.bool(),
    ProductId: yup.number()
})

function ModalEdit(props) {
    const { control, register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            StatusId: 1
        }
    })

    const [isBusy, setIsBusy] = useState(false)

    const [productStatus, setProductStatus] = useState([])

    useEffect(() => {
        const sttList = props.productStatus.map(item => {
            return {
                value: item.ProductStatusId,
                label: item.ProductStatusName
            }
        })
        setProductStatus(sttList)
    }, [props.productStatus])

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="xl"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                const info = props.info
                setValue('ProductName', info.ProductName)
                setValue('ProductCode', info.ProductCode)
                setValue('InputPrice', info.InputPrice)
                setValue('OutputPrice', info.OutputPrice)
                setValue('StatusId', info.ProductStatusId)
                setValue('Detail', info.Detail == null ? '' : info.Detail)
                setValue('AvatarUrl', info.AvatarUrl == null ? '' : info.AvatarUrl)
                setValue('CategoryId', info.CategoryId)
                setValue('IsApplyForAll', true)
                setValue('ProductId', info.ProductId)

                const cat = props.category.filter(n => n.CategoryId === info.CategoryId).map(item => {
                    return {
                        value: item.CategoryId,
                        label: item.CategoryName
                    }
                })[0]

                setValue('CategoryObj', cat)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa sản phẩm</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                            <Controller
                                control={control}
                                name="AvatarUrl"
                                render={({
                                    field: { onChange, value }
                                }) => (
                                    <UploadImg
                                        imgUrl={value}
                                        onCompleted={data => {
                                            onChange(data.Result.ImageUrl)
                                        }}
                                    />
                                )}
                            />
                            {errors.AvatarUrl && <div className="validation-message">{errors.AvatarUrl.message}</div>}
                        </div>
                        <div className="col-sm-8 col-md-9 col-lg-9 col-xl-10">
                            <div className="row">
                                <div className="col-12">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Tên sản phẩm <span className="text-danger">*</span></label>
                                        <input {...register('ProductName')} type="text" className="form-control" placeholder="Tên sản phẩm" />
                                        {errors.ProductName && <div className="validation-message">{errors.ProductName.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-3 col-xxl-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Mã sản phẩm <span className="text-danger">*</span></label>
                                        <input {...register('ProductCode')} type="text" className="form-control" placeholder="Mã sản phẩm" />
                                        {errors.ProductCode && <div className="validation-message">{errors.ProductCode.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Mã sản phẩm nhà cung cấp</label>
                                        <input {...register('SupplierProductCode')} type="text" className="form-control" placeholder="Mã sản phẩm nhà cung cấp" />
                                        {errors.SupplierProductCode && <div className="validation-message">{errors.SupplierProductCode.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 col-xxl-5">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Nhóm sản phẩm <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="CategoryId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <CategoryDropdownTree
                                                    options={props.category}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                        {errors.CategoryId && <div className="validation-message">{errors.CategoryId.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Giá nhập</label>
                                        <div className="input-group">
                                            <Controller
                                                name="InputPrice"
                                                control={control}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <NumberFormat
                                                            placeholder='Giá nhập'
                                                            className="form-control"
                                                            // thousandSeparator={"."}
                                                            // decimalSeparator={","}
                                                            allowNegative={false}
                                                            defaultValue={0}
                                                            decimalScale={0}
                                                            value={value}
                                                            onValueChange={val => onChange(val.value)}
                                                        />
                                                    )
                                                }}
                                            />
                                            <span className="input-group-text">VNĐ</span>
                                        </div>
                                        {errors.InputPrice && <div className="validation-message">{errors.InputPrice.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Giá bán <span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <Controller
                                                name="OutputPrice"
                                                control={control}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <NumberFormat
                                                            placeholder='Giá bán'
                                                            className="form-control"
                                                            // thousandSeparator={"."}
                                                            // decimalSeparator=","
                                                            allowNegative={false}
                                                            defaultValue={0}
                                                            decimalScale={0}
                                                            value={value}
                                                            onValueChange={val => onChange(val.value)}
                                                        />
                                                    )
                                                }}
                                            />
                                            <span className="input-group-text">VNĐ</span>
                                        </div>
                                        {errors.OutputPrice && <div className="validation-message">{errors.OutputPrice.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Trạng thái</label>
                                        <Controller
                                            name="StatusId"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <InputSelect
                                                        options={productStatus}
                                                        value={value}
                                                        defaultValue={1}
                                                        name='product-add'
                                                        variant='success'
                                                        onChange={onChange}
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Chi tiết sản phẩm</label>
                        <Controller
                            control={control}
                            name="Detail"
                            render={({
                                field: { onChange, value }
                            }) => (
                                <ReactQuill
                                    value={value}
                                    modules={Config.editorCfg}
                                    onChange={onChange}
                                />
                            )}
                        />
                        {errors.Detail && <div className="validation-message">{errors.Detail.message}</div>}
                    </div>
                    {
                        props.info.IsParent && <div className="text-center mt-2">
                            <div className="form-check form-checkbox-success display-inline-block">
                                <input type="checkbox" className="form-check-input" id="edit-price" {...register('IsApplyForAll')} />
                                <label className="form-check-label" htmlFor="edit-price">Áp dụng cho tất cả các sản phẩm con</label>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal >
    )
}

ModalEdit.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    category: PropTypes.array,
    productStatus: PropTypes.array,
    groupList: PropTypes.array,
    info: PropTypes.object
}

ModalEdit.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    category: [],
    productStatus: [],
    info: {}
}

export default ModalEdit