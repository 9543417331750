import axios from 'axios'

const CategoryService = {
    filter: (filters) => {
        const url = `/admin/category/filter?keyword=${filters.keyword}`
        const request = axios.get(url)
        return request
    },
    getDropdown: () => {
        const url = '/admin/category/get-dropdown'
        const request = axios.get(url)
        return request
    },
    create: (formData) => {
        const url = '/admin/category/create'
        return axios.post(url, formData)
    },
    getById: (categoryId) => {
        const url = `/admin/category/get-by-id?id=${categoryId}`
        const request = axios.get(url)
        return request
    },
    update: (formData) => {
        const url = '/admin/category/update'
        return axios.post(url, formData)
    },
    delete: (categoryId) => {
        const url = `/admin/category/delete?id=${categoryId}`
        const request = axios.post(url)
        return request
    }
}

export default CategoryService