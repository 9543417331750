import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

function RevenueProfit(props) {
    const { data } = props

    const [series, setSeries] = useState(
        [{
            name: 'Doanh thu',
            data: []
        }]
    )

    const [options, setOptions] = useState(
        {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories: [],
                position: '',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: false,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: true,
                    formatter: function (val) {
                        return val.toLocaleString('vi-VN');
                    }
                }
            },
            title: {
                text: 'Title',
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#444'
                }
            }
        }
    )

    useEffect(() => {
        const valueList = data.map(item => {
            return item.Value
        })
        setSeries(
            [{
                name: 'Doanh thu',
                data: valueList
            }]
        )

        const dateList = data.map(item => {
            return item.Date
        })
        setOptions({
            ...options,
            xaxis: {
                ...options.xaxis,
                categories: dateList
            }
        })
        // eslint-disable-next-line
    }, [data])

    return (
        <div className="card">
            <div className="card-body">
                <ReactApexChart options={options} series={series} type="bar" height={279} />
            </div>
        </div>
    )
}

RevenueProfit.propTypes = {
    data: PropTypes.array,
}

RevenueProfit.defaultProps = {
    data: []
}

export default RevenueProfit