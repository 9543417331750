import axios from "axios";
import PropTypes from "prop-types";
import React, { Fragment, useRef, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";

const maxSizeInBute = 5_242_880; // 5MB

const apiUrl = window.apiUrl;

function UploadFileButton(props) {
  const fileInput = useRef(null);

  const handleTrigger = () => {
    fileInput.current.click();
  };

  const handleUpload = (e) => {
    let files = e.target.files;
    if (files.length !== 0) {
      let file = files[0];
      let name = file.name;
      let sizeInByte = file.size;
      if (sizeInByte > maxSizeInBute) {
        return;
      }
      const formData = new FormData();
      formData.append("formFile", file);
      formData.append("fileName", name);
      const url = apiUrl + "/partner/storage/upload-file";
      setIsBusy(true);
      axios
        .post(url, formData)
        .then((res) => {
          let data = res.data;
          if (props.onCompleted) {
            props.onCompleted(data);
          }
        })
        .finally(() => setIsBusy(false));
    }
  };

  const [isBusy, setIsBusy] = useState(false);

  const renderIcon = () => {
    if (isBusy) {
      return (
        <div
          className="spinner-border spinner-border-sm me-1"
          role="status"
        ></div>
      );
    } else {
      return <IoCloudUploadOutline className="mt--3-px me-1 font-17" />;
    }
  };

  return (
    <Fragment>
      <input
        style={{ display: "none" }}
        type="file"
        accept=".pdf"
        ref={fileInput}
        onChange={handleUpload}
      />
      <button
        onClick={handleTrigger}
        className="btn btn-success"
        disabled={isBusy}
        type="button"
      >
        {renderIcon()}
        Chọn file pdf
      </button>
    </Fragment>
  );
}

UploadFileButton.propTypes = {
  onCompleted: PropTypes.func,
};

export default UploadFileButton;
