import PropTypes from 'prop-types'
import React from 'react'
import {
    IoBagHandleOutline, IoBicycleOutline, IoCardOutline, IoChatboxEllipsesOutline, IoCubeOutline,
    IoExtensionPuzzleOutline, IoLogOutOutline, IoPeopleOutline, IoPersonOutline, IoPieChartOutline, IoPricetagOutline, IoReceiptOutline, IoSettingsOutline, IoShirtOutline, IoSpeedometerOutline, IoTicketOutline
} from 'react-icons/io5'
import { NavLink } from "react-router-dom"
import { AuthAdminView } from '..'

const AdminLeftMenu = (props) => {
    return (
        <ul className="side-nav">

            <li className="side-nav-title side-nav-item">Trang chủ</li>

            <li className="side-nav-item">
                <NavLink to="/admin/dashboard" className="side-nav-link">
                    <IoSpeedometerOutline />
                    <span> Tổng quan </span>
                </NavLink>
            </li>

            <li className="side-nav-title side-nav-item">Danh mục</li>

            <AuthAdminView right="partner_group_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/partner-group" className="side-nav-link">
                        <IoPeopleOutline />
                        <span> Nhóm cộng tác viên </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="partner_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/partner" className="side-nav-link">
                        <IoPersonOutline />
                        <span> Cộng tác viên </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="category_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/category" className="side-nav-link">
                        <IoCubeOutline />
                        <span> Nhóm sản phẩm </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="product_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/product" className="side-nav-link">
                        <IoShirtOutline />
                        <span> Sản phẩm </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="attribute_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/attribute" className="side-nav-link">
                        <IoPricetagOutline />
                        <span> Thuộc tính </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="shipping_unit_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/shipping-unit" className="side-nav-link">
                        <IoBicycleOutline />
                        <span> Đơn vị vận chuyển </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <li className="side-nav-title side-nav-item">Quản lý</li>

            <AuthAdminView right="order_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/order" className="side-nav-link">
                        <IoReceiptOutline />
                        <span> Đơn hàng </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="statistic_partner_order">
                <li className="side-nav-item">
                    <NavLink to="/admin/statistic-partner-order" className="side-nav-link">
                        <IoPieChartOutline />
                        <span> Thống kê đơn hàng </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="popup_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/popup" className="side-nav-link">
                        <IoChatboxEllipsesOutline />
                        <span> Popup </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="popup_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/freeship" className="side-nav-link">
                        <IoBagHandleOutline />
                        <span> Miễn phí đóng gói </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <li className="side-nav-title side-nav-item">Tài khoản</li>

            <AuthAdminView right="account_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/user-info" className="side-nav-link">
                        <IoCardOutline />
                        <span> Thông tin cá nhân </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <li className="side-nav-item">
                <span className="side-nav-link cursor-point" onClick={() => {
                    props.onLogout()
                }}>
                    <IoLogOutOutline />
                    <span> Đăng xuất </span>
                </span>
            </li>

            <li className="side-nav-title side-nav-item">Hệ thống</li>

            <AuthAdminView right="user_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/user" className="side-nav-link">
                        <IoExtensionPuzzleOutline />
                        <span> Thành viên </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="role_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/role" className="side-nav-link">
                        <IoTicketOutline />
                        <span> Vai trò </span>
                    </NavLink>
                </li>
            </AuthAdminView>

            <AuthAdminView right="setting_view">
                <li className="side-nav-item">
                    <NavLink to="/admin/setting" className="side-nav-link">
                        <IoSettingsOutline />
                        <span> Cấu hình </span>
                    </NavLink>
                </li>
            </AuthAdminView>

        </ul>
    )
}

AdminLeftMenu.propTypes = {
    onLogout: PropTypes.func,
}

AdminLeftMenu.defaultProps = {
    onLogout: () => { }
}

export default AdminLeftMenu