import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    RoleName: yup.string().required('Vui lòng nhập tên vai trò').max(50, 'Tên vai trò quá dài'),
    RoleNote: yup.string().max(100, 'Ghi chú quá dài')
})

function ModalAdd(props) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới vai trò</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tên vai trò <span className="text-danger">*</span></label>
                        <input {...register('RoleName')} type="text" className="form-control" placeholder="Tên vai trò" />
                        {errors.RoleName && <div className="validation-message">{errors.RoleName.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Ghi chú</label>
                        <textarea {...register('RoleNote')} rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                        {errors.RoleNote && <div className="validation-message">{errors.RoleNote.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAdd.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool
}

ModalAdd.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false
}

export default ModalAdd