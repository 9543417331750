import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton, InputSelect, CategoryDropdownTree } from './../../../../../components'

const schema = yup.object().shape({
    CategoryName: yup.string().required('Vui lòng nhập tên nhóm sản phẩm').max(100, 'Tên nhóm sản phẩm quá dài'),
    ParentId: yup.number().min(0).integer(),
    StatusId: yup.number().min(1).max(2).integer(),
    Note: yup.string().max(100, 'Ghi chú quá dài')
})

const statusOptions = [
    {
        value: 1,
        label: 'Hoạt động'
    },
    {
        value: 2,
        label: 'Tạm khóa'
    }
]

const ModalAdd = props => {
    const { control, register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            StatusId: 1
        }
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới nhóm sản phẩm</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => props.onSave(formData, setIsBusy))}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tên nhóm sản phẩm <span className="text-danger">*</span></label>
                        <input {...register('CategoryName')} type="text" className="form-control" placeholder="Tên nhóm sản phẩm" />
                        {errors.CategoryName && <div className="validation-message">{errors.CategoryName.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Nhóm cha</label>
                        <Controller
                            name="ParentId"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <CategoryDropdownTree
                                        options={props.parent}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )
                            }}
                        />
                        {errors.ParentId && <div className="validation-message">{errors.ParentId.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Trạng thái</label>
                        <Controller
                            name="StatusId"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <InputSelect
                                        options={statusOptions}
                                        defaultValue={1}
                                        onChange={onChange}
                                        value={value}
                                        variant='success'
                                    />
                                )
                            }}
                        />
                        {errors.StatusId && <div className="validation-message">{errors.StatusId.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Ghi chú</label>
                        <textarea {...register('Note')} rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                        {errors.Note && <div className="validation-message">{errors.Note.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAdd.propTypes = {
    parent: PropTypes.array,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool
}

ModalAdd.defaultProps = {
    parent: [],
    onSave: null,
    onClose: null,
    isShow: false
}

export default ModalAdd