import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { ActionItem, ActionRow, EmptyRow, FormatNumber, LoadingRow } from '../../../../../components'

const renderTable = (props) => {
    let data = props.data
    if (data === null) return <LoadingRow colSpan={7} />
    else if (data.length !== 0) {
        return (
            data.map(item => {
                return (
                    <tr key={item.GroupId}>
                        <td>{item.GroupId}</td>
                        <td>{item.GroupName}</td>
                        <td>{item.Note}</td>
                        <td className="text-end text-primary">
                            <FormatNumber>
                                {item.TotalPartner}
                            </FormatNumber>
                        </td>
                        <td className="text-end text-primary">
                            <FormatNumber>
                                {item.DropShipFee}
                            </FormatNumber>
                        </td>
                        <td>
                            {renderStatus(item.StatusId)}
                        </td>
                        <td className="text-center">
                            <ActionRow>
                                <ActionItem right="role_edit" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                            </ActionRow>
                        </td>
                    </tr>
                )
            })
        )
    }
    else return <EmptyRow colSpan="7" />
}

const renderStatus = (statusId) => {
    if (statusId === 1) return <span className="badge bg-success">Hoạt động</span>
    else return <span className="badge bg-warning">Tạm khóa</span>
}

const Table = props => {
    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">ID</th>
                        <th>Tên nhóm</th>
                        <th>Ghi chú</th>
                        <th className='text-end'>Cộng tác viên</th>
                        <th className='text-end'>Phí dropship</th>
                        <th>Trạng thái</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable(props)}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array
}

Table.defaultProps = {
    data: null
}

export default Table