import React, { Fragment, useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { AuthAdminView, IconButton, Notification } from '../../../components'
import { AttributeService, ChildrenAttributeService } from './../../../services/admin'
import { ModalAddAttr, ModalAddAttrChild, ModalEditAttr, ModalEditAttrChild, TableAttr, TableAttrChild } from './components'

const AttributePage = () => {
    const [attribute, setAttribute] = useState(null)

    const [childrenAttribute, setChildrenAttribute] = useState(null)

    const [isShowModalAttrAdd, setIsShowModalAttrAdd] = useState(false)

    const [isShowModalAttrEdit, setIsShowModalAttrEdit] = useState(false)

    const [isShowModalChildAttrAdd, setIsShowModalChildAttrAdd] = useState(false)

    const [isShowModalChildAttrEdit, setIsShowModalChildAttrEdit] = useState(false)

    const [attrEditInfo, setAttrEditInfo] = useState({})

    const [attributeId, setAttributeId] = useState(0)

    const [childAttrEditInfo, setChildAttrEditInfo] = useState({})

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Thuộc tính'
        loadAttribute()
    }, [])

    useEffect(() => {
        if (attribute !== null) {
            if (attribute.length !== 0) {
                let index = attribute.findIndex(obj => obj.isSelected)
                let item = attribute[index];
                loadChildrenAttribute(item.AttributeId)
            }
        }
    }, [attribute])

    const loadAttribute = () => {
        AttributeService.getAll().then(({ data }) => {
            if (data.IsSuccess) {
                if (data.Result.length !== 0) {
                    let firstAttribute = data.Result[0]
                    let firstAttributeId = firstAttribute.AttributeId
                    let lst = [...data.Result]
                    let index = lst.findIndex(obj => obj.AttributeId === firstAttributeId)
                    lst[index].isSelected = true
                    setAttribute(lst)
                }
                else {
                    setAttribute(data.Result)
                    setChildrenAttribute([])
                }
            }
        })
    }

    const loadChildrenAttribute = (attributeId) => {
        ChildrenAttributeService.getByAttribute(attributeId).then(({ data }) => {
            if (data.IsSuccess) {
                setChildrenAttribute(data.Result)
            }
        })
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-4 col-xxl-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title mb-0">Thuộc tính</h5>
                            {/* Table thuộc tính cha */}
                            <TableAttr
                                onSelect={item => {
                                    let lst = [...attribute]
                                    let prevIndex = lst.findIndex(obj => obj.isSelected)
                                    let nextIndex = lst.findIndex(obj => obj.AttributeId === item.AttributeId)
                                    if (prevIndex !== nextIndex) {
                                        if (prevIndex > -1) {
                                            lst[prevIndex].isSelected = false
                                        }
                                        lst[nextIndex].isSelected = true
                                        loadChildrenAttribute(item.AttributeId)
                                    }

                                }}
                                onEdit={item => {
                                    AttributeService.getById(item.AttributeId).then(({ data }) => {
                                        if (data.IsSuccess) {
                                            setAttrEditInfo(data.Result)
                                            setIsShowModalAttrEdit(true)
                                        }
                                    })
                                }}
                                onDelete={item => {
                                    Notification.confirmHtml('Bạn có chắc muốn xóa thuộc tính', item.AttributeName, () => {
                                        AttributeService.delete(item.AttributeId).then(({ data }) => {
                                            if (data.IsSuccess) {
                                                loadAttribute()
                                                Notification.success('Xóa thuộc tính thành công')
                                            }
                                        })
                                    })
                                }}
                                data={attribute} />
                            <div className="mt-2">
                                {/* Button thêm mới thuộc tính cha */}
                                <AuthAdminView right="attribute_add">
                                    <IconButton
                                        icon={<IoAddCircleOutline />}
                                        text="Thêm mới"
                                        onClick={() => {
                                            setIsShowModalAttrAdd(true)
                                        }} />
                                </AuthAdminView>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-xxl-9">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title mb-0">Giá trị thuộc tính {isShowModalChildAttrAdd}</h5>
                            {/* Table thuộc tính con */}
                            <TableAttrChild
                                onEdit={item => {
                                    ChildrenAttributeService.getByAttribute(item.ChildrenAttributeId).then(({ data }) => {
                                        if (data.IsSuccess) {
                                            setChildAttrEditInfo(data.Result)
                                            setIsShowModalChildAttrEdit(true)
                                        }
                                    })
                                }}
                                onDelete={item => {
                                    Notification.confirmHtml('Bạn có chắc muốn xóa giá trị thuộc tính', item.ChildrenAttributeName, () => {
                                        ChildrenAttributeService.delete(item.ChildrenAttributeId).then(({ data }) => {
                                            if (data.IsSuccess) {
                                                loadChildrenAttribute(item.AttributeId)
                                                Notification.success('Xóa giá trị thuộc tính thành công')
                                            }
                                        })
                                    })
                                }}
                                data={childrenAttribute}
                            />
                            <div className="mt-2">
                                {/* Button thêm mới thuộc tính con */}
                                <AuthAdminView right="attribute_add_child">
                                    <IconButton
                                        icon={<IoAddCircleOutline />}
                                        text="Thêm mới"
                                        onClick={() => {
                                            let selectedAttrIndex = attribute.findIndex(obj => obj.isSelected)
                                            if (selectedAttrIndex > -1) {
                                                let attrId = attribute[selectedAttrIndex].AttributeId
                                                setAttributeId(attrId)
                                            }
                                            setIsShowModalChildAttrAdd(true)
                                        }} />
                                </AuthAdminView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal thêm mới thuộc tính cha */}
            <ModalAddAttr
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    AttributeService.create(formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadAttribute()
                            setIsShowModalAttrAdd(false)
                            Notification.success('Thêm mới thuộc tính thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalAttrAdd(false)
                }}
                isShow={isShowModalAttrAdd}
            />

            {/* Modal chỉnh sửa thuộc tính cha */}
            <ModalEditAttr
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    AttributeService.update(formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadAttribute()
                            setIsShowModalAttrEdit(false)
                            Notification.success('Chỉnh sửa thuộc tính thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalAttrEdit(false)
                }}
                isShow={isShowModalAttrEdit}
                data={attrEditInfo}
            />

            {/* Modal thêm mới thuộc tính con */}
            <ModalAddAttrChild
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    ChildrenAttributeService.create(formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadChildrenAttribute(formData.AttributeId)
                            setIsShowModalChildAttrAdd(false)
                            setAttrEditInfo({})
                            Notification.success('Thêm mới giá trị thuộc tính thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalChildAttrAdd(false)
                    setAttrEditInfo({})
                }}
                isShow={isShowModalChildAttrAdd}
                attrId={attributeId}
            />

            {/* Modal chỉnh sửa thuộc tính con */}
            <ModalEditAttrChild
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    ChildrenAttributeService.update(formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadChildrenAttribute(formData.AttributeId)
                            setIsShowModalChildAttrEdit(false)
                            setChildAttrEditInfo({})
                            Notification.success('Chỉnh sửa giá trị thuộc tính thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setChildAttrEditInfo({})
                    setIsShowModalChildAttrEdit(false)
                }}
                isShow={isShowModalChildAttrEdit}
                data={childAttrEditInfo}
            />
        </Fragment>
    )
}

export default AttributePage