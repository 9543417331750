import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    CurrentPassword: yup.string().required('Vui lòng nhập mật khẩu hiện tại').max(70, 'Mật khẩu hiện tại quá dài'),
    NewPassword: yup.string().required('Vui lòng nhập mật khẩu mới').max(70, 'Mật khẩu mới quá dài'),
    NewPasswordConfirm: yup.string().required('Vui lòng nhập lại mật khẩu mới').max(70, 'Nhập lại mật khẩu mới quá dài')
        .oneOf([yup.ref('NewPassword')], 'Nhập lại mật khẩu không chính xác')
})

function Password(props) {
    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <div className="card">
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy, reset)
            })}>
                <div className="card-body">
                    <h5 className="card-title mb-2">Đổi mật khẩu</h5>
                    <div className="relative-position mb-2">
                        <label className="form-label">Mật khẩu hiện tại <span className="text-danger">*</span></label>
                        <input {...register('CurrentPassword')} type="password" className="form-control" placeholder="Mật khẩu hiện tại" />
                        {errors.CurrentPassword && <div className="validation-message">{errors.CurrentPassword.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Mật khẩu mới <span className="text-danger">*</span></label>
                        <input {...register('NewPassword')} type="password" className="form-control" placeholder="Mật khẩu mới" />
                        {errors.NewPassword && <div className="validation-message">{errors.NewPassword.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Nhập lại mật khẩu mới <span className="text-danger">*</span></label>
                        <input {...register('NewPasswordConfirm')} type="password" className="form-control" placeholder="Nhập lại mật khẩu mới" />
                        {errors.NewPasswordConfirm && <div className="validation-message">{errors.NewPasswordConfirm.message}</div>}
                    </div>
                </div>
                <div className="card-footer">
                    <SaveButton isBusy={isBusy} title="Đổi mật khẩu" />
                </div>
            </form>
        </div>
    )
}

Password.propTypes = {
    onSave: PropTypes.func
}

Password.defaultProps = {
    onSave: () => { }
}

export default Password