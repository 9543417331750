import React, { useEffect, useState } from 'react'
import { OrderBlock, RevenueProfit, BestSellingProduct, PartnerChart } from './..'
import { IoCubeOutline, IoFileTrayOutline, IoReloadOutline } from 'react-icons/io5'
import { BiRun } from 'react-icons/bi'
import axios from 'axios'

const colors = ["#727cf5", "#fa5c7c", "#0acf97", "#ffbc00"]

const MainBlock = () => {
    const [newOrder, setNewOrder] = useState({
        value: 0,
        percent: 0
    })

    const [inProcessOrder, setInProcessOrder] = useState({
        value: 0,
        percent: 0
    })

    const [shippingOrder, setShippingOrder] = useState({
        value: 0,
        percent: 0
    })

    const [countProduct, setCountProduct] = useState({
        value: 0,
        percent: 0
    })

    const [revenueProfit, setRevenueProfit] = useState([])

    const [products, setProducts] = useState(null)

    const [partnerGroupChart, setPartnerGroupChart] = useState({
        series: [],
        options: {
            chart: {
                type: 'donut',
            },
            labels: [],
            legend: {
                show: !1
            },
            colors: colors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
        partnerGroups: []
    })

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Tổng quan'
        loadNewOrder()
        loadInProcessOrder()
        loadShippingOrder()
        loadProductCount()
        loadRevenueProfit()
        loadBestSellingProduct()
        loadPartnerGroup()
    }, [])

    function loadNewOrder() {
        const url = '/admin/dashboard/new-order'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setNewOrder({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadInProcessOrder() {
        const url = '/admin/dashboard/in-process-order'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setInProcessOrder({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadShippingOrder() {
        const url = '/admin/dashboard/shipping-order'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setShippingOrder({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadProductCount() {
        const url = '/admin/dashboard/count-product'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setCountProduct({
                    value: data.Result.Value,
                    percent: data.Result.Percent
                })
            }
        })
    }

    function loadRevenueProfit() {
        const url = `/admin/dashboard/total-revenue`
        const request = axios.get(url)
        request.then(({ data }) => {
            if (data.IsSuccess) {
                setRevenueProfit(data.Result)
            }
        })
    }

    function loadBestSellingProduct() {
        const url = `/admin/dashboard/best-selling`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setProducts(data.Result)
            }
        })
    }

    function loadPartnerGroup() {
        const url = `/admin/dashboard/partner-group`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const series = data.Result.map(item => item.CountPartner)
                const labels = data.Result.map(item => item.PartnerGroupName)
                setPartnerGroupChart({
                    series: series,
                    options: {
                        chart: {
                            type: 'donut',
                        },
                        labels: labels,
                        legend: {
                            show: !1
                        },
                        colors: colors,
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }]
                    },
                    partnerGroups: data.Result
                })
            }
        })
    }

    return (
        <div className="row">
            <div className="col-xl-5 col-lg-6">
                <div className="row">
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<IoFileTrayOutline />}
                            header="Đơn tạo mới"
                            val={newOrder.value}
                            percent={newOrder.percent}
                        />
                    </div>
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<IoReloadOutline />}
                            header="Đang xử lý"
                            val={inProcessOrder.value}
                            percent={inProcessOrder.percent}
                        />
                    </div>
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<BiRun />}
                            header="Đã gửi vận chuyển"
                            val={shippingOrder.value}
                            percent={shippingOrder.percent}
                        />
                    </div>
                    <div className="col-lg-6">
                        <OrderBlock
                            icon={<IoCubeOutline />}
                            header="Số lượng sản phẩm"
                            val={countProduct.value}
                            percent={countProduct.percent}
                        />
                    </div>
                </div>
            </div>
            <div className="col-xl-7 col-lg-6">
                <RevenueProfit
                    data={revenueProfit}
                />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-9">
                <BestSellingProduct
                    products={products}
                />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                <PartnerChart
                    data={partnerGroupChart}
                    colors={colors}
                />
            </div>
        </div>
    )
}

export default MainBlock