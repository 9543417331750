import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    // IsParent: yup.bool(),
    // IsForAllChildrent: yup.bool(),
    // ProductId: yup.number,
    // Prices: yup.array()
})

function ModalEditPrice(props) {
    const { handleSubmit, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const renderTable = () => {
        return props.data.map((item, index) => <tr key={item.GroupId}>
            <td className="text-center">{index + 1}</td>
            <td>{item.GroupName}</td>
            <td>
                <NumberFormat
                    placeholder='Giá nhập'
                    className="form-control"
                    // thousandSeparator={"."}
                    // decimalSeparator={","}
                    allowNegative={false}
                    defaultValue={item.UnitPrice}
                    onValueChange={val => {
                        const temp = [...getValues("Prices")]
                        const index = temp.findIndex(n => n.PartnerGroupId === item.GroupId)
                        temp[index].UnitPrice = parseInt(val.value)
                        setValue("Prices", temp)
                    }}
                />
            </td>
        </tr>)
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" animation={true}
            onExited={() => {
                reset()
            }}
            onShow={() => {
                let temp = []
                temp = props.data.map(item => {
                    return {
                        ProductPriceId: item.ProductPriceId,
                        UnitPrice: item.UnitPrice,
                        PartnerGroupId: item.GroupId
                    }
                })
                setValue("Prices", temp)
                setValue("IsParent", props.isParent)
                setValue("IsForAllChildrent", true)
                setValue("ProductId", props.productId)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa giá theo nhóm cộng tác viên</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-centered table-input mb-0">
                            <thead>
                                <tr>
                                    <th className="w-52-px">#</th>
                                    <th>Nhóm cộng tác viên</th>
                                    <th className="w-150-px">Đơn giá</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTable()}
                            </tbody>
                        </table>
                    </div>
                    {
                        props.isParent && <div className="text-center mt-2">
                            <div className="form-check form-checkbox-success display-inline-block">
                                <input type="checkbox" className="form-check-input" id="edit-price" defaultChecked={true} onChange={e => {
                                    setValue('IsForAllChildrent', e.target.checked)
                                }} />
                                <label className="form-check-label" htmlFor="edit-price">Áp dụng cho tất cả các sản phẩm con</label>
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEditPrice.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    data: PropTypes.array,
    isParent: PropTypes.bool,
    productId: PropTypes.number
}

ModalEditPrice.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    data: [],
    isParent: false,
    productId: 0
}

export default ModalEditPrice