import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { IoPersonAddOutline } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from "yup"
import { SaveButton, Config, AppContext } from './../../../components'

const schema = yup.object().shape({
    Email: yup.string().required('Vui lòng nhập email').email('Địa chỉ email không đúng').max(70, 'Địa chỉ email quá dài'),
    Password: yup.string().required('Vui lòng nhập mật khẩu').max(70, 'Mật khẩu quá dài')
})

function LoginPage(props) {
    const { register, handleSubmit, setFocus, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [message, setMessage] = useState('')

    const history = useHistory()

    const [isBusy, setIsBusy] = useState(() => {
        const a_token = localStorage.getItem('p-token')
        if (a_token === null || a_token === '') return false
        else return true
    })

    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/partner/dashboard" } };

    const appSettings = useContext(AppContext)

    const setInfo = (data) => {
        localStorage.setItem('p-email', data.Result.Email)
        localStorage.setItem('p-fullName', data.Result.FullName)
        localStorage.setItem('p-token', data.Result.Token)
        localStorage.setItem('p-avatarUrl', data.Result.AvatarUrl)
        axios.defaults.headers.common['P-Token'] = data.Result.Token

        appSettings.setPartnerFullName(data.Result.FullName)
        appSettings.setPartnerAvatarUrl(data.Result.AvatarUrl)
    }

    useEffect(() => {
        setFocus('Email')
    }, [setFocus])

    useEffect(() => {
        const p_token = localStorage.getItem('p-token')
        if (p_token !== null && p_token !== '') {
            const url = '/partner/partner/login-token'
            axios.post(url).then(({ data }) => {
                if (data.IsSuccess) {
                    setInfo(data)
                    history.replace(from);
                }
                else {
                    setIsBusy(false)
                }
            })
        }
        else {
            setIsBusy(false)
        }

        // eslint-disable-next-line
    }, [])

    const onLogin = (formData) => {
        formData.LoginTypeId = 1
        if (message !== '') setMessage('')
        setIsBusy(true)
        const url = '/partner/partner/login'
        axios.post(url, formData).then(({ data }) => {
            if (data.IsSuccess) {
                setInfo(data)
                history.replace(from)
            }
            else {
                if (data.Message !== '' || data.Message !== null) {
                    setMessage(data.Message)
                    setTimeout(() => {
                        setMessage('')
                    }, 3_000)
                }
            }
            setIsBusy(false)
        })
    }

    return (
        <div className="auth-fluid">
            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">

                        <div className="auth-brand left-0 right-0 text-center">
                            <span className="logo-dark">
                                <span><img src="/assets/images/partner-logo.png" alt="Logo" height={Config.partnerLoginLogoHeight} /></span>
                            </span>
                            <span className="logo-light">
                                <span><img src="/assets/images/partner-logo.png" alt="Logo" height={Config.partnerLoginLogoHeight} /></span>
                            </span>
                        </div>

                        <h4 className="mt-0">Đăng nhập</h4>

                        <p className="text-muted mb-4">Đăng nhập để bắt đầu phiên làm việc</p>

                        <form onSubmit={handleSubmit(formData => onLogin(formData))}>

                            <div className="mb-2 position-relative">
                                <label className="form-label">Email <span className="text-danger">*</span></label>
                                <input {...register('Email')} className="form-control" type="text" placeholder="Email"
                                    disabled={isBusy}
                                    autoComplete="off" />
                                {errors.Email && <div className="validation-message">{errors.Email.message}</div>}
                            </div>

                            <div className="mb-2 position-relative">
                                <label className="form-label">Mật khẩu <span className="text-danger">*</span></label>
                                <input {...register('Password')} className="form-control" type="password" placeholder="Mật khẩu"
                                    disabled={isBusy}
                                    autoComplete="off" />
                                {errors.Password && <div className="validation-message">{errors.Password.message}</div>}
                            </div>

                            {message !== '' ? <div className="mb-2">
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close"
                                        onClick={() => {
                                            setMessage('')
                                        }}
                                    ></button>
                                    {message}
                                </div>
                            </div> : ''}

                            <div className="text-center">
                                <SaveButton
                                    className="w-100"
                                    title="Đăng nhập"
                                    isBusy={isBusy}
                                    icon={<IoPersonAddOutline className="mt--3-px me-1 font-17" />}
                                />
                            </div>

                            <footer className="footer footer-alt">
                                <p className="text-muted">Bạn chưa có tài khoản? Nhấn <span onClick={() => {
                                    history.push('/partner/register')
                                }} className="text-danger cursor-point"><b>vào đây</b></span> để đăng ký tài khoản cộng tác viên</p>
                            </footer>

                        </form>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default LoginPage