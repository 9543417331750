import PropTypes from 'prop-types'
import React from 'react'
import { IoBagHandleOutline, IoPeopleOutline, IoPersonOutline, IoWalletOutline } from 'react-icons/io5'
import { FormatNumber } from '../../../../../components'

function PartnerInfo(props) {
    const { info } = props

    return (
        <div className="card widget-inline">
            <div className="card-body p-0">
                <div className="row g-0">
                    <div className="col-sm-6 col-xl-3">
                        <div className="card shadow-none m-0">
                            <div className="card-body text-center">
                                <IoWalletOutline className="fs-2 text-primary" />
                                <h3>
                                    <span>
                                        <FormatNumber>
                                            {info.balance}
                                        </FormatNumber>
                                    </span>
                                </h3>
                                <p className="text-success font-15 mb-0">Số dư tài khoản</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-xl-3">
                        <div className="card shadow-none m-0 border-start no-border-radius">
                            <div className="card-body text-center">
                                <IoBagHandleOutline className="fs-2 text-primary" />
                                <h3>
                                    <span>
                                        <FormatNumber>
                                            {info.freeShipQty}
                                        </FormatNumber>
                                    </span>
                                </h3>
                                <p className="text-success font-15 mb-0">Miễn phí đóng gói</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-xl-3">
                        <div className="card shadow-none m-0 border-start no-border-radius">
                            <div className="card-body text-center">
                                <IoPersonOutline className="fs-2 text-primary" />
                                <h3>
                                    <span>
                                        {info.groupName}
                                    </span>
                                </h3>
                                <p className="text-success font-15 mb-0">Nhóm cộng tác viên</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-xl-3">
                        <div className="card shadow-none m-0 border-start no-border-radius">
                            <div className="card-body text-center">
                                <IoPeopleOutline className="fs-2 text-primary" />
                                <h3>
                                    <span>
                                        <FormatNumber>
                                            {info.countCustomer}
                                        </FormatNumber>
                                    </span>
                                </h3>
                                <p className="text-success font-15 mb-0">Số lượng khách hàng</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

PartnerInfo.propTypes = {
    info: PropTypes.objectOf(PropTypes.any)
}

PartnerInfo.defaultProps = {
    info: {
        balance: 0,
        countCustomer: 0,
        orderToday: 0,
        groupName: ''
    }
}

export default PartnerInfo