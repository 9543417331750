import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    Title: yup.string().required('Vui lòng nhập tiêu đề').max(200, 'Tiêu đề quá dài'),
    Content: yup.string().required('Vui lòng nhập nội dung').max(4_000, 'Nội dung quá dài')
})

const ModalEdit = props => {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" animation={true}
            onExited={() => {
                reset()
            }}
            onShow={() => {
                let info = props.info
                setValue('PopupId', info.PopupId)
                setValue('Title', info.Title)
                setValue('Content', info.Content)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa popup</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tiêu đề <span className="text-danger">*</span></label>
                        <input {...register('Title')} type="text" className="form-control" placeholder="Tiêu đề" />
                        {errors.Title && <div className="validation-message">{errors.Title.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Nội dung <span className="text-danger">*</span></label>
                        <textarea {...register('Content')} rows="3" className="form-control" placeholder="Nội dung"></textarea>
                        {errors.Content && <div className="validation-message">{errors.Content.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEdit.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    info: PropTypes.object
}

ModalEdit.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    info: {}
}

export default ModalEdit

