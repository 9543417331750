import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'
import Modal from 'react-bootstrap/Modal'

const schema = yup.object().shape({
    ChildrenAttributeName: yup.string().required('Vui lòng nhập tên thuộc tính').max(50, 'Tên giá trị quá dài')
})

const ModalAddAttrChild = props => {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                setValue("AttributeId", props.attrId)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới giá trị thuộc tính</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position">
                        <label className="form-label">Giá trị thuộc tính <span className="text-danger">*</span></label>
                        <input {...register('ChildrenAttributeName')} type="text" className="form-control" placeholder="Giá trị thuộc tính" />
                        {errors.ChildrenAttributeName && <div className="validation-message">{errors.ChildrenAttributeName.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAddAttrChild.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    attrId: PropTypes.number
}

ModalAddAttrChild.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    attrId: 0
}

export default ModalAddAttrChild