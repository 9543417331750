import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const schema = yup.object().shape({

})

function ModalEditQty(props) {
    const { control, handleSubmit, reset, setValue } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const renderTable = () => {
        return props.productList.map((item, index) => {
            return <tr key={item.ProductId}>
                <td className="text-cemter">{index + 1}</td>
                <td>{item.ProductName}</td>
                <td>
                    <Controller
                        control={control}
                        name={`Products.${index}.Quantity`}
                        render={({
                            field: { onChange, onBlur, value, name, ref }
                        }) => (
                            <NumberFormat
                                placeholder='Số lượng'
                                className="form-control"
                                // thousandSeparator={"."}
                                // decimalSeparator={","}
                                allowNegative={false}
                                value={value}
                                onValueChange={val => onChange(parseInt(val.value))}
                            />
                        )}
                    />
                </td>
            </tr>
        })
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" animation={true} size="lg"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                const temp = props.productList.map((item, index) => {
                    return {
                        ProductId: item.ProductId,
                        Quantity: item.Quantity
                    }
                })
                setValue('Products', temp)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Cập nhật số lượng sản phẩm</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="table-responsive">
                        <table className="table table-centered table-input mb-0">
                            <thead>
                                <tr>
                                    <th className="w-52-px">#</th>
                                    <th>Tên sản phẩm</th>
                                    <th className="w-150-px">Số lượng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTable()}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEditQty.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    data: PropTypes.array
}

ModalEditQty.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    productList: []
}

export default ModalEditQty