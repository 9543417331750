import React, { Fragment } from 'react'
import { AiOutlineKey } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import {
    IoLogOutOutline, IoTrashOutline, IoWalletOutline,
    IoListOutline, IoReceiptOutline,
    IoStorefrontOutline, IoBagHandleOutline, IoLayersOutline
} from 'react-icons/io5'
import { AiOutlineHistory } from 'react-icons/ai'
import { AvatarRow, CloseButton, EmptyRow, FormatNumber, LoadingRow, AuthAdminView } from './../../../../../components'
import PropTypes from 'prop-types'
import { BalanceTable, OrderTable, Detail, ShopeeTable } from './../'

const Table = props => {
    const { onShowChildren } = props

    const renderTable = () => {
        let data = props.data;
        if (data === null) return <LoadingRow colSpan={6} />
        else if (data.length === 0) return <EmptyRow colSpan={6} />
        else return data.map(item => {
            return (
                <Fragment key={item.PartnerId}>
                    <tr onClick={() => props.onOpenDetail(item)} className={(item.isSelected ? 'row-master' : '')}>
                        <td>
                            <AvatarRow url={item.AvatarUrl} />
                            {item.FullName}
                        </td>
                        <td>{item.Email}</td>
                        <td>{item.Phone}</td>
                        <td className="text-end text-primary">
                            <FormatNumber>{item.Balance}</FormatNumber>
                        </td>
                        <td className={item.GroupId === 0 ? 'text-danger' : ''}>{item.GroupName}</td>
                        <td>
                            {renderStatus(item.StatusId)}
                        </td>
                    </tr>
                    {renderDetail(item)}
                </Fragment>
            )
        })
    }

    const renderStatus = (statusId) => {
        if (statusId === 1) return <span className="badge bg-success">Hoạt động</span>
        else return <span className="badge bg-warning">Tạm khóa</span>
    }

    const renderDetail = item => {
        if (item.isSelected) {
            return (
                <tr className="row-detail">
                    <td colSpan="6" className="ps-1 pt-0 pe-1 pb-1">
                        <div className="card mb-0 cursor-default">
                            <div className="card-body">
                                <ul className="nav nav-tabs nav-bordered mb-3">
                                    <li className="nav-item">
                                        <a href="#tab-1" data-bs-toggle="tab" aria-expanded="false" className="nav-link active">
                                            <IoListOutline className='d-md-none d-block' />
                                            <span className="d-none d-md-block">Chi tiết</span>
                                        </a>
                                    </li>
                                    <AuthAdminView right="partner_order_filter">
                                        <li className="nav-item">
                                            <a href="#tab-2" data-bs-toggle="tab" aria-expanded="true" className="nav-link">
                                                <IoReceiptOutline className='d-md-none d-block' />
                                                <span className="d-none d-md-block">Đơn hàng</span>
                                            </a>
                                        </li>
                                    </AuthAdminView>
                                    <AuthAdminView right="partner_balance_history">
                                        <li className="nav-item">
                                            <a href="#tab-3" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                <AiOutlineHistory className='d-md-none d-block' />
                                                <span className="d-none d-md-block">Lịch sử số dư</span>
                                            </a>
                                        </li>
                                    </AuthAdminView>
                                    <AuthAdminView right="partner_shopee_list">
                                        <li className="nav-item">
                                            <a href="#tab-4" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                <IoStorefrontOutline className='d-md-none d-block' />
                                                <span className="d-none d-md-block">Shopee</span>
                                            </a>
                                        </li>
                                    </AuthAdminView>
                                    <AuthAdminView right='partner_freeship_his'>
                                        <li className="nav-item">
                                            <a href="#tab-5" data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                                                <IoBagHandleOutline className='d-md-none d-block' />
                                                <span className="d-none d-md-block">Lịch sử miễn phí đóng gói</span>
                                            </a>
                                        </li>
                                    </AuthAdminView>
                                </ul>

                                <div className="tab-content">
                                    <div className="tab-pane show active" id="tab-1">
                                        <Detail
                                            detail={props.detail}
                                        />
                                    </div>
                                    <AuthAdminView right="partner_order_filter">
                                        <div className="tab-pane" id="tab-2">
                                            <OrderTable
                                                orders={props.partnerOrders}
                                                pagination={props.partnerOrderPagination}
                                                onPageChange={(pIndex) => {
                                                    props.onPartnerOrderPageChange(item.PartnerId, pIndex)
                                                }}
                                            />
                                        </div>
                                    </AuthAdminView>
                                    <AuthAdminView right="partner_balance_history">
                                        <div className="tab-pane" id="tab-3">
                                            <BalanceTable
                                                balanceHistories={props.balanceHistories}
                                                pagination={props.balanceHistoryPagination}
                                                onPageChange={(pIndex) => {
                                                    props.onBalanceHistoryPageChange(item.PartnerId, pIndex)
                                                }}
                                                pageItem={props.balancePageItem}
                                                pageIndex={props.balancePageIndex}
                                            />
                                        </div>
                                    </AuthAdminView>
                                    <AuthAdminView right="partner_shopee_list">
                                        <div className="tab-pane" id="tab-4">
                                            <ShopeeTable
                                                shops={props.shopeeShops}
                                            />
                                        </div>
                                    </AuthAdminView>
                                    <AuthAdminView right='partner_freeship_his'>
                                        <div className="tab-pane" id="tab-5">
                                            {props.children}
                                        </div>
                                    </AuthAdminView>
                                </div>
                            </div>

                            <div className="p-2 border-top">
                                <div className="button-list">
                                    <AuthAdminView right="partner_edit">
                                        <button onClick={() => props.onEdit(item)} className="btn btn-primary"><FiEdit className="me-1 mt--2-px font-16" />Chỉnh sửa</button>
                                    </AuthAdminView>
                                    <AuthAdminView right="partner_recharge">
                                        <button onClick={() => props.onRecharge(item)} className="btn btn-info"><IoWalletOutline className="me-1 mt--2-px font-16" />Nạp tiền</button>
                                    </AuthAdminView>
                                    <AuthAdminView right="partner_set_password">
                                        <button onClick={() => props.onSetPassword(item)} className="btn btn-success"><AiOutlineKey className="me-1 mt--2-px font-16" />Đặt lại mật khẩu</button>
                                    </AuthAdminView>
                                    <AuthAdminView right="partner_logout">
                                        <button onClick={() => props.onLogOut(item)} className="btn btn-warning"><IoLogOutOutline className="me-1 mt--2-px font-16" />Đăng xuất</button>
                                    </AuthAdminView>

                                    <button onClick={() => onShowChildren(item)} className="btn btn-primary"><IoLayersOutline className="me-1 mt--2-px font-16" />Đã giới thiệu</button>

                                    <AuthAdminView right="partner_delete">
                                        <button onClick={() => props.onDelete(item)} className="btn btn-danger"><IoTrashOutline className="me-1 mt--2-px font-16" />Xóa</button>
                                    </AuthAdminView>
                                    <CloseButton onClick={() => props.onCloseDetail(item)} />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        }
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered table-point table-avatar mb-0">
                <thead>
                    <tr>
                        <th>Họ tên</th>
                        <th>Email</th>
                        <th>Điện thoại</th>
                        <th className='text-end'>Số dư TK</th>
                        <th>Nhóm</th>
                        <th>Trạng thái</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    onOpenDetail: PropTypes.func,
    onEdit: PropTypes.func,
    onRecharge: PropTypes.func,
    onSetPassword: PropTypes.func,
    onLogOut: PropTypes.func,
    onDelete: PropTypes.func,
    onCloseDetail: PropTypes.func,
    onShowChildren: PropTypes.func,

    detail: PropTypes.object,

    partnerOrders: PropTypes.array,
    partnerOrderPagination: PropTypes.object,

    balanceHistories: PropTypes.array,
    balanceHistoryPagination: PropTypes.object,
    onBalanceHistoryPageChange: PropTypes.func,
    balancePageItem: PropTypes.number,
    balancePageIndex: PropTypes.number,

    shopeeShops: PropTypes.array
}

Table.defaultProps = {
    data: null,
    onOpenDetail: null,
    onEdit: null,
    onRecharge: null,
    onSetPassword: null,
    onLogOut: null,
    onDelete: null,
    onCloseDetail: null,
    onShowChildren: () => { },

    detail: {},

    partnerOrders: [],
    partnerOrderPagination: {},
    onPartnerOrderPageChange: () => { },

    balanceHistories: [],
    balanceHistoryPagination: {},
    onBalanceHistoryPageChange: () => { },
    balancePageItem: 0,
    balancePageIndex: 0,

    shopeeShops: [],
}

export default Table