import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton, UploadImg } from './../../../../../components'

const schema = yup.object().shape({
    GroupId: yup.number().min(1, 'Vui lòng chọn nhóm'),
    WardId: yup.number(),
    FullName: yup.string().required('Vui lòng nhập tên nhóm').max(100, 'Tên nhóm quá dài').nullable(),
    ShopName: yup.string().max(100, 'Tên shop quá dài').nullable(),
    Phone: yup.string().required('Vui lòng nhập số điện thoại').max(20, 'Số điện thoại quá dài').nullable(),
    Email: yup.string().required('Vui lòng nhập email').max(70, 'Email quá dài').email('Email không đúng').nullable(),
    Password: yup.string().required('Vui lòng nhập mật khẩu').max(100, 'Mật khẩu quá dài').nullable(),
    Address: yup.string().nullable(),
    StatusId: yup.number(),
    ShipFeeId: yup.number(),
    Note: yup.string().max(200, 'Ghi chú quá dài').nullable(),
    GHTKToken: yup.string().max(500, 'Token quá dài').nullable(),
    AvatarUrl: yup.string().max(2_000, 'Đường dẫn ảnh quá dài').nullable()
})

const ModalAdd = props => {
    const { control, register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            size="lg"
            onExited={() => {
                reset()
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới cộng tác viên</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => props.onSave(formData, setIsBusy))}>
                <Modal.Body>
                    <div className="row row-cols-1 row-cols-lg-3">
                        <div className="col-sm-4 col-md-3">
                            <Controller
                                control={control}
                                name="AvatarUrl"
                                render={({
                                    field: { onChange, value }
                                }) => (
                                    <UploadImg
                                        imgUrl={value}
                                        onCompleted={data => {
                                            onChange(data.Result.ImageUrl)
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-sm-8 col-md-9">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Họ tên <span className="text-danger">*</span></label>
                                        <input {...register('FullName')} type="text" className="form-control" placeholder="Họ tên" />
                                        {errors.FullName && <div className="validation-message">{errors.FullName.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Tên shop</label>
                                        <input {...register('ShopName')} type="text" className="form-control" placeholder="Tên shop" />
                                        {errors.ShopName && <div className="validation-message">{errors.ShopName.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Điện thoại <span className="text-danger">*</span></label>
                                        <input {...register('Phone')} type="text" className="form-control" placeholder="Điện thoại" />
                                        {errors.Phone && <div className="validation-message">{errors.Phone.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Email <span className="text-danger">*</span></label>
                                        <input {...register('Email')} type="text" className="form-control" placeholder="Email" />
                                        {errors.Email && <div className="validation-message">{errors.Email.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Nhóm cộng tác viên <span className="text-danger">*</span></label>
                                        <select {...register('GroupId')} className="form-select">
                                            <option value="0">Nhóm cộng tác viên</option>
                                            {
                                                props.partnerGroup.map(item => {
                                                    return (
                                                        <option key={item.GroupId} value={item.GroupId}>{item.GroupName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {errors.GroupId && <div className="validation-message">{errors.GroupId.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Mật khẩu <span className="text-danger">*</span></label>
                                        <input {...register('Password')} type="Password" className="form-control" placeholder="Mật khẩu" />
                                        {errors.Password && <div className="validation-message">{errors.Password.message}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="relative-position mb-2">
                                <label className="form-label">Tỉnh/Tp</label>
                                <select className="form-select" onChange={e => {
                                    let provinceId = e.target.value
                                    props.onProvinceChange(provinceId)
                                    setValue('WardId', 0)
                                }}>
                                    <option value="0">Chọn Tỉnh/Tp</option>
                                    {
                                        props.province.map(item => {
                                            return (
                                                <option key={item.ProvinceId} value={item.ProvinceId}>{item.Prefix} {item.ProvinceName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="relative-position mb-2">
                                <label className="form-label">Quận/Huyện</label>
                                <select className="form-select" onChange={e => {
                                    let districtId = e.target.value
                                    props.onDistrictChange(districtId)
                                    setValue('WardId', 0)
                                }}>
                                    <option value="0">Chọn Quận/Huyện</option>
                                    {
                                        props.district.map(item => {
                                            return (
                                                <option key={item.DistrictId} value={item.DistrictId}>{item.Prefix} {item.DistrictName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <div className="relative-position mb-2">
                                <label className="form-label">Phường/Xã</label>
                                <select {...register('WardId')} className="form-select">
                                    <option value="0">Chọn Phường/Xã</option>
                                    {
                                        props.ward.map(item => {
                                            return (
                                                <option key={item.WardId} value={item.WardId}>{item.Prefix} {item.WardName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="relative-position mb-2">
                                <label className="form-label">Địa chỉ</label>
                                <input {...register('Address')} type="text" className="form-control" placeholder="Địa chỉ" />
                                {errors.Address && <div className="validation-message">{errors.Address.message}</div>}
                            </div>
                        </div>
                        <div className="col">
                            <div className="relative-position mb-2">
                                <label className="form-label">Trạng thái</label>
                                <select {...register('StatusId')} className="form-select">
                                    <option value="1">Hoạt động</option>
                                    <option value="2">Tạm khóa</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="relative-position mb-2">
                                <label className="form-label">GHTK token</label>
                                <input {...register('GHTKToken')} type="text" className="form-control" placeholder="GHTK token" />
                                {errors.GHTKToken && <div className="validation-message">{errors.GHTKToken.message}</div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="relative-position">
                                <label className="form-label">Ghi chú</label>
                                <textarea {...register('Note')} rows="2" className="form-control" placeholder="Ghi chú"></textarea>
                                {errors.Note && <div className="validation-message">{errors.Note.message}</div>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAdd.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    province: PropTypes.array,
    district: PropTypes.array,
    ward: PropTypes.array,
    partnerGroup: PropTypes.array,
    onProvinceChange: PropTypes.func,
    onDistrictChange: PropTypes.func
}

ModalAdd.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    province: [],
    district: [],
    ward: [],
    partnerGroup: [],
    onProvinceChange: () => { },
    onDistrictChange: () => { }
}

export default ModalAdd