import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoKeyOutline } from 'react-icons/io5'
import { ActionItem, ActionRow, EmptyRow, LoadingRow } from './../../../../../components'

function Table(props) {
    const renderTable = () => {
        let data = props.data
        if (data === null) return <LoadingRow colSpan={8} />
        else if (data.length === 0) return <EmptyRow colSpan={8} />
        else return data.map((item, index) => <tr key={item.UserId}>
            <td className="text-center">{index + 1}</td>
            <td>{item.FullName}</td>
            <td>{item.UserName}</td>
            <td>{item.Phone}</td>
            <td>{item.Email}</td>
            <td>{item.RoleName}</td>
            <td>
                {renderStatus(item.StatusId)}
            </td>
            <td>
                <ActionRow>
                    <ActionItem right="user_edit" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                    <ActionItem right="user_set_password" onClick={() => props.onSetPwd(item)} icon={<IoKeyOutline />} title='Đặt lại mật khẩu' />
                </ActionRow>
            </td>
        </tr>)
    }

    const renderStatus = statusId => {
        if (statusId === 1) return <span className="badge bg-success">Hoạt động</span>
        else return <span className="badge bg-warning">Tạm khóa</span>
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Họ tên</th>
                        <th>Tài khoản</th>
                        <th>Điện thoại</th>
                        <th>Email</th>
                        <th>Vai trò</th>
                        <th>Trạng thái</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    onEdit: PropTypes.func,
    onSetPwd: PropTypes.func
}

Table.defaultProps = {
    data: null,
    onEdit: () => { },
    onSetPwd: () => { }
}

export default Table