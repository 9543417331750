import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { SearchButton, Notification } from './../../../../../components'

const statusOptions = [
    { value: 'unpaid', label: 'Chờ xác nhận' },
    { value: 'toship', label: 'Chờ lấy hàng' },
    { value: 'shipping', label: 'Đang giao' },
    { value: 'completed', label: 'Đã giao' }
]

// sort_by
const sortOptions = [
    { value: 'confirmed_date_asc', label: 'Ngày xác nhận đặt đơn: Xa - Gần' },
    { value: 'confirmed_date_desc', label: 'Ngày xác nhận đặt đơn: Gần - Xa' },
    { value: 'ship_by_date_asc', label: 'Hạn giao hàng: Xa - Gần nhất' },
    { value: 'ship_by_date_desc', label: 'Hạn giao hàng: Gần - Xa nhất' },
    { value: 'create_date_asc', label: 'Ngày đặt: Xa - Gần nhất' },
    { value: 'create_date_desc', label: 'Ngày đặt: Gần - Xa nhất' }
]

const filters = {
    partnerShopId: 0,
    status: 'unpaid',
    sortBy: 'confirmed_date_desc'
}

function Filter(props) {
    const { onSearch, shopeeShops } = props

    const [shopeeShopOptions, setShopeeShopOptions] = useState([])

    const [shopeeShop, setShopeeShop] = useState(null)

    const [status, setStatus] = useState(statusOptions[0])

    const [sort, setSort] = useState(() => {
        return sortOptions[1]
    })

    const handleSearch = () => {
        if (shopeeShop === null) {
            Notification.warning('Vui lòng chọn shop trước khi tìm kiếm đơn hàng')
            return;
        }
        onSearch(filters)
    }

    useEffect(() => {
        const shopeeShopList = shopeeShops.map(item => {
            return {
                value: item.PartnerShopId,
                label: item.ShopName
            }
        })
        setShopeeShopOptions(shopeeShopList)
    }, [shopeeShops])

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-6 col-md-6 col-lg-7 col-xl-4 col-xxl-3">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={false}
                            options={shopeeShopOptions}
                            value={shopeeShop}
                            onChange={item => {
                                if (item !== null) {
                                    setShopeeShop(item)
                                    filters.partnerShopId = item.value
                                }
                                else {
                                    setShopeeShop(null)
                                    filters.partnerShopId = 0
                                }
                            }}
                            placeholder='Chọn shop'
                            noOptionsMessage={() => 'Chưa thêm tài khoản shop'}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-5 col-xl-3 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={false}
                            options={statusOptions}
                            value={status}
                            onChange={item => {
                                if (item !== null) {
                                    setStatus(item)
                                    filters.status = item.value
                                }
                                else {
                                    setStatus(item)
                                    filters.status = 'unpaid'
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-5 col-xxl-3">
                        <Select
                            isDisabled={status.value !== 'toship'}
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={false}
                            options={sortOptions}
                            value={sort}
                            onChange={item => {
                                if (item !== null) {
                                    setSort(item)
                                    filters.sortBy = item.value
                                }
                                else {
                                    setSort(item)
                                    filters.sortBy = 'confirmed_date_desc'
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-2 col-xxl-4">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func,
    shopeeShops: PropTypes.array,
}

Filter.defaultProps = {
    onSearch: () => { },
    shopeeShops: []
}

export default Filter