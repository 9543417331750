import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import { IoTrashOutline } from 'react-icons/io5'
import NumberFormat from 'react-number-format'
import Select from 'react-select'
import * as yup from "yup"
import {
    AvatarRow, CloseButton, EmptyRow, FormatNumber,
    InputSelect, LoadingRow, Notification, SaveButton, Config,
    CheckBox, UploadFileButton
} from './../../../../../components'

const schema = yup.object().shape({
    CustomerName: yup.string().max(100, 'Tên người nhận quá dài'),
    CustomerPhone: yup.string().max(20, 'Số điện thoại quá dài'),
    CustomerAddress: yup.string().max(200, 'Địa chỉ quá dài'),
    CustomerWardObj: yup.object().nullable(),
    CustomerWardId: yup.number().nullable(),
    WarehouseNote: yup.string().max(1_000, 'Ghi chú gửi kho quá dài'),
    ShippingUnitObj: yup.object().required('Vui lòng chọn đơn vị giao hàng'),
    ShippingUnitId: yup.number().nullable(),
    WaybillCode: yup.string().required('Vui lòng nhập mã đơn hàng').max(50, 'Mã đơn hàng quá dài'),
    ShippingWeight: yup.number().max(2_000_000_000, 'Trọng lượng quá lớn'),
    ShippingMethodObj: yup.object().required('Vui lòng chọn phương thức vận chuyển'),
    ShippingMethodId: yup.number().nullable(),
    ShippingFeeTypeId: yup.number().required('Vui lòng chọn phí vận chuyển'),
    ShippingFeeAmount: yup.number().max(2_000_000_000, 'Phí vận chuyển quá lớn'),
    ShippingNote: yup.string().max(1_000, 'Ghi chú vận chuyển quá dài'),
    OrderTypeId: yup.number().required('Vui lòng chọn loại đơn hàng'),
    Products: yup.array(),
    IsUseFreeShip: yup.bool()
})

const orderTypes = Config.orderTypes

const shippingTypes = Config.shippingTypes

const ModalOrder = (props) => {
    const { setValue, getValues, control, register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ShippingNote: Config.shippingNote,
            OrderTypeId: 1,
            ShippingFeeTypeId: 1,
            ShippingFeeAmount: 0,
            ShippingWeight: 0
        }
    })

    const [isBusy, setIsBusy] = useState(false)

    const [provinces, setProvinces] = useState([])

    const [province, setProvince] = useState()

    const [districts, setDistricts] = useState([])

    const [district, setDistrict] = useState()

    const [wards, setWards] = useState([])

    const [shippingUnits, setShippingUnits] = useState([])

    const [shippingMethods, setShippingMethods] = useState([])

    const [tienHang, setTienHang] = useState(0)

    const [fileName, setFileName] = useState('')

    useEffect(() => {
        const provinceArray = props.provinces.map(item => {
            return {
                value: item.ProvinceId,
                label: `${item.Prefix} ${item.ProvinceName}`
            }
        })
        setProvinces(provinceArray)
    }, [props.provinces])

    useEffect(() => {
        const districtArray = props.districts.map(item => {
            return {
                value: item.DistrictId,
                label: `${item.Prefix} ${item.DistrictName}`
            }
        })
        setDistricts(districtArray)
    }, [props.districts])

    useEffect(() => {
        const wardArray = props.wards.map(item => {
            return {
                value: item.WardId,
                label: `${item.Prefix} ${item.WardName}`
            }
        })
        setWards(wardArray)
    }, [props.wards])

    useEffect(() => {
        const shippingUnitArray = props.shippingUnits.map(item => {
            return {
                value: item.ShippingUnitId,
                label: item.ShippingUnitName
            }
        })
        setShippingUnits(shippingUnitArray)
    }, [props.shippingUnits])

    useEffect(() => {
        const shippingMethodArray = props.shippingMethods.map(item => {
            return {
                value: item.ShippingMethodId,
                label: item.ShippingMethodName
            }
        })
        setShippingMethods(shippingMethodArray)
    }, [props.shippingMethods])

    useEffect(() => {

        const tongTienHang = props.products.reduce((prev, current) => {
            return prev + current.TotalAmount
        }, 0)
        setTienHang(tongTienHang)

        const productArray = props.products.map(item => {
            return {
                ProductId: item.ProductId,
                Quantity: item.Quantity
            }
        })
        setValue('Products', productArray)
        // eslint-disable-next-line
    }, [props.products])

    const renderProduct = () => {
        const products = props.products
        if (products === null) return <LoadingRow colSpan={6} />
        else if (products.length === 0) return <EmptyRow colSpan={6} />
        else return products.map((item, index) => <tr key={item.CartId}>
            <td className="text-center">{index + 1}</td>
            <td>
                <AvatarRow
                    url={item.AvatarUrl}
                />
                {item.ProductName}
            </td>
            <td className="text-end text-primary">{item.Quantity}</td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {item.UnitPrice}
                </FormatNumber>
            </td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {item.TotalAmount}
                </FormatNumber>
            </td>
            <td className="text-end text-danger">
                <IoTrashOutline className="cursor-point" onClick={() => props.onRemoveCart(item.CartId)} />
            </td>
        </tr>)
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="xl"
            onExited={() => {
                reset()
                setFileName('')
            }}
            onShow={() => {

            }}>
            <Modal.Header closeButton>
                <Modal.Title>Đặt hàng</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {

                if (props.products.length === 0) {
                    Notification.warning('Vui lòng chọn sản phẩm trước khi đặt hàng')
                    return
                }

                const customerWardObj = getValues('CustomerWardObj')
                formData.CustomerWardId = (customerWardObj !== undefined ? customerWardObj.value : 0)
                delete formData['CustomerWardObj']

                const shippingUnitObj = getValues('ShippingUnitObj')
                formData.ShippingUnitId = (shippingUnitObj !== undefined ? shippingUnitObj.value : 0)
                delete formData['ShippingUnitObj']

                const shippingMethodObj = getValues('ShippingMethodObj')
                formData.ShippingMethodId = (shippingMethodObj !== undefined ? shippingMethodObj.value : 0)
                delete formData['ShippingMethodObj']

                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="card border-primary border">
                        <div className="card-body">
                            <h5 className="card-title text-primary">Thông tin khách hàng</h5>
                            <div className="row gy-2">
                                <div className="col-md-3">
                                    <div className="relative-position">
                                        <label className="form-label">Người nhận</label>
                                        <input {...register('CustomerName')} type="text" className="form-control" placeholder="Người nhận" />
                                        {errors.CustomerName && <div className="validation-message">{errors.CustomerName.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="relative-position">
                                        <label className="form-label">Điện thoại</label>
                                        <input {...register('CustomerPhone')} type="text" className="form-control" placeholder="Điện thoại" />
                                        {errors.CustomerPhone && <div className="validation-message">{errors.CustomerPhone.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="relative-position">
                                        <label className="form-label">Địa chỉ</label>
                                        <input {...register('CustomerAddress')} type="text" className="form-control" placeholder="Địa chỉ" />
                                        {errors.CustomerAddress && <div className="validation-message">{errors.CustomerAddress.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position">
                                        <label className="form-label">Tỉnh/Tp</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="custom-select"
                                            isSearchable={true}
                                            isClearable={true}
                                            options={provinces}
                                            value={province}
                                            onChange={item => {
                                                setProvince(item)
                                                setDistrict(null)
                                                setValue('CustomerWardId', null)
                                                setValue('CustomerWardObj', null)
                                                props.onProvinceChange(item !== null ? item.value : 0)
                                            }}
                                            placeholder='Tỉnh/Tp'
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position">
                                        <label className="form-label">Quận/Huyện</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="custom-select"
                                            isSearchable={true}
                                            isClearable={false}
                                            options={districts}
                                            value={district}
                                            onChange={item => {
                                                setDistrict(item)
                                                setValue('CustomerWardId', null)
                                                setValue('CustomerWardObj', null)
                                                props.onDistrictChange(item.value)
                                            }}
                                            placeholder='Quận/Huyện'
                                            noOptionsMessage={() => 'Quận/Huyện'}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position">
                                        <label className="form-label">Phường/Xã</label>
                                        <Controller
                                            control={control}
                                            name="CustomerWardObj"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="custom-select"
                                                    isSearchable={true}
                                                    isClearable={false}
                                                    options={wards}
                                                    value={value}
                                                    onChange={item => {
                                                        onChange(item)
                                                    }}
                                                    placeholder='Phường/Xã'
                                                    noOptionsMessage={() => 'Phường/Xã'}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border-primary border">
                        <div className="card-body">
                            <h5 className="card-title text-primary">Danh sách sản phẩm</h5>
                            <div className="relative-position">
                                <div className="table-responsive">
                                    <table className="table table-centered table-avatar mb-2">
                                        <thead>
                                            <tr>
                                                <th className="w-52-px">#</th>
                                                <th>Tên sản phẩm</th>
                                                <th>Số lượng</th>
                                                <th>Giá CTV</th>
                                                <th>Thành tiền</th>
                                                <th className="w-52-px"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderProduct()}
                                            <tr>
                                                <td colSpan={4} className="text-end fw-bold">Tổng thành tiền</td>
                                                <td className="text-end text-primary">
                                                    <FormatNumber>
                                                        {tienHang}
                                                    </FormatNumber>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="relative-position mb-2">
                                <label className="form-label">Cộng tác viên thanh toán với kho</label>
                                <div className="table-responsive">
                                    <table className="table table-centered">
                                        <tbody>
                                            <tr>
                                                <td className="text-end w-50-pc">Tiền hàng</td>
                                                <td className="text-primary">
                                                    <FormatNumber>
                                                        {tienHang}
                                                    </FormatNumber>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-end">Phí dropship</td>
                                                <td className="text-primary">
                                                    <FormatNumber>
                                                        {props.dropShipFee}
                                                    </FormatNumber>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-end fw-bold">Cộng tác viên thanh toán</td>
                                                <td className="text-primary">
                                                    <FormatNumber>
                                                        {props.dropShipFee + tienHang}
                                                    </FormatNumber>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='row gy-2'>
                                <div className='col-md-4'>
                                    <div className="relative-position">
                                        <label className="form-label">Miễn phí đóng gói (đang có {props.freeShipQty} lần)</label>
                                        <Controller
                                            control={control}
                                            name="IsUseFreeShip"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <CheckBox
                                                    className=''
                                                    title='Sử dụng'
                                                    onChange={isCheck => {
                                                        onChange(isCheck)
                                                        props.onFreeShipChange(isCheck)
                                                    }}
                                                    checked={value}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className="relative-position">
                                        <label className="form-label">Ghi chú gửi kho</label>
                                        {/* <textarea {...register('WarehouseNote')} rows="2" className="form-control" placeholder="Ghi chú gửi kho"></textarea> */}
                                        <input {...register('WarehouseNote')} className="form-control" placeholder="Ghi chú gửi kho" type='text' />
                                        {errors.WarehouseNote && <div className="validation-message">{errors.WarehouseNote.message}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card border-primary border mb-0">
                        <div className="card-body">
                            <h5 className="card-title text-primary mb-2">Vận chuyển</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Đơn vị giao hàng <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="ShippingUnitObj"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="custom-select"
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    options={shippingUnits}
                                                    value={value}
                                                    onChange={item => {
                                                        onChange(item)
                                                    }}
                                                    placeholder='Đơn vị giao hàng'
                                                    noOptionsMessage={() => 'Đơn vị giao hàng'}
                                                />
                                            )}
                                        />
                                        {errors.ShippingUnitObj && <div className="validation-message">{errors.ShippingUnitObj.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Mã vận đơn <span className="text-danger">*</span></label>
                                        <input {...register('WaybillCode')} type="text" className="form-control" placeholder="Mã vận đơn" />
                                        {errors.WaybillCode && <div className="validation-message">{errors.WaybillCode.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Trọng lượng</label>
                                        <div className="input-group">
                                            <Controller
                                                control={control}
                                                name="ShippingWeight"
                                                render={({
                                                    field: { onChange, value }
                                                }) => (
                                                    <NumberFormat
                                                        placeholder='Trọng lượng'
                                                        className="form-control"
                                                        // thousandSeparator={"."}
                                                        // decimalSeparator={","}
                                                        allowNegative={false}
                                                        defaultValue={0}
                                                        decimalScale={0}
                                                        value={value}
                                                        onValueChange={val => onChange(val.value)}
                                                    />
                                                )}
                                            />
                                            <span className="input-group-text">Gram</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Loại đơn <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="OrderTypeId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <InputSelect
                                                    options={orderTypes}
                                                    value={value}
                                                    defaultValue={1}
                                                    name='order-type'
                                                    variant='success'
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                        {errors.OrderTypeId && <div className="validation-message">{errors.OrderTypeId.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Phương thức vận chuyển <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="ShippingMethodObj"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <Select
                                                    className="react-select-container"
                                                    classNamePrefix="custom-select"
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    options={shippingMethods}
                                                    value={value}
                                                    onChange={item => {
                                                        onChange(item)
                                                    }}
                                                    placeholder='Phương thức vận chuyển'
                                                    noOptionsMessage={() => 'Phương thức vận chuyển'}
                                                />
                                            )}
                                        />
                                        {errors.ShippingMethodObj && <div className="validation-message">{errors.ShippingMethodObj.message}</div>}
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Loại phí vận chuyển <span className="text-danger">*</span></label>
                                        <Controller
                                            control={control}
                                            name="ShippingFeeTypeId"
                                            render={({
                                                field: { onChange, value }
                                            }) => (
                                                <InputSelect
                                                    options={shippingTypes}
                                                    value={value}
                                                    defaultValue={1}
                                                    name='shipping-fee-type'
                                                    variant='success'
                                                    onChange={onChange}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="relative-position mb-2">
                                        <label className="form-label">Phí vận chuyển thỏa thuận</label>
                                        <div className="input-group">
                                            <Controller
                                                control={control}
                                                name="ShippingFeeAmount"
                                                render={({
                                                    field: { onChange, value }
                                                }) => (
                                                    <NumberFormat
                                                        placeholder='Phí vận chuyển thỏa thuận'
                                                        className="form-control"
                                                        // thousandSeparator={"."}
                                                        // decimalSeparator={","}
                                                        allowNegative={false}
                                                        defaultValue={0}
                                                        decimalScale={0}
                                                        value={value}
                                                        onValueChange={val => onChange(val.value)}
                                                    />
                                                )}
                                            />
                                            <span className="input-group-text">VNĐ</span>
                                        </div>
                                        {errors.ShippingFeeAmount && <div className="validation-message">{errors.ShippingFeeAmount.message}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="relative-position">
                                <label className="form-label">Ghi chú vận chuyển</label>
                                <textarea {...register('ShippingNote')} rows="2" className="form-control" placeholder="Ghi chú vận chuyển"></textarea>
                                {errors.ShippingNote && <div className="validation-message">{errors.ShippingNote.message}</div>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span>{fileName}</span>
                    <UploadFileButton onCompleted={data => {
                        if (data.IsSuccess) {
                            setValue('FileUrl', data.Result.FileUrl)
                            setFileName(data.Result.FileName)
                        }
                    }} />
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalOrder.propTypes = {
    isShow: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    provinces: PropTypes.array,
    onProvinceChange: PropTypes.func,
    districts: PropTypes.array,
    onDistrictChange: PropTypes.func,
    wards: PropTypes.array,
    products: PropTypes.array,
    shippingUnits: PropTypes.array,
    shippingMethods: PropTypes.array,
    onRemoveCart: PropTypes.func,
    dropShipFee: PropTypes.number,
    freeShipQty: PropTypes.number,
    onFreeShipChange: PropTypes.func
}

ModalOrder.defaultProps = {
    isShow: false,
    onSave: () => { },
    onClose: () => { },
    provinces: [],
    onProvinceChange: () => { },
    districts: [],
    onDistrictChange: () => { },
    wards: [],
    products: [],
    shippingUnits: [],
    shippingMethods: [],
    onRemoveCart: () => { },
    dropShipFee: 0,
    freeShipQty: 0,
    onFreeShipChange: () => { }
}

export default ModalOrder