import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'
import Modal from 'react-bootstrap/Modal'

const schema = yup.object().shape({
    AttributeName: yup.string().required('Vui lòng nhập tên thuộc tính').max(50, 'Tên thuộc tính quá dài')
})

const ModalEditAttr = props => {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                let data = props.data
                setValue("AttributeId", data.AttributeId)
                setValue("AttributeName", data.AttributeName)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa thuộc tính</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => props.onSave(formData, setIsBusy))}>
                <Modal.Body>
                    <div className="relative-position">
                        <label className="form-label">Tên thuộc tính <span className="text-danger">*</span></label>
                        <input {...register('AttributeName')} type="text" className="form-control" placeholder="Tên thuộc tính" />
                        {errors.AttributeName && <div className="validation-message">{errors.AttributeName.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEditAttr.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    data: PropTypes.object
}

ModalEditAttr.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    data: {}
}

export default ModalEditAttr