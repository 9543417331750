import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, InputSelect, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    orderId: yup.number(),
    statusId: yup.number()
})

const ModalUpdateStatus = (props) => {
    const { setValue, control, handleSubmit, reset } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const [orderStatuses, setOrderStatuses] = useState([])

    useEffect(() => {
        const statusList = props.statuses.map(item => {
            return {
                value: item.OrderStatusId,
                label: item.OrderStatusName
            }
        })
        setOrderStatuses(statusList)
    }, [props.statuses])

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                setValue('orderId', props.orderId)
                setValue('statusId', props.statusId)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Sửa trạng thái đơn hàng</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <Controller
                        name="statusId"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <InputSelect
                                    isVertical={true}
                                    options={orderStatuses}
                                    value={value}
                                    defaultValue={value}
                                    name='order-status'
                                    variant='success'
                                    onChange={onChange}
                                />
                            )
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalUpdateStatus.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    statuses: PropTypes.array,
    statusId: PropTypes.number,
    orderId: PropTypes.number,
    isShow: PropTypes.bool
}

ModalUpdateStatus.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    statuses: [],
    statusId: 0,
    orderId: 0,
    isShow: false
}

export default ModalUpdateStatus