import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { IoAddCircleOutline, IoPeopleOutline, IoPersonAddOutline, IoPersonRemoveOutline, IoWalletOutline } from 'react-icons/io5'
import { IconButton, Notification, Pagination, AuthAdminView } from '../../../components'
import {
    Filter, InfoBlock, ModalAdd,
    ModalEdit, ModalRecharge, ModalSetPwd, Table,
    FreeShipTable, ModalChildren
} from './components'

const freeShipPageItem = 5

const PartnerPage = () => {
    // State lưu danh sách CTV
    const [partner, setPartner] = useState(null);

    // State lưu danh sách Tỉnh/Tp
    const [province, setProvince] = useState([]);

    // State lưu danh sách Quận/Huyện
    const [district, setDistrict] = useState([]);

    // State lưu danh sách Phường/Xã
    const [ward, setWard] = useState([]);

    // State lưu danh sách nhóm CTV
    const [partnerGroup, setPartnerGroup] = useState([]);

    // State mở, đóng modal thêm CTV
    const [isShowModalPartnerAdd, setIsShowModalPartnerAdd] = useState(false);

    // State mở, đóng modal thêm CTV
    const [isShowModalPartnerEdit, setIsShowModalPartnerEdit] = useState(false);

    // State lưu thông tin cập nhật CTV
    const [partnerEdit, setPartnerEdit] = useState({});

    // State đóng mở modal và PartnerId set lại mật khẩu
    const [partnerSetPwd, setPartnerSetPwd] = useState({
        isShow: false,
        partnerId: 0
    })

    // State đóng mở modal và ParnerId nạp tiền
    const [partnerRecharge, setPartnerRecharge] = useState({
        isShow: false,
        partnerId: 0
    })

    const [info, setInfo] = useState({
        allPartner: 0,
        activePartner: 0,
        inActivePartner: 0,
        balance: 0
    })

    const [isShowModalChildren, setIsShowModalChildren] = useState(false)

    const [childrenPartners, setChildrenPartners] = useState([])

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Cộng tác viên'

        loadInfo()

        loadProvince()
        loadPartnerGroup()
    }, [])

    const [filters, setFilters] = useState({
        keyword: '',
        groupId: 0,
        statusId: 0,
        pageItem: 10,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    const [partnerOrderPagination, setPartnerOrderPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    const [balanceHistoryPatination, setBalanceHistoryPatination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    const [freeShipPatination, setFreeShipPatination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1,
        partnerId: 0
    })

    useEffect(() => {
        let url = `/admin/partner/filter?keyword=${filters.keyword}&groupId=${filters.groupId}&statusId=${filters.statusId}&pageItem=10&pageIndex=${filters.pageIndex}`;
        axios.get(url).then(res => {
            let data = res.data;
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setPartner(data.Result.Data);
            }
        })
    }, [filters])

    const [partnerOrders, setPartnerOrders] = useState([])

    const [balanceHistories, setBalanceHistories] = useState([])

    const [partnerDetail, setPartnerDetail] = useState({})

    const [shopeeShops, setShopeeShops] = useState([])

    const [freeShips, setFreeShips] = useState([])

    // Load danh sách nhóm CTV
    const loadPartnerGroup = () => {
        const url = '/admin/partner-group/get-all';
        axios.get(url).then(res => {
            let data = res.data;
            if (data.IsSuccess) {
                setPartnerGroup(data.Result);
            }
        })
    }

    // Load danh sách Tỉnh/Tp
    const loadProvince = () => {
        const url = '/admin/province/get-all';
        axios.get(url).then(res => {
            let data = res.data;
            if (data.IsSuccess) {
                setProvince(data.Result);
            }
        });
    }

    // Load danh sách Quận/Huyện theo Tỉnh/Tp
    const loadDistrict = (provinceId) => {
        const url = `/admin/district/get-by-province?id=${provinceId}`;
        axios.get(url).then(res => {
            let data = res.data;
            if (data.IsSuccess) {
                setDistrict(data.Result);
                setWard([]);
            }
        })
    }

    //Load danh sách Phường/Xã theo Quận/Huyện
    const loadWard = (districtId) => {
        const url = `/admin/ward/get-by-district?id=${districtId}`;
        axios.get(url).then(res => {
            let data = res.data;
            if (data.IsSuccess) {
                setWard(data.Result);
            }
        })
    }

    //Thực hiện mở hoặc đóng detail table
    const handleRowClick = item => {
        const partnerId = item.PartnerId
        let lst = [...partner];
        let newindex = lst.findIndex(n => n.PartnerId === item.PartnerId);
        let newItem = lst[newindex];
        let prevIndex = lst.findIndex(n => n.isSelected);
        if (prevIndex > -1) {
            let prev = lst[prevIndex];
            if (prev.PartnerId === newItem.PartnerId) {
                clearDetail()
                newItem.isSelected = false;
            }
            else {
                loadDetail(partnerId)
                prev.isSelected = false;
                newItem.isSelected = true;
            }
        }
        else {
            loadDetail(partnerId)
            newItem.isSelected = true;
        }
        setPartner(lst);
    }

    const loadDetail = (partnerId) => {
        loadPartnerDetail(partnerId)
        loadPartnerOrders(partnerId, 1)
        loadBalanceHistory(partnerId, 1)
        loadShopeeShop(partnerId)
        loadFreeShip(partnerId, 1)
    }

    const clearDetail = () => {
        setPartnerDetail({})
        setPartnerOrders([])
        setBalanceHistories([])
        setShopeeShops([])
        setFreeShips([])
    }

    // Sự kiện đóng hoặc mở chi tiết table
    function onOpenDetail(item) {
        handleRowClick(item);
    }

    const loadPartnerOrders = (partnerId, pageIndex) => {
        const url = `/admin/partner/order-filter?partnerId=${partnerId}&pageItem=5&pageIndex=${pageIndex}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPartnerOrderPagination(newPagination)
                setPartnerOrders(data.Result.Data)
            }
        })
    }

    const loadBalanceHistory = (partnerId, pageIndex) => {
        const url = `/admin/partner/balance-history?partnerId=${partnerId}&pageItem=5&pageIndex=${pageIndex}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setBalanceHistoryPatination(newPagination)
                setBalanceHistories(data.Result.Data)
            }
        })
    }

    const loadPartnerDetail = (partnerId) => {
        const url = `/admin/partner/detail?id=${partnerId}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setPartnerDetail(data.Result)
            }
        })
    }

    const loadShopeeShop = (partnerId) => {
        const url = `/admin/partner/shopee-shops?partnerId=${partnerId}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setShopeeShops(data.Result)
            }
        })
    }

    const loadFreeShip = (partnerId, pageIndex) => {
        const url = `/admin/partner/freeship-histories?partnerId=${partnerId}&pageItem=${freeShipPageItem}&pageIndex=${pageIndex}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex,
                    partnerId: partnerId
                }
                setFreeShipPatination(newPagination)
                setFreeShips(data.Result.Data)
            }
        })
    }

    const onEdit = item => {
        const url = `/admin/partner/get-by-id?id=${item.PartnerId}`
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                setProvince(data.Result.Provinces)
                setDistrict(data.Result.Districts)
                setWard(data.Result.Wards)
                setPartnerEdit(data.Result)
                setIsShowModalPartnerEdit(true)
            }
        })
    }

    const onRecharge = item => {
        setPartnerRecharge({
            isShow: true,
            partnerId: item.PartnerId
        })
    }

    const onSetPassword = item => {
        setPartnerSetPwd({
            isShow: true,
            partnerId: item.PartnerId
        })
    }

    const onLogOut = item => {
        Notification.confirmHtml('Bạn có chắc muốn đăng xuất cộng tác viên', item.FullName, () => {
            const url = `/admin/partner/logout?id=${item.PartnerId}`
            axios.post(url).then(res => {
                let data = res.data
                if (data.IsSuccess) {
                    Notification.success('Đăng xuất cộng tác viên thành công')
                }
            })
        })
    }

    const onDelete = item => {
        Notification.confirmHtml('Bạn có chắc muốn xóa cộng tác viên', item.FullName, () => {
            const url = `/admin/partner/delete?id=${item.PartnerId}`
            axios.post(url).then(res => {
                let data = res.data
                if (data.IsSuccess) {
                    reloadPartner()
                    loadInfo()
                    Notification.success('Xóa cộng tác viên thành công')
                }
            })
        })
    }

    // Sự kiện đóng chi tiết table
    const onCloseDetail = (item) => {
        handleRowClick(item);
    }

    const reloadPartner = () => {
        setFilters({
            ...filters,
            pageIndex: 1
        })
    }

    const loadInfo = () => {
        const url = '/admin/partner/info'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setInfo({
                    allPartner: data.Result.AllPartner,
                    activePartner: data.Result.ActivePartner,
                    inActivePartner: data.Result.InActivePartner,
                    balance: data.Result.Balance
                })
            }
        })
    }

    return (
        <Fragment>
            {/* Bộ lọc CTV */}
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
                partnerGroup={partnerGroup}
            />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="partner_add">
                                <IconButton
                                    className="mb-2"
                                    text="Thêm mới"
                                    icon={<IoAddCircleOutline />}
                                    onClick={() => {
                                        setIsShowModalPartnerAdd(true)
                                    }} />
                            </AuthAdminView>
                        </div>
                    </div>

                    <Table
                        data={partner}
                        onOpenDetail={onOpenDetail}
                        onEdit={onEdit}
                        onRecharge={onRecharge}
                        onSetPassword={onSetPassword}
                        onLogOut={onLogOut}
                        onDelete={onDelete}
                        onCloseDetail={onCloseDetail}
                        onShowChildren={(partner) => {
                            const url = `/admin/partner/get-children?partnerId=${partner.PartnerId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setChildrenPartners(data.Result)
                                    setIsShowModalChildren(true)
                                }
                            })
                        }}

                        detail={partnerDetail}

                        partnerOrders={partnerOrders}
                        partnerOrderPagination={partnerOrderPagination}
                        onPartnerOrderPageChange={(partnerId, pIndex) => {
                            const newPagination = { ...partnerOrderPagination, pageIndex: pIndex }
                            setPartnerOrderPagination(newPagination)
                            loadPartnerOrders(partnerId, pIndex)
                        }}

                        balanceHistories={balanceHistories}
                        balanceHistoryPagination={balanceHistoryPatination}
                        onBalanceHistoryPageChange={(partnerId, pIndex) => {
                            const newPagination = { ...balanceHistoryPatination, pageIndex: pIndex }
                            setBalanceHistoryPatination(newPagination)
                            loadBalanceHistory(partnerId, pIndex)
                        }}
                        balancePageItem={5}
                        balancePageIndex={balanceHistoryPatination.pageIndex}

                        shopeeShops={shopeeShops}
                    >
                        <FreeShipTable
                            data={freeShips}
                            pagination={freeShipPatination}
                            onPageChange={(partnerId, pIndex) => {
                                const newPagination = { ...freeShipPatination, pageIndex: pIndex }
                                setFreeShipPatination(newPagination)
                                loadFreeShip(partnerId, pIndex)
                            }}
                            pageItem={freeShipPageItem}
                        />
                    </Table>

                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>

            <div className="mb-3">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 gy-2">
                    <div className="col">
                        <InfoBlock icon={<IoPeopleOutline />} value={info.allPartner} title="Số cộng tác viên" />
                    </div>
                    <div className="col">
                        <InfoBlock icon={<IoPersonAddOutline />} value={info.activePartner} title="Cộng tác viên hoạt động" />
                    </div>
                    <div className="col">
                        <InfoBlock icon={<IoPersonRemoveOutline />} value={info.inActivePartner} title="Cộng tác viên tạm khóa" />
                    </div>
                    <div className="col">
                        <InfoBlock icon={<IoWalletOutline />} value={info.balance} title="Tổng số dư" />
                    </div>
                </div>
            </div>

            {/* Modal thêm mới CTV */}
            <ModalAdd
                onClose={() => {
                    setIsShowModalPartnerAdd(false);
                }}
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = 'admin/partner/create';
                    let reqInfo = formData;
                    reqInfo.ShipFeeId = 1;
                    axios.post(url, reqInfo).then(res => {
                        let data = res.data;
                        if (data.IsSuccess) {
                            reloadPartner()
                            loadInfo()
                            setIsShowModalPartnerAdd(false);
                            Notification.success('Thêm mới cộng tác viên thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                isShow={isShowModalPartnerAdd}
                province={province}
                district={district}
                ward={ward}
                partnerGroup={partnerGroup}
                onProvinceChange={loadDistrict}
                onDistrictChange={loadWard} />

            {/* Modal chỉnh sửa CTV */}
            <ModalEdit
                data={partnerEdit}
                onClose={() => {
                    setIsShowModalPartnerEdit(false);
                }}
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = 'admin/partner/update';
                    let reqInfo = formData;
                    reqInfo.ShipFeeId = 1;
                    axios.post(url, reqInfo).then(res => {
                        let data = res.data;
                        if (data.IsSuccess) {
                            reloadPartner()
                            loadInfo()
                            setIsShowModalPartnerEdit(false)
                            setPartnerEdit({})
                            Notification.success('Chỉnh sửa cộng tác viên thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                isShow={isShowModalPartnerEdit}
                province={province}
                district={district}
                ward={ward}
                partnerGroup={partnerGroup}
                onProvinceChange={loadDistrict}
                onDistrictChange={loadWard} />

            {/* Modal set lại mật khẩu */}
            <ModalSetPwd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/partner/set-password'
                    axios.post(url, formData).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            setPartnerSetPwd({
                                isShow: false,
                                partnerId: 0
                            })
                            Notification.success('Đặt lại mật khẩu thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setPartnerSetPwd({
                        isShow: false,
                        partnerId: 0
                    })
                }}
                isShow={partnerSetPwd.isShow}
                partnerId={partnerSetPwd.partnerId}
            />

            {/* Modal nạp tiền */}
            <ModalRecharge
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/partner/recharge'
                    axios.post(url, formData).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            const newPartnerDetail = { ...partnerDetail }
                            if (formData.RechargeTypeId === 2) {
                                newPartnerDetail.Balance += formData.Amount
                            }
                            else {
                                newPartnerDetail.Balance -= formData.Amount
                            }
                            setPartnerDetail(newPartnerDetail)

                            loadInfo()
                            loadBalanceHistory(formData.PartnerId, 1)
                            setPartnerRecharge({
                                isShow: false,
                                partnerId: 0
                            })
                            Notification.success('Nạp tiền thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setPartnerRecharge({
                        isShow: false,
                        partnerId: 0
                    })
                }}
                isShow={partnerRecharge.isShow}
                partnerId={partnerRecharge.partnerId}
            />

            <ModalChildren
                isShow={isShowModalChildren}
                onClose={() => {
                    setIsShowModalChildren(false)
                    setChildrenPartners([])
                }}
                partners={childrenPartners}
            />
        </Fragment>

    );
};

export default PartnerPage;