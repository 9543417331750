import "animate.css"
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { MdMenu } from 'react-icons/md'
import 'react-notifications-component/dist/theme.css'
import {
    BrowserRouter as Router, Redirect, Route, Switch, useHistory
} from "react-router-dom"
import SimpleBar from 'simplebar-react'
import './App.css'
import { AppContext, Config, ModalPopup, Notification, PartnerLeftMenu } from './components'
import {
    BalanceHistoryPage, CustomerPage, DashboardPage, OrderPage,
    ProductCheckingPage, ProductPage, ProfilePage,
    ChildrenPage,
    // OrderFromShopee,
    // PartnerShopPage,
} from './pages/partner'

var collapsed = false

// Phóng to, thu nhỏ left menu
const handleCollapse = () => {
    if (!collapsed) {
        document.body.classList.add('sidebar-enable')
        document.body.setAttribute('data-leftbar-compact-mode', 'condensed')
    }
    else {
        document.body.classList.remove('sidebar-enable')
        document.body.setAttribute('data-leftbar-compact-mode', '')
    }
    collapsed = !collapsed
}

var globalPopups = []

const noImgUrl = '/assets/images/no-img.jpg'

const apiUrl = window.apiUrl

const renderAvatar = (avatarUrl) => {
    let url = ''
    if (avatarUrl === null || avatarUrl === '') url = `url(${noImgUrl})`
    else url = `url(${apiUrl}/admin/storage/thumbnail?url=${avatarUrl})`
    return <span
        className="bg-avatar me-0 rounded-circle"
        style={{ backgroundImage: url, width: '32px', height: '32px' }}
    />
}

function PartnerLayout() {
    const appSettings = useContext(AppContext)

    const history = useHistory()

    const [popupInfo, setPopupInfo] = useState({
        isShow: false,
        partnerPopupId: 0,
        title: '',
        content: ''
    })

    useEffect(() => {
        loadPopup()
    }, [])

    function loadPopup() {
        const url = '/partner/popup/get-partner-popup'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const popups = data.Result
                globalPopups = popups
                if (popups.length !== 0) {
                    const popup = popups[0]
                    setPopupInfo({
                        isShow: true,
                        partnerPopupId: popup.PartnerPopupId,
                        title: popup.Title,
                        content: popup.Content
                    })
                }
            }
        })
    }

    const token = localStorage.getItem('p-token')

    if (token === null || token === '') return <Redirect to='/' />
    else <Redirect to='/partner/dashboard' />

    return (
        <div className="wrapper">

            <Router>

                <div className="leftside-menu">

                    <span className="logo text-center logo-dark">
                        <span className="logo-lg">
                            <img src="/assets/images/logo-dark.png" alt="logo" height={Config.logoHeight} />
                        </span>
                        <span className="logo-sm">
                            <img src="/assets/images/logo_sm_dark.png" alt="logo" height={Config.logoHeight} />
                        </span>
                    </span>

                    <SimpleBar className="h-100" id="leftside-menu-container">
                        <PartnerLeftMenu
                            onLogout={() => {
                                Notification.confirm('Bạn có chắc chắn muốn đăng xuất không?', () => {
                                    const url = '/partner/partner/logout'
                                    axios.post(url).then(({ data }) => {
                                        localStorage.removeItem('p-email')
                                        localStorage.removeItem('p-fullName')
                                        localStorage.removeItem('p-token')
                                        localStorage.removeItem('p-avatarUrl')
                                        axios.defaults.headers.common['P-Token'] = ''
                                        history.replace('/')
                                    })
                                })
                            }}
                        />

                        <div className="clearfix"></div>
                    </SimpleBar>

                </div>

                <div className="content-page">
                    <div className="content">

                        <div className="navbar-custom">
                            <ul className="list-unstyled topbar-menu float-end mb-0">
                                <li className="dropdown notification-list d-lg-none">
                                    <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
                                        <i className="dripicons-search noti-icon"></i>
                                    </a>
                                </li>
                                <li className="dropdown notification-list">
                                    <span className="nav-link dropdown-toggle nav-user arrow-none me-0">
                                        <span className="account-user-avatar">
                                            {renderAvatar(appSettings.partnerAvatarUrl)}
                                        </span>
                                        <span>
                                            <span className="account-user-name">{appSettings.partnerFullName}</span>
                                            <span className="account-position">Cộng tác viên</span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <button className="button-menu-mobile open-left" onClick={handleCollapse}>
                                <MdMenu style={{ marginTop: '-10px' }} />
                            </button>
                            <span className="page-title" id="page-header"></span>
                        </div>

                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12" style={{ paddingTop: '24px' }}>
                                    <Switch>
                                        <Route path="/partner/dashboard">
                                            <DashboardPage />
                                        </Route>
                                        <Route path="/partner/product">
                                            <ProductPage />
                                        </Route>
                                        <Route path="/partner/product-checking">
                                            <ProductCheckingPage />
                                        </Route>
                                        <Route path="/partner/customer">
                                            <CustomerPage />
                                        </Route>
                                        <Route path="/partner/order">
                                            <OrderPage />
                                        </Route>
                                        {/* <Route path="/partner/shop">
                                            <PartnerShopPage />
                                        </Route>
                                        <Route path="/partner/order-from-shopee">
                                            <OrderFromShopee />
                                        </Route> */}
                                        <Route path="/partner/children">
                                            <ChildrenPage />
                                        </Route>
                                        <Route path="/partner/profile">
                                            <ProfilePage />
                                        </Route>
                                        <Route path="/partner/balance-history">
                                            <BalanceHistoryPage />
                                        </Route>
                                    </Switch>
                                </div>
                            </div>

                        </div>

                    </div>

                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    Copyright &copy; {new Date().getFullYear()} Drop Viet
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end footer-links d-none d-md-block">
                                        <span>Version: {Config.version}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>

                </div>

            </Router>

            <ModalPopup
                onSave={(isChecked, partnerPopupId) => {
                    if (isChecked) {
                        const url = `/partner/popup/never-show-again?id=${partnerPopupId}`
                        axios.post(url).then(({ data }) => {
                            if (data.IsSuccess) {

                            }
                        })
                    }
                }}
                onClose={(partnerPopupId) => {
                    setPopupInfo({
                        isShow: false,
                        partnerPopupId: 0,
                        title: '',
                        content: ''
                    })
                    setTimeout(() => {
                        const nextPopups = globalPopups.filter(n => n.PartnerPopupId > partnerPopupId)
                        if (nextPopups.length !== 0) {
                            const nextPopup = nextPopups[0]
                            setPopupInfo({
                                isShow: true,
                                partnerPopupId: nextPopup.PopupId,
                                title: nextPopup.Title,
                                content: nextPopup.Content
                            })
                        }
                    }, 500)
                }}
                isShow={popupInfo.isShow}
                partnerPopupId={popupInfo.partnerPopupId}
                title={popupInfo.title}
                content={popupInfo.content}
            />

        </div>
    )
}

export default PartnerLayout