import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { IoCartOutline } from 'react-icons/io5'
import { EmptyRow, FormatNumber, IconButton, LoadingRow, ShopeeProductAvatar, Notification } from '../../../../../components'

function Table(props) {
    const { orders, onOrder, status, partnerShopId } = props

    function handleOrder(order, setIsBusy) {
        const productNoSku = order.Order_Items.filter(n => n.Item_Model.Sku === '')
        if (productNoSku.length !== 0) {
            Notification.warning('Không thể đặt hàng sản phẩm không có mã Sku')
            return
        }
        onOrder(order, setIsBusy, partnerShopId)
    }

    function renderTable() {
        if (orders === null) return <LoadingRow colSpan={6} />
        else if (orders.length === 0) return <EmptyRow colSpan={6} />
        else return orders.map((item, index) => {
            const totalProductPrice = item.Order_Items.reduce((prev, current) => {
                return prev + (current.Product.Price * current.Amount)
            }, 0)
            return (
                <Fragment key={item.Order_Id}>
                    <tr>
                        <td className="text-center">{index + 1}</td>
                        <td>{item.Order_Sn}</td>
                        <td>{item.Buyer_Address_Name}</td>
                        <td>{item.Buyer_Address_Phone}</td>
                        <td className="text-end text-primary">
                            <FormatNumber>
                                {totalProductPrice}
                            </FormatNumber>
                        </td>
                        <td className="text-end text-primary">
                            <FormatNumber>
                                {item.Shipping_Fee}
                            </FormatNumber>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className="p-0">
                            <div className="card mb-0">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-centered table-avatar mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="w-52-px">#</th>
                                                    <th>Sản phẩm</th>
                                                    <th>Sku</th>
                                                    <th>Đơn giá</th>
                                                    <th>Số lượng</th>
                                                    <th>Thành tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderProduct(item.Order_Items)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {renderFooter(item)}
                            </div>
                        </td>
                    </tr>
                </Fragment>
            )
        })
    }

    function renderFooter(item) {
        if (status === 'toship') return <div className="card-footer">
            <IconButton
                icon={<IoCartOutline />}
                variant="success"
                text="Đặt hàng"
                onClick={(setIsBusy) => {
                    handleOrder(item, setIsBusy)
                }}
            />
        </div>
        else return ''
    }

    function renderProduct(orderItems) {
        return orderItems.map((item, index) => {
            let imgUrl = ''
            const imgs = item.Product.Images
            if (imgs.length !== 0) {
                imgUrl = imgs[0]
            }
            return (
                <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>
                        <ShopeeProductAvatar
                            url={imgUrl}
                        />
                        {item.Product.Name}
                    </td>
                    <td>{item.Item_Model.Sku}</td>
                    <td className="text-end text-primary">
                        <FormatNumber>
                            {item.Product.Price}
                        </FormatNumber>
                    </td>
                    <td className="text-end text-primary">
                        <FormatNumber>
                            {item.Amount}
                        </FormatNumber>
                    </td>
                    <td className="text-end text-primary">
                        <FormatNumber>
                            {item.Product.Price * item.Amount}
                        </FormatNumber>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Mã đơn hàng</th>
                        <th>Khách hàng</th>
                        <th>Điện thoại</th>
                        <th>Tiền hàng</th>
                        <th>Phí ship</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    orders: PropTypes.array,
    onOrder: PropTypes.func,
    status: PropTypes.string,
    partnerShopId: PropTypes.number,
}

Table.defaultProps = {
    orders: [],
    onOrder: () => { },
    status: '',
    partnerShopId: 0
}

export default Table