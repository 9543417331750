import PropTypes from 'prop-types'
import React from 'react'
import { IoSaveOutline } from 'react-icons/io5'

const SaveButton = props => {
    const handleOnClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    const renderIcon = () => {
        if (props.isBusy) {
            return <div className="spinner-border spinner-border-sm me-1" role="status"></div>;
        }
        else {
            return props.icon
        }
    }

    return (
        <button type={props.type} className={props.className + ' btn btn-primary'} onClick={handleOnClick} disabled={props.isBusy}>
            {renderIcon()}
            {props.title}
        </button>
    )
}

SaveButton.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    isBusy: PropTypes.bool,
    className: PropTypes.string,
}

SaveButton.defaultProps = {
    type: 'submit',
    title: 'Lưu',
    onClick: () => { },
    icon: <IoSaveOutline className="mt--3-px me-1 font-17" />,
    isBusy: false,
    className: ''
}

export default SaveButton