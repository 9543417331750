import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const invalidValue = 'Giá trị không hợp lệ'

const schema = yup.object().shape({
    RechargeTypeId: yup.number().min(1, invalidValue).max(2, invalidValue),
    Amount: yup.number().min(1, invalidValue).max(2_000_000_000, invalidValue),
    Note: yup.string().max(100, 'Ghi chú quá dài'),
})

function ModalRecharge(props) {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [val, setVal] = useState(0)

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
                setVal(0)
            }}
            onShow={() => {
                setValue("PartnerId", props.partnerId)
                setValue("Amount", 0)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Nạp tiền</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => props.onSave(formData, setIsBusy))}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Loại</label>
                        <select {...register('RechargeTypeId')} className="form-select">
                            <option value={2}>Cộng tiền</option>
                            <option value={1}>Trừ tiền</option>
                        </select>
                        {errors.RechargeTypeId && <div className="validation-message">{errors.RechargeTypeId.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Số tiền <span className="text-danger">*</span></label>
                        <div className="input-group">
                            <NumberFormat
                                className="form-control"
                                // thousandSeparator={"."}
                                // decimalSeparator=","
                                allowNegative={false}
                                defaultValue={0}
                                onValueChange={(c) => {
                                    setValue('Amount', c.value)
                                    setVal(c.value)
                                }}
                                value={val}
                                decimalScale={0}
                            />
                            <span className="input-group-text">VNĐ</span>
                        </div>
                        {errors.Amount && <div className="validation-message">{errors.Amount.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Ghi chú</label>
                        <textarea {...register('Note')} rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                        {errors.Note && <div className="validation-message">{errors.Note.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalRecharge.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    partnerId: PropTypes.number
}

ModalRecharge.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    partnerId: 0
}

export default ModalRecharge

