import "animate.css"
import axios from 'axios'
import React, { useContext } from 'react'
import { MdMenu } from 'react-icons/md'
import 'react-notifications-component/dist/theme.css'
import {
    BrowserRouter as Router, Redirect, Route, Switch, useHistory
} from "react-router-dom"
import SimpleBar from 'simplebar-react'
import './App.css'
import { AdminLeftMenu, AppContext, AuthAdminView, Config, Notification } from './components'
import {
    AttributePage, CategoryPage, DashboardPage, OrderPage, PartnerGroupPage, PartnerPage, PopupPage, ProductFromExcel, ProductPage,
    RolePage, SettingPage, ShippingUnitPage, StatisticPartnerOrder, UserInfoPage, UserPage,
    FreeShipPage
} from './pages/admin'

var collapsed = false

// Phóng to, thu nhỏ left menu
const handleCollapse = () => {
    if (!collapsed) {
        document.body.classList.add('sidebar-enable')
        document.body.setAttribute('data-leftbar-compact-mode', 'condensed')
    }
    else {
        document.body.classList.remove('sidebar-enable')
        document.body.setAttribute('data-leftbar-compact-mode', '')
    }
    collapsed = !collapsed
}

function App() {
    const appSettings = useContext(AppContext)

    const history = useHistory()

    const token = localStorage.getItem('a-token')

    if (token === null || token === '') return <Redirect to='/admin/login' />
    else {
        const pathName = window.location.pathname
        if (pathName === '/admin' || pathName === '/admin/') {
            history.replace('/admin/dashboard')
        }
    }

    return (
        <div className="wrapper">

            <Router>

                <div className="leftside-menu">

                    <span className="logo text-center logo-dark">
                        <span className="logo-lg">
                            <img src="/assets/images/logo-dark.png" alt="logo" height={Config.logoHeight} />
                        </span>
                        <span className="logo-sm">
                            <img src="/assets/images/logo_sm_dark.png" alt="logo" height={Config.logoHeight} />
                        </span>
                    </span>

                    <SimpleBar className="h-100" id="leftside-menu-container">
                        <AdminLeftMenu
                            onLogout={() => {
                                Notification.confirm('Bạn có chắc chắn muốn đăng xuất không?', () => {
                                    const url = '/admin/user/logout'
                                    axios.post(url).then(({ data }) => {
                                        localStorage.removeItem('a-right')
                                        localStorage.removeItem('a-roleName')
                                        localStorage.removeItem('a-fullName')
                                        localStorage.removeItem('a-token')
                                        localStorage.removeItem('a-avatarUrl')
                                        axios.defaults.headers.common['A-Token'] = ''
                                        appSettings.setRights([])
                                        history.replace('/admin/login')
                                    })
                                })
                            }}
                        />

                        <div className="clearfix"></div>
                    </SimpleBar>

                </div>

                <div className="content-page">
                    <div className="content">

                        <div className="navbar-custom">
                            <ul className="list-unstyled topbar-menu float-end mb-0">
                                <li className="dropdown notification-list d-lg-none">
                                    <a className="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="/" role="button" aria-haspopup="false" aria-expanded="false">
                                        <i className="dripicons-search noti-icon"></i>
                                    </a>
                                </li>
                                <li className="dropdown notification-list">
                                    <span className="nav-link dropdown-toggle nav-user arrow-none me-0">
                                        <span className="account-user-avatar">
                                            <img src="/assets/images/users/avatar-2.jpg" alt="user-avatar" className="rounded-circle" />
                                        </span>
                                        <span>
                                            <span className="account-user-name">{appSettings.adminFullName}</span>
                                            <span className="account-position">Quản trị viên</span>
                                        </span>
                                    </span>
                                </li>
                            </ul>
                            <button className="button-menu-mobile open-left" onClick={handleCollapse}>
                                <MdMenu style={{ marginTop: '-10px' }} />
                            </button>
                            <span className="page-title" id="page-header"></span>
                        </div>

                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12" style={{ paddingTop: '24px' }}>
                                    <Switch>
                                        <Route path="/admin/dashboard">
                                            <DashboardPage />
                                        </Route>
                                        <Route path="/admin/partner-group">
                                            <AuthAdminView right="partner_group_view">
                                                <PartnerGroupPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/partner">
                                            <AuthAdminView right="partner_view">
                                                <PartnerPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/category">
                                            <AuthAdminView right="category_view">
                                                <CategoryPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/product/create-from-excel">
                                            <AuthAdminView right="product_add">
                                                <ProductFromExcel />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/product">
                                            <AuthAdminView right="product_view">
                                                <ProductPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/attribute">
                                            <AuthAdminView right="attribute_view">
                                                <AttributePage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/shipping-unit">
                                            <AuthAdminView right="shipping_unit_view">
                                                <ShippingUnitPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/order">
                                            <AuthAdminView right="order_view">
                                                <OrderPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/statistic-partner-order">
                                            <AuthAdminView right="statistic_partner_order">
                                                <StatisticPartnerOrder />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/popup">
                                            <AuthAdminView right="popup_view">
                                                <PopupPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/freeship">
                                            <AuthAdminView right="popup_view">
                                                <FreeShipPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/user-info">
                                            <AuthAdminView right="account_view">
                                                <UserInfoPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/user">
                                            <AuthAdminView right="user_view">
                                                <UserPage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/role">
                                            <AuthAdminView right="role_view">
                                                <RolePage />
                                            </AuthAdminView>
                                        </Route>
                                        <Route path="/admin/setting">
                                            <AuthAdminView right="setting_view">
                                                <SettingPage />
                                            </AuthAdminView>
                                        </Route>
                                    </Switch>
                                </div>
                            </div>

                        </div>

                    </div>

                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    Copyright &copy; {new Date().getFullYear()} Drop Viet
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end footer-links d-none d-md-block">
                                        <span>Version: {Config.version}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>

                </div>

            </Router>

        </div>
    )
}

export default App