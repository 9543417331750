import React, { useState } from 'react'
import { IoSaveOutline } from 'react-icons/io5'
import PropTypes from 'prop-types'

const LoadingButton = props => {
    const [isBusy, setIsBusy] = useState(false);

    const handleOnClick = () => {
        if (props.onClick) {
            props.onClick(setIsBusy)
        }
    }

    const renderIcon = () => {
        if (isBusy) {
            return <div className="spinner-border spinner-border-sm me-1" role="status"></div>
        }
        else {
            return props.icon;
        }
    }

    return (
        <button type={props.type} className="btn btn-primary" onClick={handleOnClick} disabled={isBusy}>
            {renderIcon()}
            {props.title}
        </button>
    );
}

LoadingButton.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    // icon: 
}

LoadingButton.defaultProps = {
    type: 'button',
    title: 'Lưu',
    onClick: null,
    icon: <IoSaveOutline className="mt--3-px me-1 font-17" />
}

export default LoadingButton