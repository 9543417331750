import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'
import PropTypes from 'prop-types'

const schema = yup.object().shape({
    ShippingUnitName: yup.string().required('Vui lòng nhập tên đơn vị giao hàng').max(100, 'Tên quá dài'),
    StatusId: yup.number(),
    Note: yup.string().max(200, 'Ghi chú quá dài')
})

const ModalAdd = props => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới đơn vị vận chuyển</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => props.onSave(formData, setIsBusy))}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tên đơn vị giao hàng <span className="text-danger">*</span></label>
                        <input {...register('ShippingUnitName')} type="text" className="form-control" placeholder="Tên đơn vị giao hàng" />
                        {errors.ShippingUnitName && <div className="validation-message">{errors.ShippingUnitName.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Trạng thái</label>
                        <select {...register('StatusId')} className="form-select">
                            <option value="1">Hoạt động</option>
                            <option value="2">Tạm khóa</option>
                        </select>
                        {errors.StatusId && <div className="validation-message">{errors.StatusId.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Ghi chú</label>
                        <textarea {...register('Note')} rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                        {errors.Note && <div className="validation-message">{errors.Note.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAdd.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool
}

ModalAdd.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false
}

export default ModalAdd