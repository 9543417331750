import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoTrashOutline } from 'react-icons/io5'
import { ActionItem, ActionRow, EmptyRow, FormatNumber, LoadingRow } from '../../../../../components'

const TableAttrChild = props => {
    const renderTable = () => {
        let data = props.data
        if (data === null) return <LoadingRow colSpan={5} />
        else if (data.length === 0) return <EmptyRow colSpan={5} />
        else return data.map((item, index) => <tr key={item.ChildrenAttributeId}>
            <td className="text-center">{index + 1}</td>
            <td>{item.ChildrenAttributeName}</td>
            <td className="text-end text-primary">
                <FormatNumber>
                    {item.TotalUsing}
                </FormatNumber>
            </td>
            <td>
                {item.CreatedDate}
                <small className="text-muted"> {item.CreatedTime}</small>
            </td>
            <td>
                <ActionRow>
                    <ActionItem right="attribute_edit_child" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                    <ActionItem right="attribute_delete_child" onClick={() => props.onDelete(item)} icon={<IoTrashOutline />} title='Xóa' className="text-danger" />
                </ActionRow>
            </td>
        </tr>)
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Giá trị</th>
                        <th className='text-end'>Sử dụng</th>
                        <th>Ngày tạo</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

TableAttrChild.propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    data: PropTypes.array
}

TableAttrChild.defaultProps = {
    onEdit: () => { },
    onDelete: () => { },
    data: null
}

export default TableAttrChild