import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

function Revenue(props) {
    const { data } = props

    const [series, setSeries] = useState([{
        name: 'Doanh thu',
        data: []
    }, {
        name: 'Lợi nhuận',
        data: []
    }])

    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: 279,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '60%',
                endingShape: 'flat'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [],
        },
        yaxis: {
            title: {
                text: 'Nghìn đồng'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " nghìn đồng"
                }
            }
        }
    })

    useEffect(() => {
        if (data.length !== 0) {
            const income = {
                name: 'Doanh thu',
                data: []
            }
            income.data = data.map(item => item.Income)
            const revenue = {
                name: 'Lợi nhuận',
                data: []
            }
            revenue.data = data.map(item => item.Revenue)
            setSeries([
                income,
                revenue
            ])
            const cats = data.map(item => item.Date)
            const newOptions = {
                ...options,
                xaxis: {
                    categories: cats,
                }
            }
            setOptions(newOptions)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <div className="card">
            <div className="card-body">
                <ReactApexChart options={options} series={series} type="bar" height={279} />
            </div>
        </div>
    )
}

Revenue.propTypes = {
    data: PropTypes.array,
}

Revenue.defaultProps = {
    data: []
}

export default Revenue