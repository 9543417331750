import "animate.css"
import axios from 'axios'
import vi from 'date-fns/locale/vi'
import React, { useState } from 'react'
import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom"
import AdminLayout from './AdminLayout'
import './App.css'
import { AppContext, Notification } from './components'
import { LoginPage, RegisterPage } from './pages/partner'
import { LoginPage as AdminLoginPage } from './pages/admin'
import PartnerLayout from './PartnerLayout'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

loadProgressBar()

registerLocale('vi', vi)
setDefaultLocale('vi')

axios.defaults.baseURL = window.apiUrl
// axios.defaults.baseURL = 'https://api.drop.radovietnam.com'
// axios.defaults.baseURL = 'http://192.168.0.15:8002'

axios.defaults.timeout = 10_000
axios.defaults.headers.common['A-Token'] = localStorage.getItem('a-token')
axios.defaults.headers.common['P-Token'] = localStorage.getItem('p-token')
axios.interceptors.response.use(function (response) {
    try {
        const responseType = response.config.responseType
        if (responseType !== 'blob') {
            if (!response.data.IsSuccess) {
                if (response.data.Message !== '') {
                    Notification.warning(response.data.Message)

                    if (response.data.Code === 401) {
                        const url = response.config.url
                        const urlArr = url.split('/')
                        if (urlArr.length > 2) {
                            const adminOrPartner = urlArr[1]
                            if (adminOrPartner === 'admin') {

                                axios.defaults.headers.common['A-Token'] = ''

                                localStorage.removeItem('a-right')
                                localStorage.removeItem('a-roleName')
                                localStorage.removeItem('a-fullName')
                                localStorage.removeItem('a-token')
                                localStorage.removeItem('a-avatarUrl')

                                window.location.reload()
                            }
                            else if (adminOrPartner === 'partner') {

                                axios.defaults.headers.common['P-Token'] = ''

                                localStorage.removeItem('p-email')
                                localStorage.removeItem('p-fullName')
                                localStorage.removeItem('p-token')
                                localStorage.removeItem('p-avatarUrl')

                                window.location.reload()
                            }
                        }
                    }
                }
            }
        }
    }
    catch { }
    return response
}, function (error) {
    Notification.danger('Có lỗi xảy ra, vui lòng liên hệ quản trị viên')
    return Promise.reject(error)
})

function App(props) {
    const [rights, setRights] = useState(() => {
        const strRight = localStorage.getItem('a-right')
        if (strRight !== null && strRight !== '') return strRight.split(';')
        return []
    })

    const [adminFullName, setAdminFullName] = useState(() => {
        const fullName = localStorage.getItem('a-fullName')
        if (fullName === null || fullName === '') return 'Chưa cập nhật'
        else return fullName
    })

    const [adminAvatarUrl, setAdminAvatarUrl] = useState(() => {
        const avatarUrl = localStorage.getItem('a-avatarUrl')
        if (avatarUrl === null || avatarUrl === '') return ''
        else return avatarUrl
    })

    const [adminRole, setAdminRole] = useState(() => {
        const roleName = localStorage.getItem('a-roleName')
        if (roleName === null || roleName === '') return 'Chưa cập nhật'
        else return roleName
    })

    const [partnerFullName, setPartnerFullName] = useState(() => {
        const fullName = localStorage.getItem('p-fullName')
        if (fullName === null || fullName === '') return 'Chưa cập nhật'
        else return fullName
    })

    const [partnerAvatarUrl, setPartnerAvatarUrl] = useState(() => {
        const avatarUrl = localStorage.getItem('p-avatarUrl')
        if (avatarUrl === null || avatarUrl === '') return ''
        else return avatarUrl
    })

    const appSettings = {
        rights,
        setRights,
        adminFullName,
        setAdminFullName,
        adminAvatarUrl,
        setAdminAvatarUrl,
        adminRole,
        setAdminRole,
        partnerFullName,
        setPartnerFullName,
        partnerAvatarUrl,
        setPartnerAvatarUrl
    }

    return (
        <AppContext.Provider value={appSettings}>
            <ReactNotification />
            <Router>
                <Switch>
                    <Route path="/admin/login">
                        <AdminLoginPage />
                    </Route>
                    <Route path="/admin">
                        <AdminLayout />
                    </Route>
                    <Route path="/partner/register">
                        <RegisterPage />
                    </Route>
                    <Route path="/partner">
                        <PartnerLayout />
                    </Route>
                    <Route path="/">
                        <LoginPage />
                    </Route>
                </Switch>
            </Router>
        </AppContext.Provider>
    )
}

export default App