import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoTrashOutline } from 'react-icons/io5'
import { ActionItem, ActionRow, EmptyRow, LoadingRow } from './../../../../../components'

const TableAttr = props => {
    const onEdit = (item, e) => {
        e.stopPropagation()
        props.onEdit(item);
    }

    const onDelete = (item, e) => {
        e.stopPropagation()
        props.onDelete(item)
    }

    const renderTable = () => {
        let data = props.data;
        if (data === null) return <LoadingRow colSpan={3} />
        else if (data.length === 0) return <EmptyRow colSpan={3} />
        else return data.map((item, index) => <tr key={item.AttributeId} onClick={() => props.onSelect(item)} className={item.isSelected ? 'table-primary' : ''}>
            <td className="text-center">{(index + 1)}</td>
            <td>{item.AttributeName}</td>
            <td>
                <ActionRow>
                    <ActionItem right="attribute_edit" onClick={e => onEdit(item, e)} icon={<FiEdit />} title='Chỉnh sửa' />
                    <ActionItem right="attribute_delete" onClick={e => onDelete(item, e)} icon={<IoTrashOutline />} title='Xóa' className="text-danger" />
                </ActionRow>
            </td>
        </tr>)
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered table-point mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Tên thuộc tính</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

TableAttr.propTypes = {
    data: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func
}

TableAttr.defaultProps = {
    data: null,
    onEdit: () => { },
    onDelete: () => { },
    onSelect: () => { }
}

export default TableAttr