import React from 'react'
import PropTypes from 'prop-types'

const LoadingRow = props => {
    return (
        <tr>
            <td colSpan={props.colSpan} className="text-center">
                <div className="spinner-border text-primary mt-2 mb-1" role="status"></div>
                <p>{props.title}</p>
            </td>
        </tr>
    )
}

LoadingRow.propTypes = {
    colSpan: PropTypes.number,
    title: PropTypes.string
}

LoadingRow.defaultProps = {
    colSpan: 1,
    title: 'Đang tải dữ liệu'
}

export default LoadingRow