import React, { Fragment, useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { CategoryService } from './../../../services/admin'
import { AuthAdminView, IconButton, Notification } from './../../../components'
import { Filter, ModalAdd, ModalEdit, Table } from './components'

const getCategoryLevel = (data, parentId, catName) => {
    let result = []
    const lst = data.filter(n => n.ParentId === parentId)
    if (lst.length !== 0) {
        lst.forEach((item) => {
            const levelCatName = `${catName !== '' ? `${catName} > ` : ''}${item.CategoryName}`
            result.push({
                ...item,
                Label: `${catName !== '' ? `${catName} > ` : ''}${item.CategoryName}`
            })
            const temp = getCategoryLevel(data, item.CategoryId, levelCatName)
            temp.forEach(cat => {
                result.push(cat)
            })
        })
    }
    return result
}

const CategoryPage = () => {
    // State lưu danh sách nhóm sản phẩm
    const [category, setCategory] = useState()

    // State lưu trạng thái đóng mở modal thêm mới nhóm sản phẩm
    const [isShowModalCategoryAdd, setIsShowModalCategoryAdd] = useState(false)

    // State lưu trạng thái đóng mở modal chỉnh sửa nhóm sản phẩm
    const [isShowModalCategoryEdit, setEditIsShowModalCategoryEdit] = useState(false)

    // State lưu danh sách nhóm sản phẩm dropdown để chọn khi thêm hoặc sửa nhóm san phẩm
    const [categoryDropdown, setCategoryDropdown] = useState([])

    // State lưu thông tin nhóm sản phẩm để chỉnh sửa
    const [categoryInfo, setCategoryInfo] = useState({})

    const [filters, setFilters] = useState({
        keyword: ''
    })

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Nhóm sản phẩm';
        loadCategoryDropdown()
    }, [])

    useEffect(() => {
        CategoryService.filter(filters).then(({ data }) => {
            if (data.IsSuccess) {
                const cats = getCategoryLevel(data.Result, 0, '')
                setCategory(cats)
            }
        })
    }, [filters])

    const loadCategoryDropdown = () => {
        CategoryService.getDropdown().then(({ data }) => {
            if (data.IsSuccess) {
                setCategoryDropdown(data.Result)
            }
        })
    }

    const reloadCategory = () => {
        setFilters({
            ...filters
        })
    }

    return (
        <Fragment>
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params
                    }
                    setFilters(newFilters)
                }}
            />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="category_add">
                                <IconButton
                                    className="mb-2"
                                    text="Thêm mới"
                                    icon={<IoAddCircleOutline />}
                                    onClick={() => {
                                        setIsShowModalCategoryAdd(true);
                                    }} />
                            </AuthAdminView>
                        </div>
                    </div>
                    <Table
                        data={category}
                        onEdit={item => {
                            CategoryService.getById(item.CategoryId).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setCategoryInfo(data.Result)
                                    setEditIsShowModalCategoryEdit(true)
                                }
                            })
                        }}
                        onDelete={item => {
                            Notification.confirmHtml('Bạn có chắc muốn xóa nhóm sản phẩm', item.CategoryName, () => {
                                CategoryService.delete(item.CategoryId).then(({ data }) => {
                                    if (data.IsSuccess) {
                                        reloadCategory()
                                        loadCategoryDropdown()
                                        Notification.success('Xóa nhóm sản phẩm thành công')
                                    }
                                })
                            })
                        }} />
                </div>
            </div>

            <ModalAdd
                parent={categoryDropdown}
                isShow={isShowModalCategoryAdd}
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    CategoryService.create(formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadCategory()
                            loadCategoryDropdown()
                            setIsShowModalCategoryAdd(false)
                            Notification.success('Thêm mới nhóm sản phẩm thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalCategoryAdd(false)
                }} />

            <ModalEdit
                data={categoryInfo}
                parent={categoryDropdown}
                isShow={isShowModalCategoryEdit}
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    CategoryService.update(formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadCategory()
                            loadCategoryDropdown()
                            setCategoryInfo({})
                            setEditIsShowModalCategoryEdit(false)
                            Notification.success('Chỉnh sửa nhóm sản phẩm thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setCategoryInfo({})
                    setEditIsShowModalCategoryEdit(false)
                }} />
        </Fragment>
    )
}

export default CategoryPage