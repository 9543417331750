import PropTypes from 'prop-types'
import React from 'react'
import { AiOutlineHistory } from 'react-icons/ai'
import {
    IoCardOutline,
    IoLogOutOutline, IoPersonOutline, IoReceiptOutline, IoScanCircleOutline, IoShirtOutline, IoSpeedometerOutline,
    IoLayersOutline,
    // IoCloudDownloadOutline,
    // IoStorefrontOutline,
} from 'react-icons/io5'
import { NavLink } from "react-router-dom"

function PartnerLeftMenu(props) {
    return (
        <ul className="side-nav">

            <li className="side-nav-title side-nav-item">Trang chủ</li>

            <li className="side-nav-item">
                <NavLink to="/partner/dashboard" className="side-nav-link">
                    <IoSpeedometerOutline />
                    <span> Tổng quan </span>
                </NavLink>
            </li>

            <li className="side-nav-title side-nav-item">Quản lý</li>

            <li className="side-nav-item">
                <NavLink to="/partner/product" className="side-nav-link">
                    <IoShirtOutline />
                    <span> Danh sách sản phẩm </span>
                </NavLink>
            </li>

            <li className="side-nav-item">
                <NavLink to="/partner/product-checking" className="side-nav-link">
                    <IoScanCircleOutline />
                    <span> Kiểm tra ID sản phẩm </span>
                </NavLink>
            </li>

            <li className="side-nav-item">
                <NavLink to="/partner/customer" className="side-nav-link">
                    <IoPersonOutline />
                    <span> Khách hàng </span>
                </NavLink>
            </li>

            <li className="side-nav-item">
                <NavLink to="/partner/order" className="side-nav-link">
                    <IoReceiptOutline />
                    <span> Đơn hàng </span>
                </NavLink>

            </li>

            {/* <li className="side-nav-item">
                <NavLink to="/partner/shop" className="side-nav-link">
                    <IoStorefrontOutline />
                    <span> Tài khoản shopee</span>
                </NavLink>
            </li>

            <li className="side-nav-item">
                <NavLink to="/partner/order-from-shopee" className="side-nav-link">
                    <IoCloudDownloadOutline />
                    <span> Đơn hàng từ shopee</span>
                </NavLink>
            </li> */}

            <li className="side-nav-item">
                <NavLink to="/partner/children" className="side-nav-link">
                    <IoLayersOutline />
                    <span> Đã giới thiệu</span>
                </NavLink>
            </li>

            <li className="side-nav-title side-nav-item">Tài khoản</li>

            <li className="side-nav-item">
                <NavLink to="/partner/profile" className="side-nav-link">
                    <IoCardOutline />
                    <span> Thông tin cá nhân </span>
                </NavLink>
            </li>

            <li className="side-nav-item">
                <NavLink to="/partner/balance-history" className="side-nav-link">
                    <AiOutlineHistory />
                    <span> Lịch sử giao dịch </span>
                </NavLink>
            </li>

            <li className="side-nav-item">
                <span className="side-nav-link cursor-point" onClick={() => {
                    props.onLogout()
                }}>
                    <IoLogOutOutline />
                    <span> Đăng xuất </span>
                </span>
            </li>

        </ul>
    )
}

PartnerLeftMenu.propTypes = {
    onLogout: PropTypes.func,
}

PartnerLeftMenu.defaultProps = {
    onLogout: () => { }
}

export default PartnerLeftMenu