import axios from 'axios'

const ChildrenAttributeService = {
    getByAttribute: attributeId => {
        const url = `/admin/children-attribute/get-by-attribute?id=${attributeId}`
        return axios.get(url)
    },
    create: formData => {
        const url = '/admin/children-attribute/create'
        return axios.post(url, formData)
    },
    getById: childAttributeId => {
        const url = `/admin/children-attribute/get-by-id?id=${childAttributeId}`
        return axios.get(url)
    },
    update: formData => {
        const url = '/admin/children-attribute/update'
        return axios.post(url, formData)
    },
    delete: childAttributeId => {
        const url = `/admin/children-attribute/delete?id=${childAttributeId}`
        return axios.post(url)
    }
}

export default ChildrenAttributeService