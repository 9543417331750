import PropTypes from 'prop-types'
import React from 'react'
import { IoEllipseSharp } from 'react-icons/io5'
import { EmptyRow, FormatNumber, LoadingRow } from '../../../../../components'

function renderType(typeId) {
    if (typeId === 1) return <><IoEllipseSharp className="text-info mt--3-px" /> Cộng tác viên</>
    else if (typeId === 2) return <><IoEllipseSharp className="text-primary mt--3-px" /> Nhóm cộng tác viên</>
}

function Table(props) {

    const { data, pageItem, pageIndex } = props

    const renderTable = () => {
        if (data === null) return <LoadingRow colSpan={8} />
        else if (data.length === 0) return <EmptyRow colSpan={8} />
        else return data.map((item, index) => <tr key={item.FreeShipId}>
            <td className="text-center">
                <FormatNumber>
                    {(pageItem * (pageIndex - 1)) + index + 1}
                </FormatNumber>
            </td>
            <td>{item.Title}</td>
            <td className='text-end text-primary'>
                <FormatNumber>
                    {item.Qty}
                </FormatNumber>
            </td>
            <td>
                {renderType(item.TypeId)}
            </td>
            <td>
                {item.GroupName}
            </td>
            <td>
                {item.PartnerFullName}
            </td>
            <td className='text-end text-primary'>
                <FormatNumber>
                    {item.TotalPartner}
                </FormatNumber>
            </td>
            <td>
                {item.CreatedDate}
                <small className="text-muted"> {item.CreatedTime}</small>
            </td>
            {/* <td>
                <ActionRow>
                    <ActionItem right="popup_edit" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                    <ActionItem right="popup_delete" onClick={() => props.onDelete(item)} icon={<IoTrashOutline />} title='Xóa' className='text-danger' />
                </ActionRow>
            </td> */}
        </tr>)
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Tiêu đề</th>
                        <th className='text-end'>Số lượng</th>
                        <th>Loại</th>
                        <th>Nhóm</th>
                        <th>CTV</th>
                        <th className='text-end'>Số CTV</th>
                        <th>Ngày tạo</th>
                        {/* <th className="w-52-px"></th> */}
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
}

Table.defaultProps = {
    data: null,
    pageItem: 0,
    pageIndex: 0,
}

export default Table
