import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ClearButton, SearchButton } from './../../../../../components'
import Select from 'react-select'

const filters = {
    keyword: '',
    roleId: 0
}

function Filter(props) {
    const { onSearch, roles } = props

    const [keyword, setKeyword] = useState('')

    const [role, setRole] = useState(null)

    const [roleOptions, setRoleOptions] = useState([])

    useEffect(() => {
        let data = roles.map(item => {
            return {
                value: item.RoleId,
                label: item.RoleName
            }
        })
        setRoleOptions(data)
    }, [roles])

    const handleSearch = () => {
        onSearch(filters)
    }

    const handleClear = () => {
        setKeyword('')
        filters.keyword = ''
        setRole(null)
        filters.roleId = 0
        onSearch(filters)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                        <input value={keyword}
                            autoFocus
                            onChange={e => {
                                setKeyword(e.target.value)
                            }} type="text" className="form-control" autoComplete="off" placeholder="Họ tên, email, điện thoại" />
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            placeholder='Vai trò'
                            options={roleOptions}
                            value={role}
                            onChange={item => {
                                if (item !== null) {
                                    setRole(item)
                                    filters.roleId = item.value
                                }
                                else {
                                    setRole(null)
                                    filters.roleId = 0
                                }
                            }}
                        />
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-6 col-xl-6 col-xxl-8">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                            <ClearButton onClick={handleClear} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func,
    roles: PropTypes.array
}

Filter.defaultProps = {
    onSearch: () => { },
    roles: []
}

export default Filter