import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { EmptyRow, FormatNumber, LoadingRow, Pagination } from '../../../../../components'
import { IoEllipseSharp } from 'react-icons/io5'

function FreeShipTable(props) {

    const { data, pagination, onPageChange, pageItem } = props

    const renderTable = () => {

        const { pageIndex } = pagination

        if (data === null) return <LoadingRow colSpan={6} />
        else if (data.length === 0) return <EmptyRow colSpan={6} />
        else return data.map((item, index) => <tr key={item.FreeShipHistoryId}>
            <td className="text-primary">
                <FormatNumber>
                    {(pageItem * (pageIndex - 1)) + index + 1}
                </FormatNumber>
            </td>
            <td>
                {renderType(item.TypeId)}
            </td>
            <td className={item.Qty > -1 ? 'text-end text-primary' : 'text-end text-danger'}>
                <FormatNumber>
                    {item.Qty}
                </FormatNumber>
            </td>
            <td>
                {item.FreeShipTitle}
            </td>
            <td>
                {item.OrderId !== null ? item.OrderId : ''}
            </td>
            <td>
                {item.CreatedDate}
                <small className='text-muted'> {item.CreatedTime}</small>
            </td>
        </tr>)
    }

    function renderType(typeId) {
        if (typeId === 1) return <><IoEllipseSharp className="text-info mt--3-px" /> Admin cộng</>
        else if (typeId === 2) return <><IoEllipseSharp className="text-primary mt--3-px" /> Trừ khi đặt hàng</>
        else return <><IoEllipseSharp className="text-warning mt--3-px" /> Cộng từ hủy đơn hàng</>
    }

    return (
        <Fragment>
            <div className="table-responsive">
                <table className="table table-centered mb-0">
                    <thead>
                        <tr>
                            <th className="w-52-px">#</th>
                            <th>Loại</th>
                            <th className='text-end'>Số lượng</th>
                            <th>Miễn phí vận chuyển</th>
                            <th>ID đơn hàng</th>
                            <th>Ngày tạo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable()}
                    </tbody>
                </table>
            </div>
            <Pagination
                totalPage={pagination.totalPage}
                currentPage={pagination.pageIndex}
                onPageChange={(pIndex) => {
                    onPageChange(pagination.partnerId, pIndex)
                }}
            />
        </Fragment>
    )
}

FreeShipTable.propTypes = {
    data: PropTypes.array,
    pagination: PropTypes.object,
    onPageChange: PropTypes.func,
    pageItem: PropTypes.number
}

FreeShipTable.defaultProps = {
    data: [],
    pagination: {},
    onPageChange: () => { },
    pageItem: 0
}

export default FreeShipTable