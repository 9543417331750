import PropTypes from 'prop-types'
import React from 'react'

function InputSelect(props) {
    const variantClassName = (variant) => {
        return `form-control form-check form-radio-${variant !== undefined ? variant : props.variant} position-relative`
    }

    const onChange = (e) => {
        const isChecked = e.target.checked
        const value = parseInt(e.target.value)
        if (isChecked) {
            if (props.onChange) {
                props.onChange(value)
            }
        }
    }

    const renderControl = () => {
        const name = props.name
        const options = props.options
        const className = !props.isVertical ? 'col' : 'col-12'
        return options.map((item, index) => {
            return (
                <div className={className} key={item.value}>
                    <div className={variantClassName(item.variant)}>
                        <input
                            checked={item.value === props.value}
                            type="radio"
                            value={item.value}
                            id={name + '-' + index}
                            name={name}
                            className="form-check-input input-select-radio"
                            onChange={onChange}
                        />
                        <label
                            className="form-check-label input-select-label"
                            htmlFor={name + '-' + index}>{item.label}
                        </label>
                    </div>
                </div>
            )
        })
    }

    const renderContainer = () => {
        const className = !props.isVertical ? 'row' : 'row gy-2'
        return <div className={className}>
            {renderControl()}
        </div>
    }

    return (
        renderContainer()
    )
}

InputSelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    name: PropTypes.string,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    isVertical: PropTypes.bool
}

InputSelect.defaultProps = {
    options: [
        {
            value: 1,
            label: 'Option 1',
            variant: 'primary'
        },
        {
            value: 2,
            label: 'Option 2',
            variant: 'warning'
        }
    ],
    value: 0,
    defaultValue: 0,
    name: 'input-select',
    variant: 'primary',
    onChange: () => { },
    isVertical: false
}

export default InputSelect