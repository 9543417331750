import React, { useEffect, Fragment, useState } from 'react'
import { Filter, List, ModalAddToCart, ModalDetail, ModalOrder } from './components'
import axios from 'axios'
import { Pagination, Notification } from './../../../components'

const ProductPage = (props) => {
    const [products, setProducts] = useState(null)

    const [categories, setCategories] = useState([])

    const [countCart, setCountCart] = useState(0)

    const [modalAddToCartInfo, setModalAddToCartInfo] = useState({
        isShow: false,
        products: []
    })

    const [modalDetailInfo, setModalDetailInfo] = useState({
        isShow: false,
        data: {}
    })

    const [modalOrderInfo, setModalOrderInfo] = useState({
        isShow: false,
        products: [],
        dropShipFee: 0,
        dropShipFeeBase: 0,
        freeShipQty: 0
    })

    const [filters, setFilters] = useState({
        keyword: '',
        catId: 0,
        pageItem: 24,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    const [provinceList, setProvinceList] = useState([])

    const [districtList, setDistrictList] = useState([])

    const [wardList, setWardList] = useState([])

    const [shippingUnits, setShippingUnits] = useState([])

    const [shippingMethods, setShippingMethods] = useState([])

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Danh sách sản phẩm'
        loadCategory()
        loadCountCart()
        loadShippingUnit()
        loadProvince()
        loadShippingMethod()
    }, [])

    useEffect(() => {
        const url = `/partner/product/get-active?keyword=${filters.keyword}&catId=${filters.catId}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setProducts(data.Result.Data)
            }
        })
    }, [filters])

    const loadCountCart = () => {
        const url = `/partner/cart/count`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setCountCart(data.Result)
            }
        })
    }

    const loadCategory = () => {
        const url = '/partner/category/get-active-dropdown'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setCategories(data.Result)
            }
        })
    }

    const loadProvince = () => {
        const url = '/partner/province/get-all'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setProvinceList(data.Result)
            }
        })
    }

    const loadDistrict = (provinceId) => {
        const url = `/partner/province/${provinceId}/districts`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setDistrictList(data.Result)
            }
        })
    }

    const loadWard = (wardId) => {
        const url = `/partner/district/${wardId}/wards`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setWardList(data.Result)
            }
        })
    }

    const loadShippingUnit = () => {
        const url = '/partner/shipping-unit/get-dropdown'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setShippingUnits(data.Result)
            }
        })
    }

    const loadShippingMethod = () => {
        const url = '/partner/shipping-method/get-dropdown'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setShippingMethods(data.Result)
            }
        })
    }

    const reloadProduct = () => {
        setFilters({
            ...filters,
            pageIndex: 1
        })
    }

    return (
        <Fragment>
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
                onOrder={(setIsBusy) => {
                    if (countCart < 1) {
                        Notification.warning('Vui lòng chọn sản phẩm trước khi đặt hàng')
                        return
                    }
                    setIsBusy(true)
                    const url = `/partner/cart/product`
                    axios.get(url).then(({ data }) => {
                        if (data.IsSuccess) {
                            setModalOrderInfo({
                                isShow: true,
                                products: data.Result.Products,
                                dropShipFee: data.Result.DropShipFee,
                                dropShipFeeBase: data.Result.DropShipFee,
                                freeShipQty: data.Result.FreeShipQty
                            })
                            setIsBusy(false)
                        }
                    })
                }}
                categories={categories}
                countCart={countCart}
            />
            <div className="card">
                <div className="card-body">
                    <List
                        products={products}
                        onAddToCart={(productId, setIsBusy) => {
                            setIsBusy(true)
                            const url = `/partner/product/get-children?parentId=${productId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setModalAddToCartInfo({
                                        isShow: true,
                                        products: data.Result
                                    })
                                }
                                setIsBusy(false)
                            })
                        }}
                        onShowDetail={(productId) => {
                            const url = `/partner/product/parent-detail?id=${productId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setModalDetailInfo({
                                        isShow: true,
                                        data: data.Result
                                    })
                                }
                            })
                        }}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>
            <ModalAddToCart
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = `/partner/cart/create`
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            setModalAddToCartInfo({
                                isShow: false,
                                products: []
                            })
                            loadCountCart()
                            Notification.success('Thêm sản phẩm vào giỏ hàng thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setModalAddToCartInfo({
                        isShow: false,
                        products: []
                    })
                }}
                isShow={modalAddToCartInfo.isShow}
                products={modalAddToCartInfo.products}
            />
            <ModalDetail
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = `/partner/cart/create`
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadCountCart()
                            setModalDetailInfo({
                                isShow: false,
                                data: {}
                            })
                            Notification.success('Thêm sản phẩm vào giỏ hàng thành công')
                        }
                    })
                }}
                onClose={() => {
                    setModalDetailInfo({
                        isShow: false,
                        data: {}
                    })
                }}
                isShow={modalDetailInfo.isShow}
                data={modalDetailInfo.data}
            />
            <ModalOrder
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/partner/order/create'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            setCountCart(0)
                            reloadProduct()
                            setModalOrderInfo({
                                isShow: false,
                                products: [],
                                dropShipFee: 0
                            })
                            Notification.success('Đặt hàng thành công')
                        }
                    }).finally(() => {
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setModalOrderInfo({
                        isShow: false,
                        products: []
                    })
                }}
                isShow={modalOrderInfo.isShow}
                provinces={provinceList}
                onProvinceChange={(provinceId) => {
                    loadDistrict(provinceId)
                }}
                districts={districtList}
                onDistrictChange={(districtId) => {
                    loadWard(districtId)
                }}
                wards={wardList}
                products={modalOrderInfo.products}
                shippingUnits={shippingUnits}
                shippingMethods={shippingMethods}
                onRemoveCart={(cartId) => {
                    const url = `/partner/cart/remove?cartId=${cartId}`
                    axios.post(url).then(({ data }) => {
                        if (data.IsSuccess) {
                            loadCountCart()
                            const temp = modalOrderInfo.products.filter(n => n.CartId !== cartId)
                            setModalOrderInfo({
                                isShow: true,
                                products: temp,
                                dropShipFee: 0
                            })
                        }
                    })
                }}
                dropShipFee={modalOrderInfo.dropShipFee}
                freeShipQty={modalOrderInfo.freeShipQty}
                onFreeShipChange={(isCheck) => {
                    if (isCheck) {
                        setModalOrderInfo({
                            ...modalOrderInfo,
                            dropShipFee: 0
                        })
                    }
                    else {
                        setModalOrderInfo({
                            ...modalOrderInfo,
                            dropShipFee: modalOrderInfo.dropShipFeeBase
                        })
                    }
                }}
            />
        </Fragment>
    )
}

export default ProductPage