import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IoAddCircleOutline, IoCloudUploadOutline } from 'react-icons/io5'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useHistory } from "react-router-dom"
import { IconButton, Notification, Pagination, AuthAdminView } from './../../../components'
import { Filter, ModalAdd, ModalEdit, ModalEditPrice, ModalEditQty, ModalEditQtyExcel, Table, ModalEditStatus } from './components'
import ImageViewer from 'react-simple-image-viewer'

const downloadFile = (data) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', 'ProductImport.xlsx')
    document.body.appendChild(link)
    link.click()
    link.remove()
}

const ProductPage = () => {
    const history = useHistory()

    const [category, setCategory] = useState([])

    const [productStatus, setProductStatus] = useState([])

    const [isShowModalProAdd, setIsShowModalProAdd] = useState(false)

    const [modalEditPriceInfo, setModalEditPriceInfo] = useState({
        data: [],
        isShow: false,
        isParent: false,
        productId: 0
    })

    const [groupList, setGroupList] = useState([])

    const [allAttribute, setAllAttribute] = useState([])

    const [productList, setProductList] = useState(null)

    const [filters, setFilters] = useState({
        keyword: '',
        catId: 0,
        statusId: 0,
        typeId: 0,
        pageItem: 10,
        pageIndex: 1,
        parentId: 0
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    const [modalEditQtyInfo, setModalEditQtyInfo] = useState({
        isShow: false,
        productList: []
    })

    const [modalEditInfo, setModalEditInfo] = useState({
        isShow: false,
        data: {}
    })

    const [isShowModalEditQtyExcel, setIsShowModalEditQtyExcel] = useState(false)

    const [parent, setParent] = useState(null)

    const [isShowViewer, setIsShowViewer] = useState(false)

    const [isShowModalEditStatus, setisShowModalEditStatus] = useState(false)

    const [editStatusInfo, setEditStatusInfo] = useState(null)

    const [viewerList, setViewerList] = useState([])

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Sản phẩm'

        loadCategory()
        loadPropductStatus()
        loadPartnerGroup()
        loadAllAttrParentChildren()
    }, [])

    const getParamString = () => {
        const strParam = `keyword=${filters.keyword}&catId=${filters.catId}&statusId=${filters.statusId}&proType=${filters.typeId}&parentId=${filters.parentId}`
        return strParam
    }

    useEffect(() => {
        const strParam = `keyword=${filters.keyword}&catId=${filters.catId}&statusId=${filters.statusId}&proType=${filters.typeId}&parentId=${filters.parentId}`
        const url = `/admin/product/filter?${strParam}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setProductList(data.Result.Data)
            }
        })
    }, [filters])

    const loadCategory = () => {
        const url = '/admin/category/get-dropdown'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setCategory(data.Result)
            }
        })
    }

    const loadPropductStatus = () => {
        const url = '/admin/product-status/get-all'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setProductStatus(data.Result)
            }
        })
    }

    const loadPartnerGroup = () => {
        const url = '/admin/partner-group/get-all'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setGroupList(data.Result)
            }
        })
    }

    const loadAllAttrParentChildren = () => {
        const url = '/admin/attribute/get-all-parent-children'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setAllAttribute(data.Result)
            }
        })
    }

    const reloadProduct = (isKeepCurrent = false) => {
        const newFilters = { ...filters }
        if (!isKeepCurrent) {
            newFilters.pageIndex = 1
        }
        setFilters(newFilters)
    }

    const renderParent = () => {
        if (parent !== null) {
            return (
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="alert alert-primary alert-dismissible fade show mb-0">
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setParent(null)
                                    const newFilters = {
                                        ...filters,
                                        pageIndex: 1,
                                        parentId: 0
                                    }
                                    setFilters(newFilters)
                                }}
                            ></button>
                            <strong>Sản phẩm cha: </strong> {parent.ProductName}
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <Fragment>
            <Filter
                category={category}
                productStatus={productStatus}
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
            />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="button-list mb-2">
                                <AuthAdminView right="product_add">
                                    <IconButton
                                        text="Thêm mới"
                                        icon={<IoAddCircleOutline />}
                                        onClick={() => {
                                            setIsShowModalProAdd(true)
                                        }} />
                                    <IconButton
                                        variant='info'
                                        text="Thêm từ file excel"
                                        icon={<IoAddCircleOutline />}
                                        onClick={() => {
                                            history.push('/admin/product/create-from-excel')
                                        }} />
                                </AuthAdminView>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="button-list mb-2 text-end">
                                <AuthAdminView right="product_edit">
                                    <IconButton
                                        variant='success'
                                        text="Cập nhật số lượng"
                                        icon={<IoCloudUploadOutline />}
                                        onClick={() => {
                                            setIsShowModalEditQtyExcel(true)
                                        }}
                                    />
                                </AuthAdminView>
                                <AuthAdminView right="product_export">
                                    <Dropdown className="display-inline-block">
                                        <Dropdown.Toggle variant="danger">
                                            <SiMicrosoftexcel className="font-17 mt--2-px me-1" />
                                            Xuất excel
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                const strParam = getParamString()
                                                const url = `/admin/product/export?${strParam}&pageItem=10&pageIndex=1`
                                                axios.get(url, { responseType: 'blob' }).then(({ data }) => {
                                                    downloadFile(data)
                                                })
                                            }}>Mặc định trang hiện tại</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                const strParam = getParamString()
                                                const url = `/admin/product/export?${strParam}&pageItem=10&pageIndex=0`
                                                axios.get(url, { responseType: 'blob' }).then(({ data }) => {
                                                    downloadFile(data)
                                                })
                                            }}>Mặc định tất cả các trang</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                const strParam = getParamString()
                                                const url = `/admin/product/export-quantity-update?${strParam}&pageItem=10&pageIndex=1`
                                                axios.get(url, { responseType: 'blob' }).then(({ data }) => {
                                                    downloadFile(data)
                                                })
                                            }}>Cập nhật số lượng trang hiện tại</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                const strParam = getParamString()
                                                const url = `/admin/product/export-quantity-update?${strParam}&pageItem=10&pageIndex=0`
                                                axios.get(url, { responseType: 'blob' }).then(({ data }) => {
                                                    downloadFile(data)
                                                })
                                            }}>Cập nhật số lượng tất cả các trang</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </AuthAdminView>
                            </div>
                        </div>
                    </div>
                    {renderParent()}
                    <Table
                        pageItem={filters.pageItem}
                        pageIndex={filters.pageIndex}
                        data={productList}
                        onEdit={item => {
                            const url = `/admin/product/get-by-id?id=${item.ProductId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setModalEditInfo({
                                        isShow: true,
                                        data: data.Result
                                    })
                                }
                            })
                        }}
                        onEditPrice={item => {
                            const url = `/admin/product/get-price?id=${item.ProductId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setModalEditPriceInfo({
                                        data: data.Result,
                                        isShow: true,
                                        isParent: item.IsParent,
                                        productId: item.ProductId
                                    })
                                }
                            })
                        }}
                        onEditQty={item => {
                            let url = ''
                            if (item.IsParent) {
                                url = `/admin/product/get-children?parentId=${item.ProductId}`
                            }
                            else {
                                url = `/admin/product/get-detail?id=${item.ProductId}`
                            }
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setModalEditQtyInfo({
                                        isShow: true,
                                        productList: (item.IsParent ? data.Result : [data.Result])
                                    })
                                }
                            })
                        }}
                        onDelete={item => {
                            Notification.confirmHtml('Bạn có chắc muốn xóa sản phẩm', item.ProductName, () => {
                                const url = `/admin/product/delete?id=${item.ProductId}`
                                axios.post(url).then(({ data }) => {
                                    if (data.IsSuccess) {
                                        reloadProduct()
                                        Notification.success('Xóa sản phẩm thành công')
                                    }
                                })
                            })
                        }}
                        onParentClick={(item) => {
                            setParent(item)
                            const newFilter = {
                                ...filters,
                                parentId: item.ProductId,
                                pageIndex: 1
                            }
                            setFilters(newFilter)
                        }}
                        onAvatarClick={(urlObj) => {
                            setIsShowViewer(true)
                            setViewerList([urlObj.fullUrl])
                        }}
                        onEditStatus={product => {
                            setEditStatusInfo({
                                productId: product.ProductId,
                                statusId: product.ProductStatusId,
                                isParent: product.IsParent
                            })
                            setisShowModalEditStatus(true)
                        }}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>
            <ModalAdd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/product/create'
                    axios.post(url, formData).then(res => {
                        const data = res.data
                        if (data.IsSuccess) {
                            reloadProduct()
                            setIsShowModalProAdd(false)
                            Notification.success('Thêm mới sản phẩm thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalProAdd(false)
                }}
                isShow={isShowModalProAdd}
                category={category}
                productStatus={productStatus}
                groupList={groupList}
                allAttribute={allAttribute}
            />
            <ModalEditPrice
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/product/update-price'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            setModalEditPriceInfo({
                                data: [],
                                isShow: false
                            })
                            Notification.success('Chỉnh sửa giá theo nhóm cộng tác viên thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setModalEditPriceInfo({
                        data: [],
                        isShow: false
                    })
                }}
                isShow={modalEditPriceInfo.isShow}
                data={modalEditPriceInfo.data}
                isParent={modalEditPriceInfo.isParent}
                productId={modalEditPriceInfo.productId}
            />
            <ModalEditQty
                onSave={(formData, setIsBusy) => {
                    const url = '/admin/product/update-quantity'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadProduct(true)
                            setModalEditQtyInfo({
                                isShow: false,
                                productList: []
                            })
                            Notification.success('Cập nhật số lượng sản phẩm thành công')
                        }
                    })
                }}
                onClose={() => {
                    setModalEditQtyInfo({
                        isShow: false,
                        productList: []
                    })
                }}
                isShow={modalEditQtyInfo.isShow}
                productList={modalEditQtyInfo.productList}
            />
            <ModalEdit
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/product/update'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadProduct(true)
                            setModalEditInfo({
                                isShow: false,
                                date: {}
                            })
                            Notification.success('Chỉnh sửa sản phẩm thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setModalEditInfo({
                        isShow: false,
                        data: {}
                    })
                }}
                isShow={modalEditInfo.isShow}
                productStatus={productStatus}
                category={category}
                info={modalEditInfo.data}
            />
            <ModalEditQtyExcel
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const products = formData.map(item => {
                        return {
                            ProductId: item.ID,
                            Quantity: item.SO_LUONG
                        }
                    })
                    const url = '/admin/product/import-quantity'
                    axios.post(url, {
                        ProductList: products
                    }).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadProduct(true)
                            setIsShowModalEditQtyExcel(false)
                            Notification.success('Cập nhật số lượng sản phẩm bằng file excel thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalEditQtyExcel(false)
                }}
                isShow={isShowModalEditQtyExcel}
            />
            <ModalEditStatus
                productId={editStatusInfo?.productId}
                statusId={editStatusInfo?.statusId}
                isParent={editStatusInfo?.isParent}
                statuses={productStatus} onSave={(params, setIsBusy) => {
                    let url = `/admin/product/update-status?productId=${params.productId}&statusId=${params.statusId}&isParent=${params.isParent}`
                    if (params.isParent) {
                        url += `&isAllowForChildrent=${params.isForAllChildrent}`
                    }
                    axios.post(url).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadProduct(true)
                            Notification.success('Cập nhật trạng thái sản phẩm thành công')
                            setisShowModalEditStatus(false)
                            setEditStatusInfo(null)
                        }
                    })
                }} onClose={() => {
                    setisShowModalEditStatus(false)
                    setEditStatusInfo(null)
                }} isShow={isShowModalEditStatus} />
            {isShowViewer && (
                <ImageViewer
                    src={viewerList}
                    currentIndex={0}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={() => {
                        setIsShowViewer(false)
                    }}
                />
            )}
        </Fragment>
    )
}

export default ProductPage