import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  CloseButton,
  InputSelect,
  SaveButton,
} from "./../../../../../components";

const schema = yup.object().shape({
  orderId: yup.number(),
  statusId: yup.number(),
});

const ModalEditStatus = (props) => {
  const { setValue, control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [isBusy, setIsBusy] = useState(false);

  const { statuses } = props;

  const [productStatuses, setProductStatuses] = useState([]);

  useEffect(() => {
    const statusList = statuses.map((item) => {
      return {
        value: item.ProductStatusId,
        label: item.ProductStatusName,
      };
    });
    setProductStatuses(statusList);
  }, [statuses]);

  return (
    <Modal
      show={props.isShow}
      onHide={props.onClose}
      backdrop="static"
      onExited={() => {
        reset();
      }}
      onShow={() => {
        setValue("productId", props.productId);
        setValue("statusId", props.statusId);
        setValue("isParent", props.isParent);
        setValue("isForAllChildrent", true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Sửa trạng thái sản phẩm</Modal.Title>
      </Modal.Header>
      <form
        onSubmit={handleSubmit((formData) => {
          props.onSave(formData, setIsBusy);
        })}
      >
        <Modal.Body>
          <Controller
            name="statusId"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <InputSelect
                  isVertical={true}
                  options={productStatuses}
                  value={value}
                  defaultValue={value}
                  name="product-status"
                  variant="success"
                  onChange={onChange}
                />
              );
            }}
          />
          {props.isParent && (
            <div className="text-center mt-2">
              <div className="form-check form-checkbox-success display-inline-block">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="edit-status"
                  defaultChecked={true}
                  onChange={(e) => {
                    setValue("isForAllChildrent", e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="edit-price">
                  Áp dụng cho tất cả các sản phẩm con
                </label>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <SaveButton isBusy={isBusy} />
          <CloseButton onClick={props.onClose} />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

ModalEditStatus.propTypes = {
  statuses: PropTypes.array,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  isShow: PropTypes.bool,
  statusId: PropTypes.number,
  productId: PropTypes.number,
  isParent: PropTypes.bool
};

export default ModalEditStatus;
