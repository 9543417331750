import React, { Fragment } from "react";
import PropTypes from "prop-types";

const renderStatus = (statusId, className, onClick) => {
  if (statusId === 1)
    return (
      <span className={`badge bg-success ` + className} onClick={onClick}>
        Đang bán
      </span>
    );
  else if (statusId === 2)
    return (
      <span className={`badge bg-danger ` + className} onClick={onClick}>
        Ngừng bán
      </span>
    );
  else
    return (
      <span className={`badge bg-warning ` + className} onClick={onClick}>
        Hết hàng
      </span>
    );
};

function ProductStatus(props) {
  const { statusId, onClick, className } = props;

  return <Fragment>{renderStatus(statusId, className, onClick)}</Fragment>;
}

ProductStatus.propTypes = {
  statusId: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

ProductStatus.defaultProps = {
  statusId: 0,
  onClick: () => {},
  className: "",
};

export default ProductStatus;
