import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { IoCloudDownloadOutline, IoSaveOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import {
    CloseButton, EmptyRow, FormatNumber, IconButton, LoadingRow, Notification, ProductStatus, ReadExcelButton, UploadProductImg
} from './../../../components'

const downloadFile = (data) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', 'ProductExport.xlsx')
    document.body.appendChild(link)
    link.click()
    link.remove()
}

function renderGroupPrice(groups) {
    return groups.map((item, index) => {
        const mbClass = groups.length - 1 === index ? 'mb-0' : 'mb-1'
        return (
            <p key={item.GroupId} className={mbClass}>{item.GroupName} <span className="text-primary float-end"><FormatNumber>{item.UnitPrice}</FormatNumber></span></p>
        )
    })
}

function ProductFromExcel(props) {
    const history = useHistory()

    const [products, setProducts] = useState([])

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Thêm mới sản phẩm từ file excel'
    }, [])

    function onUploadComplete(product, imgInfo) {
        if (imgInfo.IsSuccess) {
            const imgUrl = imgInfo.Result.ImageUrl
            if (product.IsParent) {
                const newProducts = [...products]
                const parentIndex = newProducts.findIndex(n => n.Id === product.Id)
                newProducts[parentIndex].AvatarUrl = imgUrl

                const children = newProducts.filter(n => n.ParentId === product.Id)
                children.forEach(child => {
                    const childIndex = newProducts.findIndex(n => n.Id === child.Id)
                    newProducts[childIndex].AvatarUrl = imgUrl
                })
                setProducts(newProducts)
            }
            else {
                const newProducts = [...products]
                const index = newProducts.findIndex(n => n.Id === product.Id)
                newProducts[index].AvatarUrl = imgUrl
                setProducts(newProducts)
            }
        }
    }

    function renderTable() {
        if (products === null) return <LoadingRow colSpan={6} />
        else if (products.length === 0) return <EmptyRow colSpan={6} />
        else return products.map((item, index) => <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td>{item.ProductCode}</td>
            <td>{item.SupplierProductCode}</td>
            <td>
                <div className="d-flex flex-row align-items-center">
                    <UploadProductImg
                        imgUrl={item.AvatarUrl}
                        onCompleted={(data) => onUploadComplete(item, data)}
                    />
                    <div className="d-flex flex-column flex-fill">
                        <p className={item.IsParent ? 'mb-1 text-primary' : 'mb-1'}>{item.ProductName}</p>
                        <p className="mb-0 d-block">
                            Bán lẻ: <span className="text-primary"><FormatNumber>{item.OutputPrice}</FormatNumber></span>
                            <span className="float-end">Danh mục: <span className="text-success">{item.CategoryName}</span></span>
                        </p>
                    </div>

                </div>
            </td>
            <td>
                {renderGroupPrice(item.PartnerGroupPrices)}
            </td>
            <td>
                <ProductStatus
                    statusId={item.StatusId}
                />
            </td>
        </tr>)
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="button-list">
                        <IconButton
                            icon={<IoCloudDownloadOutline />}
                            variant="info"
                            text="Tải file mẫu"
                            onClick={(setIsBusy) => {
                                setIsBusy(true)
                                const url = '/admin/product/import-template'
                                axios.get(url, { responseType: 'blob' }).then(({ data }) => {
                                    downloadFile(data)
                                    setIsBusy(false)
                                })
                            }}
                        />
                        <ReadExcelButton
                            variant="success"
                            onCompleted={(data) => {
                                if (data.IsSuccess) {
                                    const url = '/admin/product/generate-import'
                                    axios.post(url, {
                                        Json: JSON.stringify(data.Result)
                                    }).then(res => {
                                        if (res.data.IsSuccess) {
                                            setProducts(res.data.Result)
                                        }
                                    })
                                }
                            }}
                        />
                        <IconButton
                            icon={<IoSaveOutline />}
                            text="Lưu"
                            onClick={(setIsBusy) => {
                                if (products.length === 0) {
                                    Notification.warning('Vui lòng chọn file excel (.xlsx) có chứa dữ liệu theo mẫu')
                                    return
                                }
                                Notification.confirm('Bạn có chắc chắn muốn thêm sản phẩm không?', () => {
                                    setIsBusy(true)
                                    const formData = products.filter(n => n.IsParent).map(item => {
                                        return {
                                            ProductName: item.ProductName,
                                            ProductCode: item.ProductCode,
                                            SupplierProductCode: item.SupplierProductCode,
                                            CategoryId: item.CategoryId,
                                            InputPrice: 0,
                                            OutputPrice: item.OutputPrice,
                                            StatusId: item.StatusId,
                                            Detail: item.Detail,
                                            AvatarUrl: item.AvatarUrl,
                                            ProductPrices: item.PartnerGroupPrices.map(group => {
                                                return {
                                                    GroupId: group.GroupId,
                                                    UnitPrice: group.UnitPrice
                                                }
                                            }),
                                            SubProducts: products.filter(n => !n.IsParent).map(n => {
                                                return {
                                                    ChildrentAttributes: n.AttributeList,
                                                    ExtensionName: n.ExtensionName,
                                                    Quantity: 0
                                                }
                                            }),
                                            ProductChildrentAttribute: item.AttributeList
                                        }
                                    })
                                    const url = '/admin/product/import-create'
                                    axios.post(url, {
                                        ProductList: formData
                                    }).then(({ data }) => {
                                        if (data.IsSuccess) {
                                            Notification.success('Thêm mới sản phẩm từ file excel thành công')
                                            history.replace('/admin/product')
                                        }
                                        setIsBusy(false)
                                    })
                                })
                            }}
                        />
                        <CloseButton
                            onClick={() => {
                                history.replace('/admin/product')
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-centered table-avatar mb-0">
                            <thead>
                                <tr>
                                    <th className="w-52-px">#</th>
                                    <th>Mã SP</th>
                                    <th>Mã SP NCC</th>
                                    <th>Tên sản phẩm</th>
                                    <th>Giá theo nhóm CTV</th>
                                    <th>Trạng thái</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ProductFromExcel.propTypes = {

}

ProductFromExcel.defaultProps = {

}

export default ProductFromExcel