import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { LoadingRow, EmptyRow, FormatNumber, AvatarRow } from '../../../../../components'
import { IoEllipsisHorizontalSharp, IoEllipsisVertical, IoTrashOutline } from 'react-icons/io5'

const Table = (props) => {
    const renderTable = () => {
        const data = props.data
        if (data === null) return <LoadingRow colSpan={10} />
        else if (data.length === 0) return <EmptyRow colSpan={10} />
        else return data.map((item, index) => {
            const rowSpan = item.Products.length
            const firstProduct = item.Products[0]
            return (
                <Fragment key={item.OrderId}>
                    <tr>
                        <td rowSpan={rowSpan} className="text-center">
                            <FormatNumber>
                                {(props.pageItem * (props.pageIndex - 1)) + index + 1}
                            </FormatNumber>
                        </td>
                        <td rowSpan={rowSpan}>
                            <span className="fw-bold text-success">{item.OrderId}</span>
                            <br />
                            <span>{item.CreatedDate}</span>
                            <br />
                            <small className="text-muted">{item.CreatedTime}</small>
                        </td>
                        <td rowSpan={rowSpan}>
                            <p className="mb-1">{item.Phone}</p>
                            {renderAddress(item.Address, item.WardName, item.DistrictName, item.ProvinceNam, item.WardPrefix, item.DistrictPrefix, item.ProvincePrefix)}
                        </td>
                        <td>
                            <p className="mb-1">
                                <AvatarRow
                                    url={firstProduct.AvatarUrl}
                                />
                                {firstProduct.ProductName}
                            </p>
                            <p className="mb-0">
                                <span>Giá: <span className="fw-bold text-primary">
                                    <FormatNumber>
                                        {firstProduct.CustomerUnitPrice}
                                    </FormatNumber>
                                </span></span>
                                <span className="float-end">Số lượng: <span className="fw-bold text-primary">
                                    <FormatNumber>
                                        {firstProduct.Quantity}
                                    </FormatNumber>
                                </span></span>
                            </p>
                        </td>
                        <td rowSpan={rowSpan} className="text-end text-primary">
                            <FormatNumber>
                                {item.SumOutputPrice}
                            </FormatNumber>
                        </td>
                        <td rowSpan={rowSpan}>
                            <p className="mb-1">{item.WaybillCode}</p>
                            <p className="mb-1">{item.ShippingUnitName}</p>
                        </td>
                        <td rowSpan={rowSpan} className="text-end">
                            <p className="mb-1 text-primary fw-bold">
                                <FormatNumber>
                                    {item.SumPartnerPrice}
                                </FormatNumber>
                            </p>
                            <p className="mb-0 text-success">
                                +
                                <FormatNumber>
                                    {item.PartnerDropShipFee}
                                </FormatNumber>
                            </p>
                        </td>
                        <td rowSpan={rowSpan} className="text-center text-primary">
                            <IoEllipsisHorizontalSharp className="cursor-point fs-4" onClick={() => props.onEditWarehouseNote(item)} />
                        </td>
                        <td rowSpan={rowSpan}>
                            {renderStatus(item.OrderStatusId, item.OrderStatusName)}
                        </td>
                        <td rowSpan={rowSpan}>
                            <div className="dropdown float-end">
                                <button className="dropdown-toggle arrow-none card-drop btn-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                    <IoEllipsisVertical className="fs-4 text-primary" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <button onClick={() => props.onDelete(item)} className="dropdown-item text-danger"><IoTrashOutline className="mn-btn-icon" />Hủy đơn</button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    {renderProduct(item.Products)}
                </Fragment>
            )
        })
    }

    const renderAddress = (address, wardName, districtName, provinceName, wardPrefix, districtPrefix, provincePrefix) => {
        if (address !== '' && wardName !== null)
            return <p>{address !== '' ? `${address}, ` : ''}{wardPrefix} {wardName}, {districtPrefix} {districtName}, {provincePrefix} {provinceName}</p>
    }

    const renderStatus = (statusId, statusName) => {
        if (statusId === 1) return <span className="badge bg-success min-w-50-px">{statusName}</span>
        else return <span className="badge bg-warning min-w-50-px">{statusName}</span>
    }

    const renderProduct = (products) => {
        if (products.length === 1) return []
        const html = []
        const countProduct = products.length
        for (let i = 1; i < countProduct; i++) {
            const item = products[i]
            html.push(<tr key={item.ProductId}>
                <td>
                    <p className="mb-1">
                        <AvatarRow
                            url={item.AvatarUrl}
                        />
                        {item.ProductName}
                    </p>
                    <p className="mb-0">
                        <span>Giá: <span className="fw-bold text-primary">
                            <FormatNumber>
                                {item.CustomerUnitPrice}
                            </FormatNumber>
                        </span></span>
                        <span className="float-end">Số lượng: <span className="fw-bold text-primary">
                            <FormatNumber>
                                {item.Quantity}
                            </FormatNumber>
                        </span></span>
                    </p>
                </td>
            </tr>)
        }
        return html
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered table-avatar mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>ID</th>
                        <th>Khách hàng</th>
                        <th>Tên sản phẩm</th>
                        <th className='text-end'>Tổng</th>
                        <th>Vận chuyển</th>
                        <th className='text-end'>Trả NCC</th>
                        <th>Ghi chú</th>
                        <th>Trạng thái</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
    onDelete: PropTypes.func,
    onEditWarehouseNote: PropTypes.func
}

Table.defaultProps = {
    data: [],
    pageItem: 0,
    pageIndex: 0,
    onDelete: () => { },
    onEditWarehouseNote: () => { }
}

export default Table