import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { ClearButton, SearchButton } from './../../../../../components'

const filters = {
    fromDate: '',
    toDate: ''
}

const Filter = (props) => {
    const { onSearch } = props

    const [fromDate, setFromDate] = useState()

    const [toDate, setToDate] = useState()

    const handleSearch = () => {
        onSearch(filters)
    }

    const handleClear = () => {
        setFromDate(null)
        filters.fromDate = ''
        setToDate(null)
        filters.toDate = ''
        onSearch(filters)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text">Từ ngày</span>
                            <DatePicker
                                className="form-control no-border-radius-top-left no-border-radius-bottom-left"
                                placeholderText="dd/mm/yyyy"
                                dateFormat="dd/MM/yyyy"
                                selected={fromDate}
                                onChange={date => {
                                    if (date !== null) {
                                        const day = date.getDate()
                                        const month = date.getMonth() + 1
                                        const year = date.getFullYear()
                                        const strDate = `${day}/${month}/${year}`
                                        filters.fromDate = strDate
                                    }
                                    setFromDate(date)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text">Đến ngày</span>
                            <DatePicker
                                className="form-control no-border-radius-top-left no-border-radius-bottom-left"
                                placeholderText="dd/mm/yyyy"
                                dateFormat="dd/MM/yyyy"
                                selected={toDate}
                                onChange={date => {
                                    if (date !== null) {
                                        const day = date.getDate()
                                        const month = date.getMonth() + 1
                                        const year = date.getFullYear()
                                        const strDate = `${day}/${month}/${year}`
                                        filters.toDate = strDate
                                    }
                                    setToDate(date)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                            <ClearButton onClick={handleClear} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func
}

Filter.defaultProps = {
    onSearch: () => { }
}

export default Filter