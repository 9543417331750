import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import * as yup from "yup"
import { SaveButton, UploadImg } from './../../../../../components'

const schema = yup.object().shape({
    FullName: yup.string().required('Vui lòng nhập họ tên').max(100, 'Họ tên quá dài'),
    ShopName: yup.string().max(100, 'Tên shop quá dài'),
    Phone: yup.string().required('Vui lòng nhập số điện thoại').max(20, 'Số điện thoại quá dài'),
    Address: yup.string().max(200, 'Địa chỉ quá dài'),
    WardObj: yup.object().nullable(),
    WardId: yup.number().nullable(),
    AvatarUrl: yup.string().max(500, 'Đường dẫn ảnh quá dài')
})

function Profile(props) {
    const { provinces, districts, wards, partnerInfo, onSave, onProvinceChange, onDistrictChange } = props

    const { control, register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const [provinceOptions, setProvinceOptions] = useState([])

    const [province, setProvince] = useState()

    const [districtOptions, setDistrictOptions] = useState([])

    const [district, setDistrict] = useState()

    const [wardOptions, setWardOptions] = useState([])

    useEffect(() => {
        const provinceArray = provinces.map(item => {
            return {
                value: item.ProvinceId,
                label: `${item.Prefix} ${item.ProvinceName}`
            }
        })
        setProvinceOptions(provinceArray)
    }, [provinces])

    useEffect(() => {
        const districtArray = districts.map(item => {
            return {
                value: item.DistrictId,
                label: `${item.Prefix} ${item.DistrictName}`
            }
        })
        setDistrictOptions(districtArray)
    }, [districts])

    useEffect(() => {
        const wardArray = wards.map(item => {
            return {
                value: item.WardId,
                label: `${item.Prefix} ${item.WardName}`
            }
        })
        setWardOptions(wardArray)
    }, [wards])

    useEffect(() => {
        if (partnerInfo != null) {
            setValue('FullName', partnerInfo.FullName)
            setValue('ShopName', partnerInfo.ShopName)
            setValue('Phone', partnerInfo.Phone)
            setValue('Address', partnerInfo.Address)
            setValue('AvatarUrl', partnerInfo.AvatarUrl)
            const wardArray = wards.map(item => {
                return {
                    value: item.WardId,
                    label: `${item.Prefix} ${item.WardName}`
                }
            })
            setWardOptions(wardArray)
            if (partnerInfo.WardId !== 0) {
                const partnerWard = wards.filter(n => n.WardId === partnerInfo.WardId).map(item => {
                    return {
                        value: item.WardId,
                        label: `${item.Prefix} ${item.WardName}`
                    }
                })[0]
                setValue('WardObj', partnerWard)
                setValue('WardId', partnerInfo.WardId)

                const partnerDistrict = districts.filter(n => n.DistrictId === partnerInfo.DistrictId).map(item => {
                    return {
                        value: partnerInfo.DistrictId,
                        label: `${item.Prefix} ${item.DistrictName}`
                    }
                })[0]
                setDistrict(partnerDistrict)

                const partnerProvince = provinces.filter(n => n.ProvinceId === partnerInfo.ProvinceId).map(item => {
                    return {
                        value: item.ProvinceId,
                        label: `${item.Prefix} ${item.ProvinceName}`
                    }
                })[0]
                setProvince(partnerProvince)
            }
        }
        // eslint-disable-next-line
    }, [partnerInfo])

    return (
        <div className="card">
            <form onSubmit={handleSubmit(formData => {
                delete formData['WardObj']
                onSave(formData, setIsBusy)
            })}>
                <div className="card-body">
                    <h5 className="card-title mb-2">Chỉnh sửa thông tin</h5>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-3">
                            <Controller
                                control={control}
                                name="AvatarUrl"
                                render={({
                                    field: { onChange, value }
                                }) => (
                                    <UploadImg
                                        imgUrl={value}
                                        onCompleted={data => {
                                            onChange(data.Result.ImageUrl)
                                        }}
                                        isPartner={true}
                                    />
                                )}
                            />
                            {errors.AvatarUrl && <div className="validation-message">{errors.AvatarUrl.message}</div>}
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-9">
                            <div className="relative-position mb-2">
                                <label className="form-label">Họ tên <span className="text-danger">*</span></label>
                                <input {...register('FullName')} type="text" className="form-control" placeholder="Họ tên" />
                                {errors.FullName && <div className="validation-message">{errors.FullName.message}</div>}
                            </div>
                            <div className="relative-position mb-2">
                                <label className="form-label">Tên shop</label>
                                <input {...register('ShopName')} type="text" className="form-control" placeholder="Tên shop" />
                                {errors.ShopName && <div className="validation-message">{errors.ShopName.message}</div>}
                            </div>
                            <div className="relative-position mb-2">
                                <label className="form-label">Số điện thoại <span className="text-danger">*</span></label>
                                <input {...register('Phone')} type="text" className="form-control" placeholder="Số điện thoại" />
                                {errors.Phone && <div className="validation-message">{errors.Phone.message}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tỉnh/Tp</label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={true}
                            isClearable={true}
                            options={provinceOptions}
                            value={province}
                            onChange={item => {
                                setProvince(item)
                                setDistrict(null)
                                setValue('WardId', null)
                                setValue('WardObj', null)
                                onProvinceChange(item !== null ? item.value : 0)
                            }}
                            placeholder='Tỉnh/Tp'
                        />
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Quận/Huyện</label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={true}
                            isClearable={false}
                            options={districtOptions}
                            value={district}
                            onChange={item => {
                                setDistrict(item)
                                setValue('WardId', null)
                                setValue('WardObj', null)
                                onDistrictChange(item.value)
                            }}
                            placeholder='Quận/Huyện'
                            noOptionsMessage={() => 'Quận/Huyện'}
                        />
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Phường/Xã</label>
                        <Controller
                            control={control}
                            name="WardObj"
                            render={({
                                field: { onChange, value }
                            }) => (
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="custom-select"
                                    isSearchable={true}
                                    isClearable={false}
                                    options={wardOptions}
                                    value={value}
                                    onChange={item => {
                                        if (item !== null) {
                                            setValue('WardId', item.value)
                                        }
                                        onChange(item)
                                    }}
                                    placeholder='Phường/Xã'
                                    noOptionsMessage={() => 'Phường/Xã'}
                                />
                            )}
                        />
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Địa chỉ</label>
                        <input {...register('Address')} type="text" className="form-control" placeholder="Địa chỉ" />
                        {errors.Address && <div className="validation-message">{errors.Address.message}</div>}
                    </div>
                </div>
                <div className="card-footer">
                    <SaveButton isBusy={isBusy} />
                </div>
            </form>
        </div>
    )
}

Profile.propTypes = {
    partnerInfo: PropTypes.object,
    onSave: PropTypes.func,
    provinces: PropTypes.array,
    onProvinceChange: PropTypes.func,
    districts: PropTypes.array,
    onDistrictChange: PropTypes.func,
    wards: PropTypes.array
}

Profile.defaultProps = {
    partnerInfo: {},
    onSave: () => { },
    provinces: [],
    onProvinceChange: () => { },
    districts: [],
    onDistrictChange: () => { },
    wards: []
}

export default Profile