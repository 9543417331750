import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { ClearButton, SearchButton } from './../../../../../components'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import axios from 'axios'
import { debounce } from 'lodash'

const filters = {
    orderId: '',
    partnerId: 0,
    wayBillCode: '',
    fromDate: '',
    toDate: '',
    statusId: 0,
    orderFromId: 0
}

const loadOptions = (inputValue, callback) => {
    const url = `/admin/partner/dropdown-filter?keyword=${inputValue}&pageItem=5&pageIndex=1`
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            let partnerLst = data.Result.Data.map(item => {
                return {
                    value: item.PartnerId,
                    label: item.FullName + ` (${item.Email})`
                }
            })
            callback(partnerLst)
        }
    })
}

const Filter = (props) => {
    const { onSearch, statuses, orderFroms } = props

    const [orderId, setOrderId] = useState('')

    const [partner, setPartner] = useState(null)

    const [wayBillCode, setWayBillCode] = useState('')

    const [fromDate, setFromDate] = useState()

    const [toDate, setToDate] = useState()

    const [statusOptions, setStatusOptions] = useState([])

    const [status, setStatus] = useState(null)

    const [orderFromOptions, setOrderFromOptions] = useState([])

    const [orderFrom, setOrderFrom] = useState(null)

    useEffect(() => {
        const statusList = statuses.map(item => {
            return {
                value: item.OrderStatusId,
                label: item.OrderStatusName
            }
        })
        setStatusOptions(statusList)
    }, [statuses])

    useEffect(() => {
        const orderFromList = orderFroms.map(item => {
            return {
                value: item.OrderFromId,
                label: item.OrderFromName
            }
        })
        setOrderFromOptions(orderFromList)
    }, [orderFroms])

    const handleSearch = () => {
        onSearch(filters)
    }

    const handleClear = () => {
        setOrderId('')
        filters.orderId = ''
        setPartner(null)
        filters.partnerId = 0
        setWayBillCode('')
        filters.wayBillCode = ''
        setFromDate(null)
        filters.fromDate = ''
        setToDate(null)
        filters.toDate = ''
        setStatus(null)
        filters.statusId = 0
        setOrderFrom(null)
        filters.orderFromId = 0
        onSearch(filters)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-2">
                        <input value={orderId}
                            autoFocus
                            onChange={e => {
                                const value = e.target.value
                                setOrderId(value)
                                filters.orderId = value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                            type="text" className="form-control" autoComplete="off" placeholder="ID đơn hàng" />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-2">
                        <input value={wayBillCode}
                            onChange={e => {
                                const value = e.target.value
                                setWayBillCode(value)
                                filters.wayBillCode = value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                            type="text" className="form-control" autoComplete="off" placeholder="Mã vận đơn" />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4">
                        <AsyncSelect
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            placeholder='Cộng tác viên'
                            cacheOptions
                            defaultOptions
                            isSearchable={true}
                            isClearable={true}
                            value={partner}
                            loadOptions={debounce(loadOptions, 250)}
                            onChange={item => {
                                if (item !== null) {
                                    filters.partnerId = item.value
                                    setPartner(item)
                                }
                                else {
                                    setPartner(null)
                                    filters.partnerId = 0
                                }
                            }}
                            loadingMessage={() => "Đang tìm kiếm"}
                            noOptionsMessage={() => "Không tìm thấy dữ liệu"}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text">Từ ngày</span>
                            <DatePicker
                                className="form-control no-border-radius-top-left no-border-radius-bottom-left"
                                placeholderText="dd/mm/yyyy"
                                dateFormat="dd/MM/yyyy"
                                selected={fromDate}
                                onChange={date => {
                                    if (date !== null) {
                                        const day = date.getDate()
                                        const month = date.getMonth() + 1
                                        const year = date.getFullYear()
                                        const strDate = `${day}/${month}/${year}`
                                        filters.fromDate = strDate
                                    }
                                    setFromDate(date)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text">Đến ngày</span>
                            <DatePicker
                                className="form-control no-border-radius-top-left no-border-radius-bottom-left"
                                placeholderText="dd/mm/yyyy"
                                dateFormat="dd/MM/yyyy"
                                selected={toDate}
                                onChange={date => {
                                    if (date !== null) {
                                        const day = date.getDate()
                                        const month = date.getMonth() + 1
                                        const year = date.getFullYear()
                                        const strDate = `${day}/${month}/${year}`
                                        filters.toDate = strDate
                                    }
                                    setToDate(date)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            options={statusOptions}
                            value={status}
                            onChange={item => {
                                if (item !== null) {
                                    setStatus(item)
                                    filters.statusId = item.value
                                }
                                else {
                                    setStatus(null)
                                    filters.statusId = 0
                                }
                            }}
                            placeholder='Trạng thái'
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            options={orderFromOptions}
                            value={orderFrom}
                            onChange={item => {
                                if (item !== null) {
                                    setOrderFrom(item)
                                    filters.orderFromId = item.value
                                }
                                else {
                                    setOrderFrom(null)
                                    filters.orderFromId = 0
                                }
                            }}
                            placeholder='Đơn nguồn'
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                            <ClearButton onClick={handleClear} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func,
    statuses: PropTypes.array,
    orderFrom: PropTypes.array
}

Filter.defaultProps = {
    onSearch: () => { },
    statuses: [],
    orderFroms: []
}

export default Filter