import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { EmptyRow, LoadingRow, FormatNumber, AvatarRow, DetailRow } from '../../../../../components'

function Table(props) {
    const { data, onOpenDetail, detail } = props

    function renderTable() {
        if (data === null) return <LoadingRow colSpan={6} />
        else if (data.length === 0) return <EmptyRow colSpan={6} />
        else return data.map((item, index) => {
            return (
                <Fragment key={item.PartnerId}>
                    <tr onClick={() => onOpenDetail(item)} className={(item.isSelected ? 'row-master' : '')}>
                        <td className="text-center">{(props.pageItem * (props.pageIndex - 1)) + index + 1}</td>
                        <td>
                            <AvatarRow
                                url={item.AvatarUrl}
                            />
                            {item.FullName}
                        </td>
                        <td>{item.Email}</td>
                        <td>{item.Phone}</td>
                        <td className="text-end text-primary">
                            <FormatNumber>
                                {item.CountOrder}
                            </FormatNumber>
                        </td>
                        <td className="text-end text-primary">
                            <FormatNumber>
                                {item.TotalMoney}
                            </FormatNumber>
                        </td>
                    </tr>
                    {rederDetail(item)}
                </Fragment>
            )
        })
    }

    function rederDetail(item) {
        if (item.isSelected) {
            return (
                <tr className="row-detail">
                    <td colSpan={6} className="ps-1 pt-0 pe-1 pb-1">
                        <div className="card mb-0 cursor-default">
                            <div className="card-body">
                                <div className="row">
                                    <DetailRow title="Tổng đơn">
                                        <span className="text-primary">
                                            <FormatNumber>
                                                {detail.CountOrder}
                                            </FormatNumber>
                                        </span>
                                    </DetailRow>
                                    <DetailRow title="Đơn hủy">
                                        <span className="text-primary">
                                            <FormatNumber>
                                                {detail.CountCancel}
                                            </FormatNumber>
                                        </span>
                                    </DetailRow>
                                    <DetailRow title="Doanh thu">
                                        <span className="text-primary">
                                            <FormatNumber>
                                                {detail.TotalMoney}
                                            </FormatNumber>
                                        </span>
                                    </DetailRow>
                                    <DetailRow title="Tổng nạp">
                                        <span className="text-primary">
                                            <FormatNumber>
                                                {detail.TotalRecharge}
                                            </FormatNumber>
                                        </span>
                                    </DetailRow>
                                    <DetailRow title="Số lượng sản phẩm">
                                        <span className="text-primary">
                                            <FormatNumber>
                                                {detail.CountProduct}
                                            </FormatNumber>
                                        </span>
                                    </DetailRow>
                                    <DetailRow title="Tổng phí dropship">
                                        <span className="text-primary">
                                            <FormatNumber>
                                                {detail.TotalDropshipFee}
                                            </FormatNumber>
                                        </span>
                                    </DetailRow>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        }
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered table-avatar table-point mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Họ tên</th>
                        <th>Email</th>
                        <th>Điện thoại</th>
                        <th className='text-end'>Số đơn hàng</th>
                        <th className='text-end'>Doanh thu</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number,
    onOpenDetail: PropTypes.func,
    detail: PropTypes.object,
}

Table.defaultProps = {
    data: [],
    pageItem: 10,
    pageIndex: 1,
    onOpenDetail: () => { },
    detail: {}
}

export default Table