import React, { Fragment, useEffect, useState } from 'react'
import { IconButton } from './../../../components'
import { Filter, Table, ModalAdd, ModalEdit } from './components'
import { IoAddCircleOutline } from 'react-icons/io5'
import axios from 'axios'
import { Notification, Pagination } from './../../../components'

function CustomerPage(props) {
    const [customerList, setCustomerList] = useState(null)

    const [isShowModalAdd, setIsShowModalAdd] = useState(false)

    const [modalEditInfo, setModalEditInfo] = useState({
        isShow: false,
        info: {}
    })

    const [provinceList, setProvinceList] = useState([])

    const [districtList, setDistrictList] = useState([])

    const [wardList, setWardList] = useState([])

    const [filters, setFilters] = useState({
        keyword: '',
        pageItem: 10,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    useEffect(() => {
        const url = `/partner/customer/filter?keyword=${filters.keyword}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setCustomerList(data.Result.Data)
            }
        })
    }, [filters])

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Khách hàng'
        loadProvince()
    }, [])

    const loadProvince = () => {
        const url = '/partner/province/get-all'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setProvinceList(data.Result)
            }
        })
    }

    const loadDistrict = (provinceId) => {
        const url = `/partner/province/${provinceId}/districts`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setDistrictList(data.Result)
            }
        })
    }

    const loadWard = (wardId) => {
        const url = `/partner/district/${wardId}/wards`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setWardList(data.Result)
            }
        })
    }

    const reloadCustomer = () => {
        setFilters({
            ...filters,
            pageIndex: 1
        })
    }

    return (
        <Fragment>
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
            />
            <div className="card">
                <div className="card-body">
                    <div className="row mb-2">
                        <div className="col-12">
                            <IconButton text="Thêm mới" icon={<IoAddCircleOutline />} onClick={() => {
                                setIsShowModalAdd(true)
                            }} />
                        </div>
                    </div>
                    <Table
                        pageItem={filters.pageItem}
                        pageIndex={filters.pageIndex}
                        data={customerList}
                        onEdit={customer => {
                            const url = `/partner/customer/get-by-id?id=${customer.CustomerId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    if (data.Result.Districts.length !== 0) {
                                        setDistrictList(data.Result.Districts)
                                    }
                                    if (data.Result.Wards.length !== 0) {
                                        setWardList(data.Result.Wards)
                                    }
                                    setModalEditInfo({
                                        isShow: true,
                                        info: data.Result.CustomerInfo
                                    })
                                }
                            })
                        }}
                        onDelete={customer => {
                            Notification.confirmHtml('Bạn có chắc muốn xóa khách hàng', customer.FullName, () => {
                                const url = `/partner/customer/delete?id=${customer.CustomerId}`
                                axios.post(url).then(({ data }) => {
                                    if (data.IsSuccess) {
                                        reloadCustomer()
                                        Notification.success('Xóa khách hàng thành công')
                                    }
                                })
                            })
                        }}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>
            <ModalAdd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = `/partner/customer/create`
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadCustomer()
                            setIsShowModalAdd(false)
                            Notification.success('Thêm mới khách hàng thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalAdd(false)
                }}
                isShow={isShowModalAdd}
                provinceList={provinceList}
                onProvinceChange={(provinceId) => {
                    loadDistrict(provinceId)
                    setWardList([])
                }}
                districtList={districtList}
                onDistrictChange={(districtId) => {
                    loadWard(districtId)
                }}
                wardList={wardList}
            />
            <ModalEdit
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = `/partner/customer/update`
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            reloadCustomer()
                            setModalEditInfo({
                                isShow: false,
                                info: {}
                            })
                            Notification.success('Chỉnh sửa khách hàng thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setModalEditInfo({
                        isShow: false
                    })
                }}
                isShow={modalEditInfo.isShow}
                info={modalEditInfo.info}
                provinceList={provinceList}
                onProvinceChange={(provinceId) => {
                    loadDistrict(provinceId)
                    setWardList([])
                }}
                districtList={districtList}
                onDistrictChange={(districtId) => {
                    loadWard(districtId)
                }}
                wardList={wardList}
            />
        </Fragment>
    )
}

export default CustomerPage