import React, { useContext } from 'react'
import { MainBlock, Welcome } from './components'
import { AppContext } from './../../../components'

const DashboardPage = () => {
    const appSettings = useContext(AppContext)

    return (
        <>
            {
                appSettings.rights.some(n => n === 'other_dashboard') ? <MainBlock /> : <Welcome />
            }
        </>
    )
}

export default DashboardPage