import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { CloseButton, SaveButton } from './../../../../../components'

function ModalPermission(props) {
    const [rightList, setRightList] = useState([])

    const [isBusy, setIsBusy] = useState(false)

    function onRightChange(rightId, e) {
        let isChecked = e.target.checked
        if (isChecked) {

            let newList = [...rightList]
            let index = newList.indexOf(rightId)
            if (index < 0) {
                newList.push(rightId)
                setRightList(newList)
            }
        }
        else {
            let newList = [...rightList]
            let index = newList.indexOf(rightId)
            if (index > -1) {
                newList.splice(index, 1)
                setRightList(newList)
            }
        }
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="lg"
            onExited={() => {
                setRightList([])
            }}
            onShow={() => {
                let groups = props.info
                let inputList = []
                groups.forEach(item => {
                    const rightCheckedList = item.Rights.filter(x => x.IsAllow)
                    const arrRight = rightCheckedList.map(x => x.RightId)
                    inputList = inputList.concat(arrRight);
                })
                setRightList(inputList)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Phân quyền vai trò</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    {
                        props.info.map(item => <div className="col-md-3" key={item.RightGroupId}>
                            <label className="fw-bold mb-1">{item.RightGroupName}</label>
                            <div className="form-group ms-1">
                                {
                                    item.Rights.map(right => <div className="form-check form-checkbox-success mb-1" key={right.RightId}>
                                        <input type="checkbox" className="form-check-input"
                                            defaultChecked={right.IsAllow}
                                            id={right.RightId}
                                            onChange={e => onRightChange(right.RightId, e)}
                                        />
                                        <label className="form-check-label" htmlFor={right.RightId}>{right.RightName}</label>
                                    </div>)
                                }
                            </div>
                        </div>)
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SaveButton type="button" isBusy={isBusy}
                    onClick={() => {
                        props.onSave(rightList, props.roleId, setIsBusy)
                    }} />
                <CloseButton onClick={props.onClose} />
            </Modal.Footer>
        </Modal>
    )
}

ModalPermission.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    info: PropTypes.array,
    roleId: PropTypes.number
}

ModalPermission.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    info: [],
    roleId: 0
}

export default ModalPermission