import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'
import Select from 'react-select'

const schema = yup.object().shape({
    CustomerId: yup.number(),
    FullName: yup.string().required('Vui lòng nhập họ tên').max(100, 'Họ tên quá dài'),
    Phone: yup.string().required('Vui lòng nhập số điện thoại').max(20, 'Số điện thoại quá dài'),
    WardObj: yup.object().nullable(),
    WardId: yup.number().nullable(),
    Address: yup.string().max(200, 'Địa chỉ quá dài'),
    Note: yup.string().max(200, 'Ghi chú quá dài')
})

function ModalEdit(props) {
    const { setValue, getValues, control, register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const [provinceList, setProvinceList] = useState([])

    const [province, setProvince] = useState()

    const [districtList, setDistrictList] = useState([])

    const [district, setDistrict] = useState()

    const [wardList, setWardList] = useState([])

    useEffect(() => {
        const provinces = props.provinceList.map(item => {
            return {
                value: item.ProvinceId,
                label: `${item.Prefix} ${item.ProvinceName}`
            }
        })
        setProvinceList(provinces)
    }, [props.provinceList])

    useEffect(() => {
        const districts = props.districtList.map(item => {
            return {
                value: item.DistrictId,
                label: `${item.Prefix} ${item.DistrictName}`
            }
        })
        setDistrictList(districts)
    }, [props.districtList])

    useEffect(() => {
        const wards = props.wardList.map(item => {
            return {
                value: item.WardId,
                label: `${item.Prefix} ${item.WardName}`
            }
        })
        setWardList(wards)
    }, [props.wardList])

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="lg"
            onExited={() => {
                reset()
                setProvince(null)
                setDistrict(null)
            }}
            onShow={() => {
                const info = props.info
                setValue('CustomerId', info.CustomerId)
                setValue('FullName', info.FullName)
                setValue('Phone', info.Phone)
                setValue('Address', info.Address)
                setValue('Note', info.Note)
                setValue('WardId', info.WardId)
                if (info.WardId !== 0) {
                    const selectedWard = props.wardList.filter(n => n.WardId === info.WardId)[0]
                    setValue('WardObj', {
                        value: info.WardId,
                        label: `${selectedWard.Prefix} ${selectedWard.WardName}`
                    })
                    const selectedProvince = props.provinceList.filter(n => n.ProvinceId === info.ProvinceId)[0]
                    setProvince({
                        value: info.ProvinceId,
                        label: `${selectedProvince.Prefix} ${selectedProvince.ProvinceName}`
                    })
                    const selectedDistrict = props.districtList.filter(n => n.DistrictId === info.DistrictId)[0]
                    setDistrict({
                        value: info.DistrictId,
                        label: `${selectedDistrict.Prefix} ${selectedDistrict.DistrictName}`
                    })
                }
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa khách hàng</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                const wardObj = getValues('WardObj')
                if (wardObj !== undefined) {
                    formData.WardId = (wardObj !== null ? wardObj.value : 0)
                    delete formData['WardObj']
                }
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="row gy-2">
                        <div className="col-sm-6">
                            <div className="relative-position">
                                <label className="form-label">Họ tên <span className="text-danger">*</span></label>
                                <input {...register('FullName')} type="text" className="form-control" placeholder="Họ tên" />
                                {errors.FullName && <div className="validation-message">{errors.FullName.message}</div>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="relative-position">
                                <label className="form-label">Điện thoại <span className="text-danger">*</span></label>
                                <input {...register('Phone')} type="text" className="form-control" placeholder="Điện thoại" />
                                {errors.Phone && <div className="validation-message">{errors.Phone.message}</div>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="relative-position">
                                <label className="form-label">Tỉnh/Tp</label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="custom-select"
                                    isSearchable={true}
                                    isClearable={true}
                                    options={provinceList}
                                    value={province}
                                    onChange={item => {
                                        setProvince(item)
                                        setDistrict(null)
                                        props.onProvinceChange(item !== null ? item.value : 0)
                                        setValue('WardId', null)
                                        setValue('WardObj', null)
                                    }}
                                    placeholder='Tỉnh/Tp'
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="relative-position">
                                <label className="form-label">Quận/Huyện</label>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="custom-select"
                                    isSearchable={true}
                                    isClearable={false}
                                    options={districtList}
                                    value={district}
                                    onChange={item => {
                                        setDistrict(item)
                                        props.onDistrictChange(item.value)
                                        setValue('WardId', null)
                                        setValue('WardObj', null)
                                    }}
                                    placeholder='Quận/Huyện'
                                    noOptionsMessage={() => 'Quận/Huyện'}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="relative-position">
                                <label className="form-label">Phường/Xã</label>
                                <Controller
                                    control={control}
                                    name="WardObj"
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="custom-select"
                                            isSearchable={true}
                                            isClearable={false}
                                            options={wardList}
                                            value={value}
                                            onChange={item => {
                                                onChange(item)
                                            }}
                                            placeholder='Phường/Xã'
                                            noOptionsMessage={() => 'Phường/Xã'}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="relative-position">
                                <label className="form-label">Địa chỉ</label>
                                <input {...register('Address')} type="text" className="form-control" placeholder="Địa chỉ" />
                                {errors.Address && <div className="validation-message">{errors.Address.message}</div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="relative-position">
                                <label className="form-label">Ghi chú</label>
                                <textarea {...register('Note')} rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                                {errors.Note && <div className="validation-message">{errors.Note.message}</div>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEdit.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    info: PropTypes.object,
    provinceList: PropTypes.array,
    onProvinceChange: PropTypes.func,
    districtList: PropTypes.array,
    onDistrictChange: PropTypes.func,
    wardList: PropTypes.array
}

ModalEdit.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    info: {},
    provinceList: [],
    onProvinceChange: () => { },
    districtList: [],
    onDistrictChange: () => { },
    wardList: []
}

export default ModalEdit