import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { Filter, ModalAdd, Table } from './components'
import { AuthAdminView, IconButton, Notification, Pagination } from './../../../components'
import { IoAddCircleOutline } from 'react-icons/io5'

function FreeShipPage(props) {

    const [isShowModalAdd, setIsShowModalAdd] = useState(false)

    const [filters, setFilters] = useState({
        keyword: '',
        pageItem: 10,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    const [partnerGroups, setPartnerGroups] = useState()

    const [freeShips, setFreeShips] = useState(null)

    const loadPartnerGroup = () => {
        const url = '/admin/partner-group/get-all'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                const groups = data.Result.map(item => {
                    return {
                        value: item.GroupId,
                        label: item.GroupName
                    }
                })
                setPartnerGroups(groups)
            }
        })
    }

    useEffect(() => {
        const url = `/admin/free-ship/filter?keyword=${filters.keyword}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setFreeShips(data.Result.Data)
            }
        })
    }, [filters])

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Miễn phí đóng gói'
        loadPartnerGroup()
    }, [])

    return (
        <Fragment>
            <Filter
                onSearch={params => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
            />

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="freeship_add">
                                <IconButton
                                    className="mb-2"
                                    text="Thêm mới"
                                    icon={<IoAddCircleOutline />}
                                    onClick={() => {
                                        setIsShowModalAdd(true)
                                    }} />
                            </AuthAdminView>
                        </div>
                    </div>

                    <Table
                        pageItem={filters.pageItem}
                        pageIndex={filters.pageIndex}
                        data={freeShips}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>

            <ModalAdd
                isShow={isShowModalAdd}
                onSave={(formData, setIsBusy) => {
                    // console.log(formData)
                    setIsBusy(true)
                    const url = '/admin/free-ship/create'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            Notification.success('Thêm mới miễn phí đóng gói thành công')
                            setIsShowModalAdd(false)
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalAdd(false)
                }}
                partnerGroups={partnerGroups}
            />
        </Fragment>
    )
}

export default FreeShipPage