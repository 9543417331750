import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { Notification } from '../../../components'
import { Filter, Table } from './components'
import Swal from 'sweetalert2'

function OrderFromShopee(props) {
    const [shopeeShops, setShopeeShops] = useState([])

    const [orders, setOrders] = useState({
        orders: [],
        status: '',
        partnerShopId: 0
    })

    const [filters, setFilters] = useState({
        partnerShopId: 0,
        status: 'unpaid',
        sortBy: 'confirmed_date_desc',
        pageItem: 10,
        pageIndex: 1
    })

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Đơn hàng từ shopee'
        loadShop()
    }, [])

    useEffect(() => {
        if (filters.partnerShopId !== 0) {
            setOrders({
                ...orders,
                orders: null
            })
            let url = `/partner/partner-shop/order-list-with-detail`
            url += `?partnerShopId=${filters.partnerShopId}&source=${filters.status}&sortBy=${filters.sortBy}&pageSize=10&pageNumber=1`
            axios.get(url).then(({ data }) => {
                if (data.IsSuccess) {
                    if (data.Result === null) {
                        setOrders([])
                        return
                    }
                    if (data.Result.length === 0) {
                        setOrders([])
                        return
                    }
                    const orderList = data.Result.Orders.Data.Orders
                    const status = data.Result.Status
                    setOrders({
                        orders: orderList,
                        status: status,
                        partnerShopId: filters.partnerShopId
                    })
                }
                else {
                    setOrders({
                        ...orders,
                        orders: []
                    })
                }
            })
        }

        // eslint-disable-next-line
    }, [filters])

    function loadShop() {
        const url = '/partner/partner-shop/get-all'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setShopeeShops(data.Result)
            }
        })
    }

    function processOrder(orderUrl, order, partnerShopId, setIsBusy, isUseFreeShip) {
        axios.get(orderUrl).then(({ data }) => {
            if (data.IsSuccess) {
                const isExist = data.Result
                const orderUrl = '/partner/order/create-from-shopee'
                if (isExist) {
                    Notification.confirmHtml('Đơn hàng đã được đặt với kho, bạn có muốn tiếp tục đặt đơn hàng', order.Order_Sn, () => {
                        axios.post(orderUrl, {
                            OrderId: order.Order_Id,
                            ShopId: order.Shop_Id,
                            RegionId: 'VN',
                            PartnerShopId: partnerShopId,
                            IsUseFreeShip: isUseFreeShip
                        }).then(orderResponse => {
                            if (orderResponse.data.IsSuccess) {
                                Notification.success('Đặt hàng từ shopee thành công')
                            }
                            setIsBusy(false)
                        })
                    })
                }
                else {
                    axios.post(orderUrl, {
                        OrderId: order.Order_Id,
                        ShopId: order.Shop_Id,
                        RegionId: 'VN',
                        PartnerShopId: partnerShopId,
                        IsUseFreeShip: isUseFreeShip
                    }).then(orderResponse => {
                        if (orderResponse.data.IsSuccess) {
                            Notification.success('Đặt hàng từ shopee thành công')
                        }
                        setIsBusy(false)
                    })
                }
            }
            setIsBusy(false)
        })
    }

    return (
        <Fragment>
            <Filter
                onSearch={(params) => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
                shopeeShops={shopeeShops}
            />
            <div className="card">
                <div className="card-body">
                    <Table
                        orders={orders.orders}
                        status={orders.status}
                        partnerShopId={orders.partnerShopId}
                        onOrder={(order, setIsBusy, partnerShopId) => {
                            Notification.confirmHtml('Bạn có chắc muốn đặt đơn hàng', order.Order_Sn, () => {
                                setIsBusy(true)
                                Swal.fire({
                                    text: 'Bạn có muốn sử dụng miễn phí đóng gói không?',
                                    icon: 'question',
                                    showDenyButton: true,
                                    showCancelButton: true,
                                    confirmButtonText: 'Đồng ý',
                                    denyButtonText: `Không`,
                                    cancelButtonText: 'Bỏ qua'
                                }).then((result) => {
                                    const orderUrl = `/partner/order/is-exist-order-sn?orderSn=${order.Order_Sn}`
                                    if (result.isConfirmed) {
                                        processOrder(orderUrl, order, partnerShopId, setIsBusy, true)
                                    } else if (result.isDenied) {
                                        processOrder(orderUrl, order, partnerShopId, setIsBusy, false)
                                    }
                                    else {
                                        setIsBusy(false)
                                    }
                                })
                            })
                        }}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default OrderFromShopee