import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    FullName: yup.string().required('Vui lòng nhập họ tên').max(100, 'Họ tên quá dài'),
    Email: yup.string().required('Vui lòng nhập email').max(70, 'Email quá dài').email('Email không đúng'),
    Phone: yup.string().required('Vui lòng nhập số điện thoại').max(20, 'Số điện thoại quá dài')
})

const Profile = props => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    useEffect(() => {
        const data = props.data
        const fields = ['FullName', 'Email', 'Phone']
        fields.forEach(item => {
            setValue(item, data[item])
        })
        // eslint-disable-next-line
    }, [props.data])

    return (
        <div className="card">
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <div className="card-body">
                    <h5 className="card-title mb-2">Chỉnh sửa thông tin</h5>
                    <div className="relative-position mb-2">
                        <label className="form-label">Họ tên <span className="text-danger">*</span></label>
                        <input {...register('FullName')} type="text" className="form-control" placeholder="Họ tên" />
                        {errors.FullName && <div className="validation-message">{errors.FullName.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Email <span className="text-danger">*</span></label>
                        <input {...register('Email')} type="text" className="form-control" placeholder="Email" />
                        {errors.Email && <div className="validation-message">{errors.Email.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Điện thoại <span className="text-danger">*</span></label>
                        <input {...register('Phone')} type="text" className="form-control" placeholder="Điện thoại" />
                        {errors.Phone && <div className="validation-message">{errors.Phone.message}</div>}
                    </div>
                </div>
                <div className="card-footer">
                    <SaveButton isBusy={isBusy} />
                </div>
            </form>
        </div>
    )
}

Profile.propTypes = {
    onSave: PropTypes.func,
    data: PropTypes.object
}

Profile.defaultProps = {
    onSave: () => { },
    data: {}
}

export default Profile