import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ClearButton, SearchButton, CategoryDropdownTree } from './../../../../../components'

const productTypes = [
    {
        value: 0,
        label: 'Sản phẩm cha con'
    },
    {
        value: 1,
        label: 'Chỉ sản phẩm cha'
    },
    {
        value: 2,
        label: 'Chỉ sản phẩm con'
    }
]

const filters = {
    keyword: '',
    catId: 0,
    statusId: 0,
    typeId: 0
}

const Filter = props => {
    const { onSearch } = props

    const [keyword, setKeyword] = useState('')

    const [productStatus, setProductStatus] = useState()

    const [productStatusList, setProductStatusList] = useState([])

    const [productType, setProductType] = useState()

    const [catId, setCatId] = useState(0)

    useEffect(() => {
        const proStatusList = props.productStatus.map(item => {
            return {
                value: item.ProductStatusId,
                label: item.ProductStatusName
            }
        })
        setProductStatusList(proStatusList)
    }, [props.productStatus])

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                        <input value={keyword}
                            autoFocus
                            onChange={e => {
                                const value = e.target.value
                                setKeyword(value)
                                filters.keyword = value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    onSearch(filters)
                                }
                            }}
                            type="text" className="form-control" autoComplete="off" placeholder="Mã, tên sản phẩm" />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                        <CategoryDropdownTree
                            options={props.category}
                            onChange={(value) => {
                                setCatId(value)
                                filters.catId = value
                            }}
                            value={catId}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            options={productStatusList}
                            value={productStatus}
                            onChange={item => {
                                if (item !== null) {
                                    setProductStatus(item)
                                    filters.statusId = item.value
                                }
                                else {
                                    setProductStatus(null)
                                    filters.statusId = 0
                                }
                            }}
                            placeholder='Trạng thái'
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={false}
                            options={productTypes}
                            value={productType}
                            onChange={item => {
                                if (item !== null) {
                                    setProductType(item)
                                    filters.typeId = item.value
                                }
                                else {
                                    setProductType(null)
                                    filters.typeId = 0
                                }
                            }}
                            defaultValue={productTypes[0]}
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-12 col-xxl-4">
                        <div className="button-list">
                            <SearchButton onClick={() => {
                                onSearch(filters)
                            }} />
                            <ClearButton onClick={() => {
                                setKeyword('')
                                filters.keyword = ''
                                setCatId(0)
                                filters.catId = 0
                                setProductStatus(null)
                                filters.statusId = 0
                                setProductType(productTypes[0])
                                filters.typeId = 0
                                onSearch(filters)
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func,
    category: PropTypes.array,
    productStatus: PropTypes.array
}

Filter.defaultProps = {
    onSearch: () => { },
    category: [],
    productStatus: []
}

export default Filter