import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import * as yup from "yup"
import { CloseButton, InputSelect, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    Title: yup.string().required('Vui lòng nhập tiêu đề').max(100, 'Tiêu đề quá dài'),
    TypeId: yup.mixed().oneOf([1, 2], 'Vui lòng chọn loại'),
    PartnerId: yup.number().when('TypeId', {
        is: 1,
        then: yup.number().required('Vui lòng chọn cộng tác viên')
    }),
    GroupId: yup.number().when('TypeId', {
        is: 2,
        then: yup.number().required('Vui lòng chọn nhóm cộng tác viên')
    }),
    Note: yup.string().max(200, 'Ghi chú quá dài'),
    Qty: yup.number().required('Vui lòng nhập số lượng').min(1, 'Số lượng tối thiều 1').max(10_000, 'Số lượng tối đa 10.000')
})

const types = [
    { value: 1, label: 'Cộng tác viên' },
    { value: 2, label: 'Nhóm cộng tác viên' }
]

const loadPartner = (inputValue, callback) => {
    const url = `/admin/partner/dropdown-filter?keyword=${inputValue}&pageItem=5&pageIndex=1`
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            let partnerLst = data.Result.Data.map(item => {
                return {
                    value: item.PartnerId,
                    label: item.FullName + ` (${item.Email})`
                }
            })
            callback(partnerLst)
        }
    })
}

function ModalAdd(props) {

    const { control, register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            TypeId: 1
        }
    })

    const [isBusy, setIsBusy] = useState(false)

    const [freeShipType, setFreeShipType] = useState(1)

    const renderPartner = () => {
        if (freeShipType === 1) {
            return <div className="relative-position mb-2">
                <label className="form-label">Cộng tác viên <span className="text-danger">*</span></label>
                <AsyncSelect
                    className="react-select-container"
                    classNamePrefix="custom-select"
                    placeholder='Cộng tác viên'
                    cacheOptions
                    defaultOptions
                    loadOptions={debounce(loadPartner, 250)}
                    onChange={item => setValue('PartnerId', item.value)}
                    loadingMessage={() => "Đang tìm kiếm"}
                    noOptionsMessage={() => "Không tìm thấy dữ liệu"}
                />
                {errors.PartnerId && <div className="validation-message">{errors.PartnerId.message}</div>}
            </div>
        }
    }

    const renderGroup = () => {
        if (freeShipType === 2) {
            return <div className="relative-position mb-2">
                <label className="form-label">Nhóm cộng tác viên <span className="text-danger">*</span></label>
                <Select
                    className="react-select-container"
                    classNamePrefix="custom-select"
                    // defaultValue={props.partnerGroups[0]}
                    placeholder="Nhóm cộng tác viên"
                    isSearchable={false}
                    options={props.partnerGroups}
                    onChange={item => {
                        setValue('GroupId', item.value)
                    }}
                />
                {errors.GroupId && <div className="validation-message">{errors.GroupId.message}</div>}
            </div>
        }
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" animation={true}
            onExited={() => {
                reset()
                setFreeShipType(1)
            }}
            onShow={() => {
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới miễn phí đóng gói</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tiêu đề <span className="text-danger">*</span></label>
                        <input {...register('Title')} type="text" className="form-control" placeholder="Tiêu đề" />
                        {errors.Title && <div className="validation-message">{errors.Title.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Loại <span className="text-danger">*</span></label>
                        <Controller
                            name="TypeId"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <InputSelect
                                        options={types}
                                        value={value}
                                        defaultValue={1}
                                        name='freeship-type'
                                        variant='success'
                                        onChange={(val) => {
                                            onChange(val)
                                            setFreeShipType(val)
                                        }}
                                    />
                                )
                            }}
                        />
                        {errors.TypeId && <div className="validation-message">{errors.TypeId.message}</div>}
                    </div>
                    {renderPartner()}
                    {renderGroup()}
                    <div className="relative-position mb-2">
                        <label className="form-label">Số lượng</label>
                        <Controller
                            name="Qty"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <NumberFormat
                                        placeholder='Số lượng'
                                        className="form-control"
                                        // thousandSeparator={"."}
                                        // decimalSeparator={","}
                                        allowNegative={false}
                                        defaultValue={0}
                                        decimalScale={0}
                                        value={value}
                                        onValueChange={val => onChange(val.value)}
                                    />
                                )
                            }}
                        />
                        {errors.Qty && <div className="validation-message">{errors.Qty.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Ghi chú</label>
                        <textarea {...register('Note')} rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                        {errors.Note && <div className="validation-message">{errors.Note.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAdd.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    partnerGroups: PropTypes.array
}

ModalAdd.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    partnerGroups: []
}

export default ModalAdd