import axios from 'axios'

const AttributeService = {
    getAll: () => {
        const url = '/admin/attribute/get-all'
        return axios.get(url)
    },
    create: (formData) => {
        const url = 'admin/attribute/create'
        return axios.post(url, formData)
    },
    getById: (attributeId) => {
        const url = `/admin/attribute/get-by-id?id=${attributeId}`
        return axios.get(url)
    },
    update: (formData) => {
        const url = '/admin/attribute/update'
        return axios.post(url, formData)
    },
    delete: (attributeId) => {
        const url = `/admin/attribute/delete?id=${attributeId}`
        return axios.post(url)
    }
}

export default AttributeService