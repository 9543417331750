import PropTypes from 'prop-types'
import React from 'react'
import { FiEdit } from 'react-icons/fi'
import { IoTrashOutline } from 'react-icons/io5'
import { ActionItem, ActionRow, EmptyRow, LoadingRow } from './../../../../../components'

const Table = props => {
    const renderTable = () => {
        let data = props.data
        if (data === null) return <LoadingRow colSpan={6} />
        else if (data.length === 0) return <EmptyRow colSpan={6} />
        else {
            return data.map((item, index) => {
                return <tr key={item.ShippingUnitId}>
                    <td className="text-center">{(index + 1)}</td>
                    <td>{item.ShippingUnitName}</td>
                    <td>
                        {renderStatus(item.ShippingUnitStatusId)}
                    </td>
                    <td>{item.Note}</td>
                    <td>
                        {item.CreatedDate}
                        <small className="text-muted"> {item.CreatedTime}</small>
                    </td>
                    <td>
                        <ActionRow>
                            <ActionItem right="shipping_unit_edit" onClick={() => props.onEdit(item)} icon={<FiEdit />} title='Chỉnh sửa' />
                            <ActionItem right="shipping_unit_delete" onClick={() => props.onDelete(item)} icon={<IoTrashOutline />} title='Xóa' className="text-danger" />
                        </ActionRow>
                    </td>
                </tr>
            })
        }
    }

    const renderStatus = statusId => {
        if (statusId === 1) return <span className="badge bg-success">Hoạt động</span>
        else return <span className="badge bg-warning">Tạm khóa</span>
    }

    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Đơn vị vận chuyển</th>
                        <th>Trạng thái</th>
                        <th>Ghi chú</th>
                        <th>Ngày tạo</th>
                        <th className="w-52-px"></th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable()}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
}

Table.defaultProps = {
    data: null,
    onEdit: () => { },
    onDelete: () => { }
}

export default Table