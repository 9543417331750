import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoClose } from 'react-icons/io5'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as yup from "yup"
import { Config, IconButton, InputSelect, Notification, SaveButton } from '../../../components'
import { SettingAffiliate } from './components'

const statusOptions = [
    {
        value: 1,
        label: 'Hoạt động'
    },
    {
        value: 2,
        label: 'Tạm khóa'
    }
]

const emailStatusOptions = [
    { value: 1, label: 'Gửi email' },
    { value: 2, label: 'Không gửi' }
]

const schema = yup.object().shape({
    EmailToAddress: yup.string().max(100, 'Địa chỉ email quá dài').email('Email không đúng định dạng'),
    SendingEmailStatusId: yup.number(),
    EmailSubject: yup.string().max(100, 'Chủ đề email quá dài')
})

function SettingPage(props) {
    const { control, register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [content, setContent] = useState('')

    const [status, setStatus] = useState(0)

    const [isBusy, setIsBusy] = useState(false)

    const [isBusyEmail, setIsBusyEmail] = useState(false)

    const [setting, setSetting] = useState(null)

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Cấu hình'
        loadSetting()

        // eslint-disable-next-line
    }, [])

    const loadSetting = () => {
        const url = '/admin/setting/get-all'
        axios.get(url).then(res => {
            const data = res.data
            if (data.IsSuccess) {
                setContent(data.Result.Message)
                const resStatus = statusOptions.filter(x => x.value === data.Result.StatusId)[0]?.value ?? 2
                setStatus(resStatus)

                setSetting(data.Result)

                setValue('EmailSubject', data.Result.EmailSubject)
                setValue('EmailToAddress', data.Result.EmailToAddress)
                setValue('SendingEmailStatusId', data.Result.SendingEmailStatusId)
            }
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Cấu hình Thông báo</h5>
                    <div className="relative-position mb-2">
                        <label className="form-label">Nội dung thông báo</label>
                        <ReactQuill
                            value={content}
                            modules={Config.editorCfg}
                            onChange={setContent}
                        />
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-4">
                            <div className="relative-position">
                                <label className="form-label">Trạng thái</label>
                                <InputSelect
                                    name="msg-status"
                                    variant='success'
                                    options={statusOptions}
                                    value={status}
                                    onChange={val => {
                                        setStatus(val)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="button-list">
                        <SaveButton
                            isBusy={isBusy}
                            onClick={() => {
                                setIsBusy(true)
                                const url = '/admin/setting/update'
                                axios.post(url, {
                                    Message: content,
                                    StatusId: status
                                }).then(res => {
                                    const data = res.data
                                    if (data.IsSuccess) {
                                        Notification.success('Chỉnh sửa cấu hình thông báo thành công')
                                    }
                                    setIsBusy(false)
                                })
                            }} />
                        <IconButton onClick={() => {
                            setStatus(statusOptions[1])
                            setContent('')
                        }} text="Xóa hết" variant="warning" icon={<IoClose />} />
                    </div>
                </div>
            </div>

            <div className="card">
                <form onSubmit={handleSubmit(formData => {
                    setIsBusyEmail(true)
                    const url = '/admin/setting/update-email'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            Notification.success('Chỉnh sửa cấu hình email thành công')
                            reset();
                        }
                        setIsBusyEmail(false)
                    })
                })}>
                    <div className="card-body">
                        <h5 className="card-title">Cấu hình email</h5>
                        <div className="row gy-2">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 position-relative">
                                <label className="form-label">Email nhận</label>
                                <input {...register('EmailToAddress')} type="text" className="form-control" placeholder="Email nhận" />
                                {errors.EmailToAddress && <div className="validation-message">{errors.EmailToAddress.message}</div>}
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 position-relative">
                                <label className="form-label">Chủ đề email</label>
                                <input {...register('EmailSubject')} type="text" className="form-control" placeholder="Chủ đề email" />
                                {errors.EmailSubject && <div className="validation-message">{errors.EmailSubject.message}</div>}
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 position-relative">
                                <label className="form-label">Trạng thái</label>
                                <Controller
                                    control={control}
                                    name="SendingEmailStatusId"
                                    render={({
                                        field: { onChange, value }
                                    }) => (
                                        <InputSelect
                                            name="email-status"
                                            variant='success'
                                            options={emailStatusOptions}
                                            value={value}
                                            onChange={val => {
                                                onChange(val)
                                            }}
                                        />
                                    )}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="button-list">
                            <SaveButton
                                type="submit"
                                isBusy={isBusyEmail}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <SettingAffiliate setting={setting} />
        </Fragment>
    )
}

export default SettingPage