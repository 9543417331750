import PropTypes from 'prop-types'
import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { IoSquare } from 'react-icons/io5'
import { FormatNumber } from './../../../../../components'

function PartnerChart(props) {
    const { data, colors } = props

    function renderLabel() {
        return data.partnerGroups.map((item, index) => {
            return (
                <p key={index} className={data.partnerGroups.length - 1 === index ? 'mb-0' : ''}>
                    <IoSquare className="me-1" style={{ color: colors[index] }} />
                    {item.PartnerGroupName}
                    <span className="float-end"><FormatNumber>{item.CountPartner}</FormatNumber></span>
                </p>
            )
        })
    }

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title mb-2">SỐ CỘNG TÁC VIÊN</h5>
                <ReactApexChart options={data.options} series={data.series} type="donut" />
                <div className="chart-widget-list">
                    {renderLabel()}
                </div>
            </div>
        </div>
    )
}

PartnerChart.propTypes = {
    data: PropTypes.object,
    colors: PropTypes.array,
}

PartnerChart.defaultProps = {
    data: {},
    colors: []
}

export default PartnerChart