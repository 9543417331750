import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    OrderId: yup.number(),
    WarehouseNote: yup.string().max(1_000, 'Ghi chú gửi kho quá dài')
})

const ModalEditWarehouseNote = (props) => {
    const { setValue, register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const [isEnableEdit, setIsEnableEdit] = useState(true)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" size="lg"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                const data = props.data
                if (data !== null) {
                    setValue('OrderId', data.OrderId)
                    setValue('WarehouseNote', data.WarehouseNote)
                    setIsEnableEdit(data.OrderStatusId === 1) // Chỉ cho phép sửa ghi chú gửi kho đơn hàng mới
                }
            }
            }>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa ghi chú gửi kho</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position">
                        <label className="form-label">Ghi chú gửi kho</label>
                        <textarea {...register('WarehouseNote')} rows="3" className="form-control" placeholder="Ghi chú gửi kho" disabled={!isEnableEdit}></textarea>
                        {errors.WarehouseNote && <div className="validation-message">{errors.WarehouseNote.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {isEnableEdit ? <SaveButton isBusy={isBusy} /> : ''}
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEditWarehouseNote.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    data: PropTypes.object
}

ModalEditWarehouseNote.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    data: {}
}

export default ModalEditWarehouseNote