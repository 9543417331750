import React from 'react'
import PropTypes from 'prop-types'

function CheckBox(props) {

    const { title, name, className, variant, onChange, checked } = props

    const variantClassName = () => {
        return `form-control form-check form-radio-${variant !== undefined ? variant : props.variant} position-relative`
    }

    const handleChange = (e) => {
        const isChecked = e.target.checked
        onChange(isChecked)
    }


    return (
        <div
            className={className}
        >
            <div
                className={variantClassName()}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    id={name}
                    name={name}
                    className="form-check-input input-select-radio"
                    onChange={handleChange}
                />
                <label
                    className="form-check-label input-select-label"
                    htmlFor={name}
                >
                    {title}
                </label>
            </div>
        </div>
    )
}

CheckBox.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.string,
    className: PropTypes.string
}

CheckBox.defaultProps = {
    checked: false,
    name: 'input-checkbox',
    variant: 'primary',
    onChange: () => { },
    title: 'Title',
    className: ''
}

export default CheckBox