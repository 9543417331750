import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SearchButton, ClearButton } from './../../../../../components'
import DatePicker from 'react-datepicker'
import Select from 'react-select'

const filters = {
    keyword: '',
    fromDate: '',
    toDate: '',
    statusId: 0,
    orderFromId: 0
}

const Filter = (props) => {
    const { onSearch } = props

    const [keyword, setKeyword] = useState('')

    const [fromDate, setFromDate] = useState()

    const [toDate, setToDate] = useState()

    const [statuses, setStatuses] = useState([])

    const [status, setStatus] = useState()

    const [orderFroms, setOrderFroms] = useState([])

    const [orderFrom, setOrderFrom] = useState()

    useEffect(() => {
        const orderStatuses = props.orderStatuses.map(item => {
            return {
                value: item.OrderStatusId,
                label: item.OrderStatusName
            }
        })
        setStatuses(orderStatuses)
    }, [props.orderStatuses])

    useEffect(() => {
        const froms = props.orderFroms.map(item => {
            return {
                value: item.OrderFromId,
                label: item.OrderFromName
            }
        })
        setOrderFroms(froms)
    }, [props.orderFroms])

    const handleSearch = () => {
        onSearch(filters)
    }

    const handleClear = () => {
        setKeyword('')
        filters.keyword = ''
        setFromDate(null)
        filters.fromDate = ''
        setToDate(null)
        filters.toDate = ''
        setStatus(null)
        filters.statusId = 0
        setOrderFrom(null)
        filters.orderFromId = 0
        onSearch(filters)
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className="row g-2">
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-2">
                        <input
                            autoFocus
                            onChange={e => {
                                const value = e.target.value
                                setKeyword(value)
                                filters.keyword = value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                            value={keyword} type="text" className="form-control" autoComplete="off" placeholder="ID đơn hàng" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-2">
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text">Từ ngày</span>
                            <DatePicker
                                className="form-control no-border-radius-top-left no-border-radius-bottom-left"
                                placeholderText="dd/mm/yyyy"
                                dateFormat="dd/MM/yyyy"
                                selected={fromDate}
                                onChange={date => {
                                    if (date !== null) {
                                        const day = date.getDate()
                                        const month = date.getMonth() + 1
                                        const year = date.getFullYear()
                                        const strDate = `${day}/${month}/${year}`
                                        filters.fromDate = strDate
                                    }
                                    setFromDate(date)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-2">
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text">Đến ngày</span>
                            <DatePicker
                                className="form-control no-border-radius-top-left no-border-radius-bottom-left"
                                placeholderText="dd/mm/yyyy"
                                dateFormat="dd/MM/yyyy"
                                selected={toDate}
                                onChange={date => {
                                    if (date !== null) {
                                        const day = date.getDate()
                                        const month = date.getMonth() + 1
                                        const year = date.getFullYear()
                                        const strDate = `${day}/${month}/${year}`
                                        filters.toDate = strDate
                                    }
                                    setToDate(date)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            options={statuses}
                            value={status}
                            onChange={item => {
                                if (item !== null) {
                                    setStatus(item)
                                    filters.statusId = item.value
                                }
                                else {
                                    setStatus(null)
                                    filters.statusId = 0
                                }
                            }}
                            placeholder='Trạng thái'
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-2">
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            isSearchable={false}
                            isClearable={true}
                            options={orderFroms}
                            value={orderFrom}
                            onChange={item => {
                                if (item !== null) {
                                    setOrderFrom(item)
                                    filters.orderFromId = item.value
                                }
                                else {
                                    setOrderFrom(null)
                                    filters.orderFromId = 0
                                }
                            }}
                            placeholder='Đơn nguồn'
                        />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-10">
                        <div className="button-list">
                            <SearchButton onClick={handleSearch} />
                            <ClearButton onClick={handleClear} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Filter.propTypes = {
    onSearch: PropTypes.func,
    orderStatuses: PropTypes.array,
    orderFroms: PropTypes.array
}

Filter.defaultProps = {
    onSearch: () => { },
    orderStatuses: [],
    orderFroms: []
}

export default Filter