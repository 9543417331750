import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { FiEdit } from "react-icons/fi";
import {
  IoCloudDownloadOutline,
  IoEllipsisHorizontalSharp,
  IoSwapHorizontalOutline,
  IoTrashOutline,
  IoReloadOutline,
} from "react-icons/io5";
import {
  ActionItem,
  ActionRow,
  AvatarRow,
  EmptyRow,
  FormatNumber,
  LoadingRow,
} from "./../../../../../components";

const Table = (props) => {
  const {
    data,
    onEdit,
    onDelete,
    onUpdateStatus,
    onDownloadWaybill,
    onAvatarClick,
    onGetWaybill,
    onDownloadAttachWaybill,
  } = props;

  const renderTable = () => {
    if (data === null) return <LoadingRow colSpan={9} />;
    else if (data.length === 0) return <EmptyRow colSpan={9} />;
    else
      return data.map((item) => {
        const rowSpan = item.Products.length;
        const firstProduct = item.Products[0];
        return (
          <Fragment key={item.OrderId}>
            <tr>
              <td className="text-center" rowSpan={rowSpan}>
                {item.OrderId}
                <br />
                <span className="text-success">{item.CreatedDate}</span>
                <br />
                <small className="text-muted">{item.CreatedTime}</small>
              </td>
              <td rowSpan={rowSpan}>{item.PartnerFullName}</td>
              <td>
                <p className="mb-1">
                  <AvatarRow
                    url={firstProduct.AvatarUrl}
                    onClick={(urlObj) => {
                      onAvatarClick(urlObj);
                    }}
                    className="cursor-zoom"
                  />
                  {firstProduct.ProductName}
                </p>
                <p className="mb-0">
                  <span>
                    Giá:{" "}
                    <span className="text-danger fw-bold">
                      <FormatNumber>
                        {firstProduct.CustomerUnitPrice}
                      </FormatNumber>
                    </span>
                  </span>
                  <span className="float-end">
                    Số lượng:{" "}
                    <span className="text-danger fw-bold">
                      <FormatNumber>{firstProduct.Quantity}</FormatNumber>
                    </span>
                  </span>
                </p>
              </td>
              <td className="text-end text-primary" rowSpan={rowSpan}>
                <FormatNumber>{item.SumOutputPrice}</FormatNumber>
              </td>
              <td rowSpan={rowSpan}>
                <p className="mb-1">{item.WaybillCode}</p>
                <p className="mb-0">{item.ShippingUnitName}</p>
              </td>
              <td className="text-end" rowSpan={rowSpan}>
                <p className="text-primary fw-bold mb-1">
                  <FormatNumber>{item.SumPartnerPrice}</FormatNumber>
                </p>
                <p className="text-success mb-0">
                  +<FormatNumber>{item.DropshipFee}</FormatNumber>
                </p>
              </td>
              <td className="text-center text-primary" rowSpan={rowSpan}>
                <IoEllipsisHorizontalSharp
                  className="cursor-point fs-4"
                  onClick={() => {
                    props.onEditWarehouseNote(item);
                  }}
                />
              </td>
              <td rowSpan={rowSpan}>{renderStatus(item)}</td>
              <td rowSpan={rowSpan}>
                <ActionRow>
                  {renderDownloadWaybill(item)}
                  {renderUpdateCmd(item)}
                  {renderGetWaybill(item)}
                  {renderDownloadAttachWaybill(item)}
                  <ActionItem
                    right="order_edit"
                    onClick={() => onUpdateStatus(item)}
                    icon={<IoSwapHorizontalOutline />}
                    title="Cập nhật trạng thái"
                  />
                  <ActionItem
                    right="order_delete"
                    onClick={() => onDelete(item)}
                    icon={<IoTrashOutline />}
                    title="Xóa"
                    className="text-danger"
                  />
                </ActionRow>
              </td>
            </tr>
            {renderChildrenProduct(item.Products)}
          </Fragment>
        );
      });
  };

  const renderDownloadAttachWaybill = (order) => {
    if (order.FileUrl !== "")
      return (
        <ActionItem
          right="order_waybill_attach_download"
          onClick={() => onDownloadAttachWaybill(order)}
          icon={<IoCloudDownloadOutline />}
          title="Tải vận đơn (đính kèm)"
        />
      );
  };

  const renderGetWaybill = (order) => {
    if (order.OrderFromId === 1)
      // Đơn hàng từ shopee
      return (
        <ActionItem
          right="order_waybill_get"
          onClick={() => onGetWaybill(order)}
          icon={<IoReloadOutline />}
          title="Tải lại vận đơn"
        />
      );
  };

  const renderUpdateCmd = (item) => {
    if (item.OrderStatusId === 1) {
      <ActionItem
        right="order_edit"
        onClick={() => onEdit(item)}
        icon={<FiEdit />}
        title="Chỉnh sửa"
      />;
    }
  };

  const renderDownloadWaybill = (order) => {
    if (order.WaybillUrl !== null && order.WaybillUrl !== "")
      return (
        <ActionItem
          right="order_waybill_download"
          onClick={() => onDownloadWaybill(order)}
          icon={<IoCloudDownloadOutline />}
          title="Tải vận đơn"
        />
      );
  };

  const renderStatus = (order) => {
    const statusId = order.OrderStatusId;
    const statusName = order.OrderStatusName;
    let className = "";
    if (statusId !== 5) {
      className =
        statusId === 1
          ? "badge bg-success min-w-50-px cursor-point"
          : "badge bg-danger min-w-50-px cursor-point";
    } else {
      className =
        statusId === 1
          ? "badge bg-success min-w-50-px"
          : "badge bg-danger min-w-50-px";
    }
    return (
      <span className={className} onClick={() => handleChangeStatus(order)}>
        {statusName}
      </span>
    );
  };

  const handleChangeStatus = (order) => {
    if (order.OrderStatusId !== 5) {
      onUpdateStatus(order);
    }
  };

  const renderChildrenProduct = (products) => {
    const html = [];
    const productLength = products.length;
    for (let i = 1; i < productLength; i++) {
      const product = products[i];
      html.push(
        <tr key={product.ProductId}>
          <td>
            <p className="mb-1">
              <AvatarRow
                url={product.AvatarUrl}
                onClick={(urlObj) => {
                  onAvatarClick(urlObj);
                }}
                className="cursor-zoom"
              />
              {product.ProductName}
            </p>
            <p className="mb-0">
              <span>
                Giá:{" "}
                <span className="text-danger fw-bold">
                  <FormatNumber>{product.CustomerUnitPrice}</FormatNumber>
                </span>
              </span>
              <span className="float-end">
                Số lượng:{" "}
                <span className="text-danger fw-bold">
                  <FormatNumber>{product.Quantity}</FormatNumber>
                </span>
              </span>
            </p>
          </td>
        </tr>
      );
    }
    return html;
  };

  return (
    <div className="table-responsive">
      <table className="table table-centered table-avatar mb-0">
        <thead>
          <tr>
            <th>ID</th>
            <th>CTV</th>
            <th>Tên sản phẩm</th>
            <th className="text-end">Tổng</th>
            <th>Vận chuyển</th>
            <th className="text-end">Thu CTV</th>
            <th>Ghi chú</th>
            <th>Trạng thái</th>
            <th className="w-52-px"></th>
          </tr>
        </thead>
        <tbody>{renderTable()}</tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdateStatus: PropTypes.func,
  onEditWarehouseNote: PropTypes.func,
  onDownloadWaybill: PropTypes.func,
  onAvatarClick: PropTypes.func,
  onGetWaybill: PropTypes.func,
  onDownloadAttachWaybill: PropTypes.func,
};

Table.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onUpdateStatus: () => {},
  onEditWarehouseNote: () => {},
  onDownloadWaybill: () => {},
  onAvatarClick: () => {},
  onGetWaybill: () => {},
  onDownloadAttachWaybill: () => {},
};

export default Table;
