import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { EmptyRow, LoadingRow } from "../../../components";

export default function ChildrenPage() {
  const [partners, setPartners] = useState(null);

  useEffect(() => {
    document.getElementById('page-header').innerText = 'Đã giới thiệu'

    const url = "/partner/partner/get-children";
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setPartners(data.Result);
      }
    });
  }, []);

  function renderTable() {
    if (partners === null) return <LoadingRow colSpan={5} />;
    else if (partners.length === 0) return <EmptyRow colSpan={5} />;
    else
      return partners.map((item, index) => (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>{item.FullName}</td>
          <td>{item.Email}</td>
          <td>{item.Phone}</td>
          <td>{item.GroupName}</td>
        </tr>
      ));
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive">
          <table className="table table-centered mb-0">
            <thead>
              <tr>
                <th className="w-52-px">#</th>
                <th>Họ tên</th>
                <th>Điện thoại</th>
                <th>Email</th>
                <th>Nhóm</th>
              </tr>
            </thead>
            <tbody>{renderTable()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
