import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

function ModalPopup(props) {
    const { partnerPopupId, title, content, onSave, onClose, isShow } = props

    function handleSave(e) {
        let isChecked = e.target.checked
        onSave(isChecked, partnerPopupId)
    }

    return (
        <Modal show={isShow} onHide={() => onClose(partnerPopupId)} backdrop="static" centered
            onExited={() => {

            }}>
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <div className="form-check form-checkbox-success">
                    <input type="checkbox" className="form-check-input" id="partner-popup" onChange={e => handleSave(e)} />
                    <label className="form-check-label" htmlFor="partner-popup">Không hiển thị lại</label>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

ModalPopup.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    partnerPopupId: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
}

ModalPopup.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    partnerPopupId: 0,
    title: '',
    content: ''
}

export default ModalPopup