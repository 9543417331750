import axios from 'axios'
import PropTypes from 'prop-types'
import React, { Fragment, useRef } from 'react'

const maxSizeInBute = 5_242_880 // 5MB

const noImgUrl = '/assets/images/no-img.jpg'

const apiUrl = window.apiUrl

const UploadProductImg = props => {
    const fileInput = useRef(null)

    const handleTrigger = () => {
        fileInput.current.click()
    }

    const handleUpload = e => {
        let files = e.target.files
        if (files.length !== 0) {
            let file = files[0]
            let name = file.name
            let sizeInByte = file.size
            if (sizeInByte > maxSizeInBute) {
                return
            }
            const formData = new FormData()
            formData.append('formFile', file)
            formData.append('fileName', name)
            const url = uploadUrl();
            axios.post(url, formData).then(res => {
                let data = res.data
                if (props.onCompleted) {
                    props.onCompleted(data)
                }
            })
        }
    }

    const uploadUrl = () => {
        if (props.isPartner) return '/partner/storage/upload-image'
        else return '/admin/storage/upload-image'
    }

    return (
        <Fragment>
            <input
                style={{ display: 'none' }}
                type="file"
                accept=".gif, .jpg, jpeg, .png"
                ref={fileInput}
                onChange={handleUpload} />
            <span
                className={`bg-avatar rounded-circle cursor-point me-${props.me}`}
                style={{ backgroundImage: `url(${(props.imgUrl === '' ? noImgUrl : `${apiUrl}/admin/storage/thumbnail?url=${props.imgUrl}&size=${props.thumbnailSize}`)})` }}
                onClick={handleTrigger}
            />
        </Fragment>
    )
}

UploadProductImg.propTypes = {
    imgUrl: PropTypes.string,
    thumbnailSize: PropTypes.number,
    onCompleted: PropTypes.func,
    me: PropTypes.number,
}

UploadProductImg.defaultProps = {
    imgUrl: '',
    thumbnailSize: 50,
    onCompleted: () => { },
    me: 1
}

export default UploadProductImg