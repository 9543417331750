import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { Notification, Pagination } from './../../../components'
import { Filter, ModalEditWarehouseNote, Table } from './components'

const OrderPage = (props) => {
    const [orderStatuses, setOrderStatuses] = useState([])

    const [orderFroms, setOrderFroms] = useState([])

    const [orders, setOrders] = useState(null)

    const [modalEditWarehouseNoteInfo, setModalEditWarehouseNoteInfo] = useState({
        isShow: false,
        data: null
    })

    const [filters, setFilters] = useState({
        keyword: '',
        fromDate: '',
        toDate: '',
        statusId: 0,
        orderFromId: 0,
        pageItem: 10,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Đơn hàng'

        const loadOrderStatus = () => {
            const url = '/partner/order-status/get-dropdown'
            axios.get(url).then(({ data }) => {
                if (data.IsSuccess) {
                    setOrderStatuses(data.Result)
                }
            })
        }

        const loadOrderFrom = () => {
            const url = '/partner/order-from/get-dropdown'
            axios.get(url).then(({ data }) => {
                if (data.IsSuccess) {
                    setOrderFroms(data.Result)
                }
            })
        }

        loadOrderStatus()
        loadOrderFrom()
    }, [])

    useEffect(() => {
        let param = `keyword=${filters.keyword}&fromDate=${filters.fromDate}&toDate=${filters.toDate}`
        param += `&orderStatusId=${filters.statusId}&paymentStatusId=0&orderFromId=${filters.orderFromId}`
        const url = `/partner/order/filter?${param}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setOrders(data.Result.Data)
            }
        })
    }, [filters])

    return (
        <Fragment>
            <Filter
                onSearch={(params) => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
                orderStatuses={orderStatuses}
                orderFroms={orderFroms}
            />
            <div className="card">
                <div className="card-body">
                    <Table
                        pageItem={filters.pageItem}
                        pageIndex={filters.pageIndex}
                        data={orders}
                        onDelete={(item) => {
                            Notification.confirmHtml('Bạn có chắc muốn hủy đơn hàng', item.OrderId, () => {
                                const url = `/partner/order/cancel?orderId=${item.OrderId}`
                                axios.post(url).then(({ data }) => {
                                    if (data.IsSuccess) {
                                        const temp = [...orders]
                                        const index = temp.findIndex(n => n.OrderId === item.OrderId)
                                        temp[index].OrderStatusId = 5 // Trạng thái hủy
                                        setOrders(temp)
                                        Notification.success('Hủy đơn hàng thành công')
                                    }
                                })
                            })
                        }}
                        onEditWarehouseNote={(item) => {
                            const url = `/partner/order/get-warehouse-note?id=${item.OrderId}`
                            axios.get(url).then(({ data }) => {
                                if (data.IsSuccess) {
                                    setModalEditWarehouseNoteInfo({
                                        isShow: true,
                                        data: data.Result
                                    })
                                }
                            })
                        }}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>
            <ModalEditWarehouseNote
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/partner/order/update-warehouse-note'
                    axios.post(url, formData).then(({ data }) => {
                        if (data.IsSuccess) {
                            setModalEditWarehouseNoteInfo({
                                isShow: false,
                                data: null
                            })
                            Notification.success('Chỉnh sửa ghi chú gửi kho thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setModalEditWarehouseNoteInfo({
                        isShow: false,
                        data: null
                    })
                }}
                isShow={modalEditWarehouseNoteInfo.isShow}
                data={modalEditWarehouseNoteInfo.data}
            />
        </Fragment>
    )
}

export default OrderPage