import PropTypes from 'prop-types'
import { useContext } from 'react'
import { AppContext } from './../../components'
import { Fragment } from 'react'

function AuthAdminView(props) {
    const { right, children } = props

    const appSettings = useContext(AppContext)

    function renderChildrent() {
        if (appSettings.rights.some(n => n === right)) return children
    }

    return (
        <Fragment>
            {renderChildrent()}
        </Fragment>
    )
}

AuthAdminView.propTypes = {
    right: PropTypes.string,
}

AuthAdminView.defaultProps = {
    right: ''
}

export default AuthAdminView