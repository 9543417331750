import React from 'react'
import { IoEllipsisVertical } from 'react-icons/io5'

function ActionRow(props) {
    const { children } = props

    return (
        <div className="dropdown float-end">
            <button className="dropdown-toggle arrow-none card-drop btn-drop" data-bs-toggle="dropdown" aria-expanded="false">
                <IoEllipsisVertical className="fs-4 text-primary" />
            </button>
            <div className="dropdown-menu dropdown-menu-end">
                {children}
            </div>
        </div>
    )
}

export default ActionRow