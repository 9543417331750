import React from 'react'
import PropTypes from 'prop-types'

const noImgUrl = '/assets/images/no-img.jpg'

const apiUrl = window.apiUrl

const AvatarRow = props => {
    const { className, onClick, url, me, size } = props

    const getImgUrl = () => {
        if (url === '' || url === null) return `url(${noImgUrl})`
        else return `url(${apiUrl}/admin/storage/thumbnail?url=${url}&size=${size})`
    }

    const iconClass = () => {
        return `bg-avatar me-${me} rounded-circle`
    }

    const handleClick = () => {
        if (onClick) {
            const fullUrl = `${apiUrl}${url}`
            onClick({
                baseUrl: url,
                fullUrl
            })
        }
    }

    return (
        <span
            onClick={handleClick}
            className={iconClass() + ` ${className}`}
            style={{ backgroundImage: getImgUrl() }}
        ></span>
    )
}

AvatarRow.propTypes = {
    url: PropTypes.string,
    size: PropTypes.number,
    me: PropTypes.number,
    className: PropTypes.string,
    onClick: PropTypes.func,
}

AvatarRow.defaultProps = {
    url: '',
    size: 50,
    me: 1,
    className: '',
    onClick: null
}

export default AvatarRow