import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import * as yup from "yup"
import { CloseButton, SaveButton, InputSelect } from './../../../../../components'

const invalidValue = 'Giá trị không hợp lệ'

const schema = yup.object().shape({
    GroupName: yup.string().required('Vui lòng nhập tên nhóm').max(100, 'Tên nhóm quá dài'),
    DropShipFee: yup.number()
        .negative(invalidValue)
        .integer(invalidValue)
        .min(0, invalidValue)
        .max(2_000_000_000, invalidValue),
    StatusId: yup.number(),
    Note: yup.string().max(200, 'Ghi chú quá dài')
})

const statusOptions = [
    {
        value: 1,
        label: 'Hoạt động'
    },
    {
        value: 2,
        label: 'Tạm khóa'
    }
]

const ModalEdit = props => {
    const { control, register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const [val, setVal] = useState(0)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" animation={true}
            onExited={() => {
                reset()
                setVal(0)
            }}
            onShow={() => {
                let data = props.data
                setValue('GroupName', data.GroupName)
                setValue('DropShipFee', data.DropShipFee)
                setValue('StatusId', data.StatusId)
                setValue('Note', data.Note)

                setVal(data.DropShipFee)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa nhóm cộng tác viên</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tên nhóm <span className="text-danger">*</span></label>
                        <input {...register('GroupName')} type="text" className="form-control" placeholder="Tên nhóm" />
                        {errors.GroupName && <div className="validation-message">{errors.GroupName.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Phí dropship <span className="text-danger">*</span></label>
                        <div className="input-group">
                            <NumberFormat
                                className="form-control"
                                // thousandSeparator="."
                                // decimalSeparator=","
                                allowNegative={false}
                                defaultValue={0}
                                value={val}
                                onValueChange={(c) => {
                                    let num = c.vallue !== '' ? c.value : 0
                                    setVal(num)
                                    setValue('DropShipFee', num)
                                }}
                                decimalScale={0}
                            />
                            <span className="input-group-text">VNĐ</span>
                        </div>
                        {errors.DropShipFee && <div className="validation-message">{errors.DropShipFee.message}</div>}
                    </div>

                    <div className="relative-position mb-2">
                        <label className="form-label">Trạng thái</label>
                        <Controller
                            name="StatusId"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <InputSelect
                                        options={statusOptions}
                                        defaultValue={1}
                                        onChange={onChange}
                                        value={value}
                                        variant='success'
                                    />
                                )
                            }}
                        />
                        {errors.StatusId && <div className="validation-message">{errors.StatusId.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Ghi chú</label>
                        <textarea {...register('Note')} rows="3" className="form-control" placeholder="Ghi chú"></textarea>
                        {errors.Note && <div className="validation-message">{errors.Note.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEdit.propTypes = {
    data: PropTypes.object,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    isBusy: PropTypes.bool
}

ModalEdit.defaultProps = {
    data: {},
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    isBusy: false
}

export default ModalEdit