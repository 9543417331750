import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { Pagination } from '../../../components'
import { Filter, Table } from './components'

const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' }

const now = new Date()

const StatisticPartnerOrder = () => {
    const [partners, setPartners] = useState(null)

    const [filters, setFilters] = useState({
        fromDate: new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString('vi-VN', dateOptions),
        toDate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString('vi-VN', dateOptions),
        pageItem: 10,
        pageIndex: 1
    })

    const [pagination, setPagination] = useState({
        totalRow: 0,
        totalPage: 0,
        pageIndex: 1
    })

    const [detail, setDetail] = useState({})

    useEffect(() => {
        const url = `/admin/statistic/order-by-partner?fromDate=${filters.fromDate}&toDate=${filters.toDate}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                const newPagination = {
                    totalRow: data.Result.TotalRow,
                    totalPage: data.Result.TotalPage,
                    pageIndex: data.Result.PageIndex
                }
                setPagination(newPagination)
                setPartners(data.Result.Data)
            }
        })
    }, [filters])

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Thống kê đơn hàng'
    }, []);

    const handleRowClick = item => {
        const partnerId = item.PartnerId
        let lst = [...partners];
        let newindex = lst.findIndex(n => n.PartnerId === item.PartnerId);
        let newItem = lst[newindex];
        let prevIndex = lst.findIndex(n => n.isSelected);
        if (prevIndex > -1) {
            let prev = lst[prevIndex];
            if (prev.PartnerId === newItem.PartnerId) {
                clearDetail()
                newItem.isSelected = false;
            }
            else {
                loadDetail(partnerId)
                prev.isSelected = false;
                newItem.isSelected = true;
            }
        }
        else {
            loadDetail(partnerId)
            newItem.isSelected = true;
        }
        setPartners(lst);
    }

    function loadDetail(partnerId) {
        const url = `/admin/statistic/order-by-partner-detail?partnerId=${partnerId}&fromDate=${filters.fromDate}&toDate=${filters.toDate}`
        const request = axios.get(url)
        request.then(({ data }) => {
            if (data.IsSuccess) {
                setDetail(data.Result)
            }
        })
    }

    function clearDetail() {
        setDetail({})
    }

    return (
        <Fragment>
            <Filter
                onSearch={(params) => {
                    const newFilters = {
                        ...filters,
                        ...params,
                        pageIndex: 1
                    }
                    setFilters(newFilters)
                }}
            />
            <div className="card">
                <div className="card-body">
                    <Table
                        pageItem={filters.pageItem}
                        pageIndex={filters.pageIndex}
                        data={partners}
                        onOpenDetail={(item) => {
                            handleRowClick(item)
                        }}
                        detail={detail}
                    />
                    <Pagination
                        totalPage={pagination.totalPage}
                        currentPage={pagination.pageIndex}
                        onPageChange={(pIndex) => {
                            const newPagination = { ...pagination, pageIndex: pIndex }
                            setPagination(newPagination)
                            const newFilters = {
                                ...filters,
                                pageIndex: pIndex
                            }
                            setFilters(newFilters)
                        }}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default StatisticPartnerOrder;