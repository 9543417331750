import React from 'react'
import PropTypes from 'prop-types'
import { LoadingRow, EmptyRow, FormatNumber, AvatarRow } from './../../../../../components'

function BestSellingProduct(props) {
    const { products } = props

    function renderTable() {
        if (products === null) return <LoadingRow colSpan={5} />
        else if (products.length === 0) return <EmptyRow colSpan={5} />
        else return products.map(item => <tr key={item.ProductId}>
            <td style={{ width: '60.38px' }}>
                <AvatarRow url={item.AvatarUrl} me={0} />
            </td>
            <td>
                <p className="mb-1">{item.ProductName}</p>
                <p className="text-muted mb-0">{item.CategoryName}</p>
            </td>
            <td className="text-end">
                <p className="mb-1 text-primary"><FormatNumber>{item.OutputPrice}</FormatNumber></p>
                <p className="text-muted mb-0">Giá đề xuất</p>
            </td>
            <td className="text-end">
                <p className="mb-1 text-primary"><FormatNumber>{item.Quantity}</FormatNumber></p>
                <p className="text-muted mb-0">Số lượng</p>
            </td>
            <td className="text-end">
                <p className="mb-1 text-primary"><FormatNumber>{item.OutputPrice * item.Quantity}</FormatNumber></p>
                <p className="text-muted mb-0">Thành tiền</p>
            </td>
        </tr>)
    }

    return (
        <div className="card">
            <div className="card-body" style={{ paddingBottom: '1.7rem' }}>
                <h5 className="card-title mb-2">SẢN PHẨM BÁN CHẠY TRONG THÁNG</h5>
                <div className="table-responsive">
                    <table className="table table-centered mb-0">
                        <tbody>
                            {renderTable()}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

BestSellingProduct.propTypes = {
    products: PropTypes.array,
}

BestSellingProduct.defaultProps = {
    products: []
}

export default BestSellingProduct