import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { DetailRow, FormatNumber } from './../../../../../components'

function Detail(props) {
    const { detail } = props

    function renderDetail() {
        if (detail !== {}) {
            return (
                <Fragment>
                    <DetailRow title="Họ tên">{detail.FullName}</DetailRow>
                    <DetailRow title="Email">{detail.Email}</DetailRow>
                    <DetailRow title="Phone">{detail.Email}</DetailRow>
                    <DetailRow title="Số dư">
                        <span className="text-primary">
                            <FormatNumber>
                                {detail.Balance}
                            </FormatNumber>
                        </span>
                    </DetailRow>
                    <DetailRow title="Nhóm"><span className={detail.GroupId === 0 ? 'text-danger' : ''}>{detail.GroupName}</span></DetailRow>
                    <DetailRow title="Trạng thái">{detail.StatusId === 1 ? <span className="text-primary">Hoạt động</span> : <span className="text-danger">Tạm khóa</span>}</DetailRow>
                    <DetailRow title="Địa chỉ">{detail.Address}</DetailRow>
                    <DetailRow title="Ngày tạo">{detail.CreatedDate}<small className="text-muted"> {detail.CreatedTime}</small></DetailRow>
                    <DetailRow title="Tên shop">{detail.ShopName}</DetailRow>
                    <DetailRow title="Ghi chú">{detail.Note}</DetailRow>
                    <DetailRow title="GHTK token">{detail.GHTKToken}</DetailRow>
                    <DetailRow title="Miễn phí đóng gói"><span className='text-primary'>{detail.FreeShipQty}</span></DetailRow>
                    <DetailRow className='col-sm-12 col-md-6 col-lg-6 col-xl-8' title="Mã giới thiệu"><span className='text-primary'>{detail.PartnerCode}</span></DetailRow>
                    <DetailRow className='col-sm-12 col-md-6 col-lg-6 col-xl-4' title="Người giới thiệu"><span className='text-primary'>{detail.RefFullName}</span></DetailRow>
                </Fragment>
            )
        }
    }

    return (
        <div className="row">
            {renderDetail()}
        </div>
    )
}

Detail.propTypes = {
    detail: PropTypes.object,
}

Detail.defaultProps = {
    detail: {}
}

export default Detail