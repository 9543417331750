import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { IconButton, Notification, AuthAdminView } from '../../../components'
import { ModalAdd, ModalEdit, Table } from './components'

const PartnerGroupPage = () => {
    useEffect(() => {
        document.getElementById('page-header').innerText = 'Nhóm cộng tác viên'
        loadPartnerGroup()
    }, [])

    // State danh sách nhóm CTV
    const [partnerGroup, setPartnerGroup] = useState(null)

    // State lưu data sửa nhóm CTV
    const [editValue, setEditValue] = useState({})

    // State đóng mở modal sửa nhóm CTV
    const [isShowModalPartnerGroupEdit, setIsShowModalPartnerGroupEdit] = useState(false)

    // State lưu trạng thái đóng mở modal thêm mới nhóm CTV
    const [isShowModalPartnerGroupAdd, setIsShowModalPartnerGroupAdd] = useState(false)

    // Load danh sách nhóm cộng tác viên
    const loadPartnerGroup = () => {
        let url = '/admin/partner-group/get-all'
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                setPartnerGroup(data.Result)
            }
        });
    }

    // Mở modal sửa nhóm cộng tác viên và load chi tiết
    const handleOpenModalEdit = (item) => {
        let url = `/admin/partner-group/get-by-id?id=${item.GroupId}`
        axios.get(url).then(res => {
            let data = res.data
            setEditValue(data.Result)
            setIsShowModalPartnerGroupEdit(true)
        })
    }

    // Đóng modal sửa nhóm cộng tác viên
    const handleCloseModalEdit = () => setIsShowModalPartnerGroupEdit(false)

    // Lưu sửa nhóm cộng tác viên
    const handleSavePartnerGroupEdit = (formVal, setIsBusy) => {
        setIsBusy(true)
        let url = '/admin/partner-group/update'
        axios.post(url, {
            GroupId: editValue.GroupId,
            GroupName: formVal.GroupName,
            DropShipFee: formVal.DropShipFee,
            StatusId: formVal.StatusId,
            Note: formVal.Note
        }).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                loadPartnerGroup()
                setIsShowModalPartnerGroupEdit(false)
                Notification.success('Chỉnh sửa nhóm cộng tác viên thành công')
            }
            setIsBusy(false)
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="partner_group_add">
                                <IconButton
                                    className="mb-2"
                                    text="Thêm mới"
                                    icon={<IoAddCircleOutline />}
                                    onClick={() => {
                                        setIsShowModalPartnerGroupAdd(true)
                                    }}
                                />
                            </AuthAdminView>

                        </div>
                    </div>
                    <Table
                        data={partnerGroup}
                        onEdit={handleOpenModalEdit} />
                </div>
            </div>

            <ModalAdd
                onSave={(formData, setIsBusy) => {
                    setIsBusy(true)
                    if (formData.DropShipFee === undefined) {
                        formData.DropShipFee = 0
                    }
                    const url = '/admin/partner-group/create'
                    axios.post(url, formData).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            loadPartnerGroup()
                            setIsShowModalPartnerGroupAdd(false)
                            Notification.success('Thêm mới nhóm cộng tác viên thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalPartnerGroupAdd(false)
                }}
                isShow={isShowModalPartnerGroupAdd}
                data={editValue} />

            <ModalEdit
                onSave={handleSavePartnerGroupEdit}
                onClose={handleCloseModalEdit}
                isShow={isShowModalPartnerGroupEdit}
                data={editValue} />
        </Fragment>
    )
}

export default PartnerGroupPage