import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {
  Filter,
  Table,
  ModalUpdateStatus,
  ModalEditWarehouseNote,
  ModalEdit,
} from "./components";
import { Pagination, Notification } from "./../../../components";
import ImageViewer from "react-simple-image-viewer";

const downloadFile = (data, fileName) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const OrderPage = () => {
  const [orderStatuses, setOrderStatuses] = useState([]);

  const [orderFroms, setOrderFroms] = useState([]);

  const [filters, setFilters] = useState({
    orderId: "",
    partnerId: 0,
    wayBillCode: "",
    fromDate: "",
    toDate: "",
    statusId: 0,
    orderFromId: 0,
    pageItem: 10,
    pageIndex: 1,
  });

  const [pagination, setPagination] = useState({
    totalRow: 0,
    totalPage: 0,
    pageIndex: 1,
  });

  const [orders, setOrders] = useState(null);

  const [modalStatus, setModalStatus] = useState({
    isShow: false,
    statusId: 0,
    orderId: 0,
  });

  const [modalEditWarehouseNote, setModalEditWarehouseNote] = useState({
    isShow: false,
    data: {},
  });

  const [modalOrderEditInfo, setModalOrderEditInfo] = useState({
    isShow: false,
    detail: {},
  });

  const [isShowViewer, setIsShowViewer] = useState(false);

  const [viewerList, setViewerList] = useState([]);

  useEffect(() => {
    let url = `/admin/order/filter?orderId=${filters.orderId}&partnerId=${filters.partnerId}&waybillCode=${filters.wayBillCode}`;
    url += `&fromDate=${filters.fromDate}&toDate=${filters.toDate}&orderStatusId=${filters.statusId}`;
    url += `&paymentStatusId=0&orderFromId=${filters.orderFromId}&pageItem=${filters.pageItem}&pageIndex=${filters.pageIndex}`;
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        const newPagination = {
          totalRow: data.Result.TotalRow,
          totalPage: data.Result.TotalPage,
          pageIndex: data.Result.PageIndex,
        };
        setPagination(newPagination);
        setOrders(data.Result.Data);
      }
    });
  }, [filters]);

  useEffect(() => {
    document.getElementById("page-header").innerText = "Đơn hàng";

    loadOrderStatus();
    loadOrderFrom();

    loadShippingUnit();
    loadProvince();
    loadShippingMethod();
  }, []);

  const loadOrderStatus = () => {
    const url = "/admin/order-status/get-dropdown";
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setOrderStatuses(data.Result);
      }
    });
  };

  const loadOrderFrom = () => {
    const url = "/admin/order-from/get-dropdown";
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setOrderFroms(data.Result);
      }
    });
  };

  const [provinceList, setProvinceList] = useState([]);

  const [districtList, setDistrictList] = useState([]);

  const [wardList, setWardList] = useState([]);

  const [shippingUnits, setShippingUnits] = useState([]);

  const [shippingMethods, setShippingMethods] = useState([]);

  const loadProvince = () => {
    const url = "/admin/province/get-all";
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setProvinceList(data.Result);
      }
    });
  };

  const loadDistrict = (provinceId) => {
    const url = `/admin/province/${provinceId}/districts`;
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setDistrictList(data.Result);
      }
    });
  };

  const loadWard = (wardId) => {
    const url = `/admin/district/${wardId}/wards`;
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setWardList(data.Result);
      }
    });
  };

  const loadShippingUnit = () => {
    const url = "/admin/shipping-unit/get-dropdown";
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setShippingUnits(data.Result);
      }
    });
  };

  const loadShippingMethod = () => {
    const url = "/admin/shipping-method/get-dropdown";
    axios.get(url).then(({ data }) => {
      if (data.IsSuccess) {
        setShippingMethods(data.Result);
      }
    });
  };

  const reloadOrder = () => {
    setFilters({
      ...filters,
      pageIndex: 1,
    });
  };

  return (
    <Fragment>
      <Filter
        onSearch={(params) => {
          const newFilters = {
            ...filters,
            ...params,
            pageIndex: 1,
          };
          setFilters(newFilters);
        }}
        statuses={orderStatuses}
        orderFroms={orderFroms}
      />
      <div className="card">
        <div className="card-body">
          <Table
            data={orders}
            onDownloadWaybill={(order) => {
              const url = `/admin/order/download-waybill?orderId=${order.OrderId}`;
              axios.get(url, { responseType: "blob" }).then(({ data }) => {
                const fileName = `${order.ShopeeOrderSn}.pdf`;
                downloadFile(data, fileName);
              });
            }}
            onEdit={(order) => {
              const url = `/admin/order/detail?orderId=${order.OrderId}`;
              axios.get(url).then(({ data }) => {
                if (data.IsSuccess) {
                  const districts = data.Result.Districts;
                  setDistrictList(districts);
                  const wards = data.Result.Wards;
                  setWardList(wards);
                  setModalOrderEditInfo({
                    isShow: true,
                    detail: data.Result,
                  });
                }
              });
            }}
            onEditWarehouseNote={(order) => {
              const url = `/admin/order/get-warehouse-note?id=${order.OrderId}`;
              axios.get(url).then(({ data }) => {
                if (data.IsSuccess) {
                  setModalEditWarehouseNote({
                    isShow: true,
                    data: data.Result,
                  });
                }
              });
            }}
            onDelete={(order) => {
              Notification.confirmHtml(
                "Bạn có chắc muốn xóa đơn hàng",
                order.OrderId,
                () => {
                  const url = `/admin/order/delete?id=${order.OrderId}`;
                  axios.post(url).then(({ data }) => {
                    if (data.IsSuccess) {
                      reloadOrder();
                      Notification.success("Xóa đơn hàng thành công");
                    }
                  });
                }
              );
            }}
            onUpdateStatus={(order) => {
              setModalStatus({
                isShow: true,
                statusId: order.OrderStatusId,
                orderId: order.OrderId,
              });
            }}
            onAvatarClick={(urlObj) => {
              setIsShowViewer(true);
              setViewerList([urlObj.fullUrl]);
            }}
            onGetWaybill={(order) => {
              const url = `/admin/order/get-waybill?orderId=${order.OrderId}`;
              axios.post(url).then(({ data }) => {
                if (data.IsSuccess) {
                  Notification.success("Tải lại vận đơn thành công");
                }
              });
            }}
            onDownloadAttachWaybill={(order) => {
              const url = `${window.apiUrl}${order.FileUrl}`;
              window.open(url);
            }}
          />
          <Pagination
            totalPage={pagination.totalPage}
            currentPage={pagination.pageIndex}
            onPageChange={(pIndex) => {
              const newPagination = { ...pagination, pageIndex: pIndex };
              setPagination(newPagination);
              const newFilters = {
                ...filters,
                pageIndex: pIndex,
              };
              setFilters(newFilters);
            }}
          />
        </div>
      </div>
      <ModalUpdateStatus
        onSave={(formData, setIsBusy) => {
          setIsBusy(true);
          const url = `/admin/order/update-status?orderId=${formData.orderId}&statusId=${formData.statusId}`;
          axios.post(url).then(({ data }) => {
            if (data.IsSuccess) {
              const orderList = [...orders];
              const index = orderList.findIndex(
                (n) => n.OrderId === formData.orderId
              );
              orderList[index].OrderStatusId = formData.statusId;
              orderList[index].OrderStatusName = data.Result.OrderStatusName;
              setOrders(orderList);
              setModalStatus({
                isShow: false,
                statusId: 0,
                orderId: 0,
              });
              Notification.success("Sửa trạng thái đơn hàng thành công");
            }
            setIsBusy(false);
          });
        }}
        onClose={() => {
          setModalStatus({
            isShow: false,
            statusId: 0,
            orderId: 0,
          });
        }}
        statuses={orderStatuses}
        statusId={modalStatus.statusId}
        orderId={modalStatus.orderId}
        isShow={modalStatus.isShow}
      />
      <ModalEditWarehouseNote
        onSave={(formData, setIsBusy) => {
          setIsBusy(true);
          const url = "/admin/order/update-warehouse-note";
          axios.post(url, formData).then(({ data }) => {
            if (data.IsSuccess) {
              setModalEditWarehouseNote({
                isShow: false,
                data: {},
              });
              Notification.success("Chỉnh sửa ghi chú gửi kho thành công");
            }
            setIsBusy(false);
          });
        }}
        onClose={() => {
          setModalEditWarehouseNote({
            isShow: false,
            data: {},
          });
        }}
        isShow={modalEditWarehouseNote.isShow}
        data={modalEditWarehouseNote.data}
      />
      <ModalEdit
        isShow={modalOrderEditInfo.isShow}
        onSave={(formData, setIsBusy) => {
          setIsBusy(true);
          const url = "/admin/order/update";
          axios.post(url, formData).then(({ data }) => {
            if (data.IsSuccess) {
              reloadOrder();
              setModalOrderEditInfo({
                isShow: false,
                detail: {},
              });
              Notification.success("Chỉnh sửa đơn hàng thành công");
            }
            setIsBusy(false);
          });
        }}
        onClose={() => {
          setModalOrderEditInfo({
            isShow: false,
            detail: {},
          });
        }}
        provinces={provinceList}
        onProvinceChange={(provinceId) => {
          loadDistrict(provinceId);
        }}
        districts={districtList}
        onDistrictChange={(districtId) => {
          loadWard(districtId);
        }}
        wards={wardList}
        shippingUnits={shippingUnits}
        shippingMethods={shippingMethods}
        dropShipFee={0}
        detail={modalOrderEditInfo.detail}
        removeProduct={(productId) => {
          const products = [...modalOrderEditInfo.detail.Products];
          const newProducts = products.filter((n) => n.ProductId !== productId);
          setModalOrderEditInfo({
            ...modalOrderEditInfo,
            detail: {
              ...modalOrderEditInfo.detail,
              Products: newProducts,
            },
          });
        }}
      />
      {isShowViewer && (
        <ImageViewer
          src={viewerList}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => {
            setIsShowViewer(false);
          }}
        />
      )}
    </Fragment>
  );
};

export default OrderPage;
