import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton, CustomSelect, InputSelect } from './../../../../../components'
import PropTypes from 'prop-types'

const schema = yup.object().shape({
    UserId: yup.number(),
    FullName: yup.string().required('Vui lòng nhập họ tên').max(100, 'Họ tên quá dài'),
    UserName: yup.string().required('Vui lòng nhập tài khoản').max(70, 'Tài khoản quá dài'),
    Email: yup.string().required('Vui lòng nhập email').email('Email không đúng').max(70, 'Email quá dài'),
    Phone: yup.string().required('Vui lòng nhập số điện thoại').max(20, 'Số điện thoại quá dài'),
    StatusId: yup.number(),
    RoleId: yup.number().required('Vui lòng chọn vai trò')
})

const statusOptions = [
    {
        value: 1,
        label: 'Hoạt động'
    },
    {
        value: 2,
        label: 'Tạm khóa'
    }
]

function ModalEditUser(props) {
    const { control, register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const [roleOptions, setRoleOptions] = useState([])

    useEffect(() => {
        const roles = props.role.map(item => {
            return {
                value: item.RoleId,
                label: item.RoleName
            }
        })
        setRoleOptions(roles)
    }, [props.role])

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                let info = props.info
                setValue('UserId', info.UserId)
                setValue('FullName', info.FullName)
                setValue('UserName', info.UserName)
                setValue('Email', info.Email)
                setValue('Phone', info.Phone)
                setValue('StatusId', info.StatusId)
                setValue('RoleId', info.RoleId)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa thành viên</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => props.onSave(formData, setIsBusy))}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Họ tên <span className="text-danger">*</span></label>
                        <input {...register('FullName')} type="text" className="form-control" placeholder="Họ tên" />
                        {errors.FullName && <div className="validation-message">{errors.FullName.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tài khoản <span className="text-danger">*</span></label>
                        <input {...register('UserName')} type="text" className="form-control" placeholder="Tài khoản" />
                        {errors.UserName && <div className="validation-message">{errors.UserName.message}</div>}
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="relative-position mb-2">
                                <label className="form-label">Email <span className="text-danger">*</span></label>
                                <input {...register('Email')} type="text" className="form-control" placeholder="Email" />
                                {errors.Email && <div className="validation-message">{errors.Email.message}</div>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="relative-position mb-2">
                                <label className="form-label">Điện thoại <span className="text-danger">*</span></label>
                                <input {...register('Phone')} type="text" className="form-control" placeholder="Điện thoại" />
                                {errors.Phone && <div className="validation-message">{errors.Phone.message}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Vai trò <span className="text-danger">*</span></label>
                        <Controller
                            control={control}
                            name="RoleId"
                            render={({
                                field: { onChange, value }
                            }) => (
                                <CustomSelect
                                    isClearable={false}
                                    isSearchable={false}
                                    options={roleOptions}
                                    value={value}
                                    onChange={onChange}
                                    placeholder='Vai trò'
                                />
                            )}
                        />
                        {errors.RoleId && <div className="validation-message">{errors.RoleId.message}</div>}
                    </div>
                    <div className="relative-position">
                        <label className="form-label">Trạng thái</label>
                        <Controller
                            control={control}
                            name="StatusId"
                            render={({
                                field: { onChange, value }
                            }) => (
                                <InputSelect
                                    variant='success'
                                    options={statusOptions}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                        {errors.StatusId && <div className="validation-message">{errors.StatusId.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalEditUser.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    role: PropTypes.array,
    info: PropTypes.object
}

ModalEditUser.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    role: [],
    info: {}
}

export default ModalEditUser