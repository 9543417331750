import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'

const schema = yup.object().shape({
    NewPassword: yup.string().required('Vui lòng nhập mật khẩu mới').max(70, 'Mật khẩu quá dài')
})

function ModalUserSetPwd(props) {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static"
            onExited={() => {
                reset()
            }}
            onShow={() => {
                setValue('UserId', props.userId)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Đặt lại mật khẩu</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => props.onSave(formData, setIsBusy))}>
                <Modal.Body>
                    <div className="relative-position">
                        <label className="form-label">Mật khẩu mới <span className="text-danger">*</span></label>
                        <input {...register('NewPassword')} type="password" className="form-control" placeholder="Mật khẩu mới" />
                        {errors.NewPassword && <div className="validation-message">{errors.NewPassword.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalUserSetPwd.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    userId: PropTypes.number
}

ModalUserSetPwd.defaultProps = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    userId: 0
}

export default ModalUserSetPwd

