import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { Notification, AppContext } from '../../../components'
import { Profile, Password } from './components'

function ProfilePage(props) {
    const appSettings = useContext(AppContext)

    useEffect(() => {
        document.getElementById('page-header').innerText = 'Lịch sử giao dịch'
        loadProvince()
        loadProfile()
    }, [])

    const [provinces, setProvinces] = useState([])

    const [districts, setDistricts] = useState([])

    const [wards, setWards] = useState([])

    const [profileInfo, setProfileInfo] = useState(null)

    const loadProvince = () => {
        const url = '/partner/province/get-all'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setProvinces(data.Result)
            }
        })
    }

    const loadDistrict = (provinceId) => {
        const url = `/partner/province/${provinceId}/districts`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setDistricts(data.Result)
            }
        })
    }

    const loadWard = (wardId) => {
        const url = `/partner/district/${wardId}/wards`
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setWards(data.Result)
            }
        })
    }

    const loadProfile = () => {
        const url = '/partner/partner/get-profile'
        axios.get(url).then(({ data }) => {
            if (data.IsSuccess) {
                setWards(data.Result.Wards)
                setDistricts(data.Result.Districts)
                setProfileInfo(data.Result.Info)
            }
        })
    }

    return (
        <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-6">
                <Profile
                    partnerInfo={profileInfo}
                    onSave={(formData, setIsBusy) => {
                        setIsBusy(true)
                        const url = '/partner/partner/update-profile'
                        axios.post(url, formData).then(({ data }) => {
                            if (data.IsSuccess) {
                                appSettings.setPartnerFullName(formData.FullName)
                                localStorage.setItem('p-fullName', formData.FullName)
                                appSettings.setPartnerAvatarUrl(formData.AvatarUrl)
                                localStorage.setItem('p-avatarUrl', formData.AvatarUrl)
                                Notification.success('Chỉnh sửa thông tin thành công')
                            }
                            setIsBusy(false)
                        })
                    }}
                    provinces={provinces}
                    onProvinceChange={(provinceId) => {
                        loadDistrict(provinceId)
                        setWards([])
                    }}
                    districts={districts}
                    onDistrictChange={(districtId) => {
                        loadWard(districtId)
                    }}
                    wards={wards}
                />
            </div>
            <div className="col-md-12 col-lg-12 col-xl-6">
                <Password
                    onSave={(formData, setIsBusy, resetForm) => {
                        setIsBusy(true)
                        const url = '/partner/partner/change-password'
                        axios.post(url, formData).then(({ data }) => {
                            if (data.IsSuccess) {
                                resetForm()
                                Notification.success('Thay đổi mật khẩu thành công')
                            }
                            setIsBusy(false)
                        })
                    }}
                />
            </div>
        </div>
    )
}

export default ProfilePage