import axios from 'axios'
import React, { Fragment, useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { IconButton, Notification, AuthAdminView } from './../../../components'
import { ModalAdd, ModalEdit, ModalPermission, Table } from './components'

function RolePage(props) {
    useEffect(() => {
        document.getElementById('page-header').innerText = 'Vai trò'
        loadRole()
    }, [])

    const [isShowModalRoleAdd, setIsShowModalRoleAdd] = useState(false)

    const [infoModalRoleEdit, setInfoModalRoleEdit] = useState({
        isShow: false,
        info: {}
    })

    const [infoModalPer, setInfoModalPer] = useState({
        isShow: false,
        info: [],
        roleId: 0
    })

    const [roleList, setRoleList] = useState(null)

    const loadRole = () => {
        const url = '/admin/role/get-all'
        axios.get(url).then(res => {
            let data = res.data
            if (data.IsSuccess) {
                setRoleList(data.Result)
            }
        })
    }

    return (
        <Fragment>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <AuthAdminView right="role_add">
                                <IconButton
                                    className="mb-2"
                                    text="Thêm mới"
                                    icon={<IoAddCircleOutline />}
                                    onClick={() => {
                                        setIsShowModalRoleAdd(true)
                                    }}
                                />
                            </AuthAdminView>
                        </div>
                    </div>
                    <Table
                        onEdit={item => {
                            const url = `/admin/role/get-by-id?id=${item.RoleId}`
                            axios.get(url).then(res => {
                                let data = res.data
                                if (data.IsSuccess) {
                                    setInfoModalRoleEdit({
                                        isShow: true,
                                        info: data.Result
                                    })
                                }
                            })

                        }}
                        onPermission={item => {
                            const url = `/admin/role/get-permission?id=${item.RoleId}`
                            axios.get(url).then(res => {
                                let data = res.data
                                if (data.IsSuccess) {
                                    setInfoModalPer({
                                        isShow: true,
                                        info: data.Result,
                                        roleId: item.RoleId
                                    })
                                }
                            })
                        }}
                        onDelete={item => {
                            Notification.confirmHtml('Bạn có chắc muốn xóa vai trò', item.RoleName, () => {
                                const url = `/admin/role/delete?id=${item.RoleId}`
                                axios.post(url).then(res => {
                                    const data = res.data
                                    if (data.IsSuccess) {
                                        loadRole()
                                        Notification.success('Xóa vai trò thành công')
                                    }
                                })
                            })
                        }}
                        data={roleList}
                    />
                </div>
            </div>

            <ModalAdd
                onSave={(formData, setIsBusy) => {
                    const url = '/admin/role/create'
                    setIsBusy(true)
                    axios.post(url, formData).then(res => {
                        const data = res.data
                        if (data.IsSuccess) {
                            loadRole()
                            setIsShowModalRoleAdd(false)
                            Notification.success('Thêm mới vai trò thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setIsShowModalRoleAdd(false)
                }}
                isShow={isShowModalRoleAdd}
            />

            <ModalEdit
                onSave={(formData, setIsBusy) => {
                    const url = '/admin/role/update'
                    setIsBusy(true)
                    axios.post(url, formData).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            loadRole()
                            setInfoModalRoleEdit({
                                isShow: false,
                                info: {}
                            })
                            Notification.success('Chỉnh sửa vai trò thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setInfoModalRoleEdit({
                        isShow: false,
                        info: {}
                    })
                }}
                isShow={infoModalRoleEdit.isShow}
                info={infoModalRoleEdit.info}
            />

            <ModalPermission
                onSave={(rightList, roleId, setIsBusy) => {
                    setIsBusy(true)
                    const url = '/admin/role/update-permission'
                    const reqInfo = {
                        RoleId: roleId,
                        Rights: rightList
                    }
                    axios.post(url, reqInfo).then(res => {
                        let data = res.data
                        if (data.IsSuccess) {
                            setInfoModalPer({
                                isShow: false,
                                info: [],
                                roleId: 0
                            })
                            Notification.success('Phân quyền vai trò thành công')
                        }
                        setIsBusy(false)
                    })
                }}
                onClose={() => {
                    setInfoModalPer({
                        isShow: false,
                        info: [],
                        roleId: 0
                    })
                }}
                isShow={infoModalPer.isShow}
                info={infoModalPer.info}
                roleId={infoModalPer.roleId}
            />
        </Fragment>
    )
}

export default RolePage