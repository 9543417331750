import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import * as yup from "yup"
import { CloseButton, SaveButton } from './../../../../../components'
import { debounce } from 'lodash'

const schema = yup.object().shape({
    Title: yup.string().required('Vui lòng nhập tiêu đề').max(200, 'Tiêu đề quá dài'),
    PopupTypeId: yup.mixed().oneOf([1, 2, 3], 'Vui lòng chọn loại popup'),
    PartnerId: yup.number().when('PopupTypeId', {
        is: 1,
        then: yup.number().required('Vui lòng chọn cộng tác viên')
    }),
    GroupId: yup.number().when('PopupTypeId', {
        is: 3,
        then: yup.number().required('Vui lòng chọn nhóm cộng tác viên')
    }),
    Content: yup.string().required('Vui lòng nhập nội dung').max(4_000, 'Nội dung quá dài')
})

const poupTypes = [
    { value: 0, label: 'Loại popup' },
    { value: 1, label: 'Cộng tác viên' },
    { value: 2, label: 'Cộng tác viên chưa kích hoạt' },
    { value: 3, label: 'Nhóm cộng tác viên' }
]

const loadOptions = (inputValue, callback) => {
    const url = `/admin/partner/dropdown-filter?keyword=${inputValue}&pageItem=5&pageIndex=1`
    axios.get(url).then(({ data }) => {
        if (data.IsSuccess) {
            let partnerLst = data.Result.Data.map(item => {
                return {
                    value: item.PartnerId,
                    label: item.FullName + ` (${item.Email})`
                }
            })
            callback(partnerLst)
        }
    })
}

const ModalAdd = props => {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [isBusy, setIsBusy] = useState(false)

    const [popupType, setPopupType] = useState(0)

    const [isShowCTV, setIsShowCTV] = useState(false)

    const [isShowGroup, setIsShowGroup] = useState(false)

    useEffect(() => {
        if (popupType !== 0) {
            switch (popupType) {
                case 1:
                    setIsShowCTV(true)
                    setIsShowGroup(false)
                    break
                case 2:
                    setIsShowCTV(false)
                    setIsShowGroup(false)
                    break
                default: // 3
                    setIsShowCTV(false)
                    setIsShowGroup(true)
                    break
            }
        }
        else {
            setIsShowCTV(false)
            setIsShowGroup(false)
        }
    }, [popupType])

    const renderPartner = () => {
        if (isShowCTV) {
            return <div className="relative-position mb-2">
                <label className="form-label">Cộng tác viên <span className="text-danger">*</span></label>
                <AsyncSelect
                    className="react-select-container"
                    classNamePrefix="custom-select"
                    placeholder='Cộng tác viên'
                    cacheOptions
                    defaultOptions
                    loadOptions={debounce(loadOptions, 250)}
                    onChange={item => setValue('PartnerId', item.value)}
                    loadingMessage={() => "Đang tìm kiếm"}
                    noOptionsMessage={() => "Không tìm thấy dữ liệu"}
                />
                {errors.PartnerId && <div className="validation-message">{errors.PartnerId.message}</div>}
            </div>
        }
    }

    const renderGroup = () => {
        if (isShowGroup) {
            const partnerGroup = props.partnerGroup.map(item => {
                return {
                    value: item.GroupId,
                    label: item.GroupName
                }
            })
            return <div className="relative-position mb-2">
                <label className="form-label">Nhóm cộng tác viên <span className="text-danger">*</span></label>
                <Select
                    className="react-select-container"
                    classNamePrefix="custom-select"
                    defaultValue={partnerGroup[0]}
                    isSearchable={false}
                    options={partnerGroup}
                    onChange={item => {
                        setValue('GroupId', item.value)
                    }}
                />
            </div>
        }
    }

    return (
        <Modal show={props.isShow} onHide={props.onClose} backdrop="static" animation={true}
            onExited={() => {
                reset()
                setIsShowCTV(false)
                setIsShowGroup(false)
            }}
            onShow={() => {
                setValue("PopupTypeId", 0)
            }}>
            <Modal.Header closeButton>
                <Modal.Title>Thêm mới popup</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(formData => {
                props.onSave(formData, setIsBusy)
            })}>
                <Modal.Body>
                    <div className="relative-position mb-2">
                        <label className="form-label">Tiêu đề <span className="text-danger">*</span></label>
                        <input {...register('Title')} type="text" className="form-control" placeholder="Tiêu đề" />
                        {errors.Title && <div className="validation-message">{errors.Title.message}</div>}
                    </div>
                    <div className="relative-position mb-2">
                        <label className="form-label">Loại popup <span className="text-danger">*</span></label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="custom-select"
                            defaultValue={poupTypes[0]}
                            isSearchable={false}
                            options={poupTypes}
                            onChange={item => {
                                setPopupType(item.value)
                                setValue("PopupTypeId", item.value)
                            }}
                        />
                        {errors.PopupTypeId && <div className="validation-message">{errors.PopupTypeId.message}</div>}
                    </div>
                    {renderPartner()}
                    {renderGroup()}
                    <div className="relative-position">
                        <label className="form-label">Nội dung <span className="text-danger">*</span></label>
                        <textarea {...register('Content')} rows="3" className="form-control" placeholder="Nội dung"></textarea>
                        {errors.Content && <div className="validation-message">{errors.Content.message}</div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SaveButton isBusy={isBusy} />
                    <CloseButton onClick={props.onClose} />
                </Modal.Footer>
            </form>
        </Modal>
    )
}

ModalAdd.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    isShow: PropTypes.bool,
    partnerGroup: PropTypes.array
}

ModalAdd.defaultPropts = {
    onSave: () => { },
    onClose: () => { },
    isShow: false,
    partnerGroup: []
}

export default ModalAdd