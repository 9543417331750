import React from 'react'
import PropTypes from 'prop-types'
import { IoExtensionPuzzleOutline, IoArrowUpOutline, IoArrowDownOutline } from 'react-icons/io5'
import { FormatNumber } from './../../../../../components'

function OrderBlock(props) {
    const { icon, header, val, percent } = props

    function renderUpDown() {
        if (val < 0) return <IoArrowDownOutline className="mt--2-px" />
        else return <IoArrowUpOutline className="mt--2-px" />
    }

    return (
        <div className="card widget-flat">
            <div className="card-body">
                <div className="float-end">
                    <span className="widget-icon">
                        {icon}
                    </span>
                </div>
                <h5 className="text-muted fw-normal mt-0">{header}</h5>
                <h3 className="mt-3 mb-3">
                    <FormatNumber maxDigit={2}>
                        {val}
                    </FormatNumber>
                </h3>
                <p className="mb-0 text-muted">
                    <span className={val < 0 ? 'text-danger me-2' : 'text-success me-2'}>
                        {renderUpDown()}
                        <FormatNumber maxDigit={2}>{percent}</FormatNumber>
                        %
                    </span>
                    <span className="text-nowrap">So với hôm trước</span>
                </p>
            </div>
        </div>
    )
}

OrderBlock.propTypes = {
    icon: PropTypes.element,
    header: PropTypes.string,
    val: PropTypes.number,
    percent: PropTypes.number,
}

OrderBlock.defaultProps = {
    icon: <IoExtensionPuzzleOutline />,
    header: 'Title',
    val: 0,
    percent: 0
}

export default OrderBlock