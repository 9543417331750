import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

// const getLevel = (data, parentId, level) => {
//     let result = []
//     const lst = data.filter(n => n.ParentId === parentId)
//     if (lst.length !== 0) {
//         const aLevel = level + 1
//         lst.forEach((item) => {
//             result.push({
//                 ...item,
//                 Level: level
//             })
//             const temp = getLevel(data, item.CategoryId, aLevel)
//             temp.forEach(cat => {
//                 result.push(cat)
//             })
//         })
//     }
//     return result
// }

const getCategoryLevel = (data, parentId, catName) => {
    let result = []
    const lst = data.filter(n => n.ParentId === parentId)
    if (lst.length !== 0) {
        lst.forEach((item) => {
            const levelCatName = `${catName !== '' ? `${catName} > ` : ''}${item.CategoryName}`
            result.push({
                ...item,
                Label: `${catName !== '' ? `${catName} > ` : ''}${item.CategoryName}`
            })
            const temp = getCategoryLevel(data, item.CategoryId, levelCatName)
            temp.forEach(cat => {
                result.push(cat)
            })
        })
    }
    return result
}

// const getLabel = (catName, level) => {
//     let str = ''
//     for (let index = 0; index < level; index++) {
//         str += '--- '
//     }
//     str += catName
//     return str
// }

function CategoryDropdownTree(props) {
    const { options, onChange, value, isClearable } = props

    const [categories, setCategories] = useState([])

    const [category, setCategory] = useState(null)

    useEffect(() => {
        if (options.length !== 0) {
            // const cats = getLevel(options, 0, 0).map(item => {
            //     return {
            //         label: getLabel(item.CategoryName, item.Level),
            //         value: item.CategoryId
            //     }
            // })
            const cats = getCategoryLevel(options, 0, '').map(item => {
                return {
                    label: item.Label,
                    value: item.CategoryId
                }
            })
            setCategories(cats)
        }
    }, [options])

    useEffect(() => {
        if (categories.length !== 0) {
            const tempList = categories.filter(n => n.value === value)
            if (tempList.length !== 0) {
                const selectedValue = tempList[0]
                setCategory(selectedValue)
            }
            else {
                setCategory(0)
            }
        }

        // eslint-disable-next-line
    }, [value])

    return (
        <Select
            className="react-select-container"
            classNamePrefix="custom-select"
            isSearchable={false}
            isClearable={isClearable}
            options={categories}
            value={category}
            onChange={item => {
                if (item !== null) {
                    onChange(item.value)
                }
                else {
                    onChange(0)
                }
            }}
            placeholder='Nhóm sản phẩm'
        />
    )
}

CategoryDropdownTree.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.number,
    isClearable: PropTypes.bool,
}

CategoryDropdownTree.defaultProps = {
    options: [],
    onChange: () => { },
    value: 0,
    isClearable: true
}

export default CategoryDropdownTree