import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { IoKeyOutline } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from "yup"
import { SaveButton, AppContext, Config } from './../../../components'

const schema = yup.object().shape({
    UserName: yup.string().required('Vui lòng nhập tài khoản').max(70, 'Địa chỉ email quá dài'),
    Password: yup.string().required('Vui lòng nhập mật khẩu').max(70, 'Mật khẩu quá dài')
})

function LoginPage(props) {
    const { register, handleSubmit, setFocus, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    const [message, setMessage] = useState('')

    const history = useHistory()

    const [isBusy, setIsBusy] = useState(() => {
        const a_token = localStorage.getItem('a-token')
        if (a_token === null || a_token === '') return false
        else return true
    })

    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/admin/dashboard" } };

    const appSettings = useContext(AppContext)

    const setInfo = (data) => {
        localStorage.setItem('a-right', data.Result.Rights)
        localStorage.setItem('a-roleName', data.Result.RoleName)
        localStorage.setItem('a-fullName', data.Result.FullName)
        localStorage.setItem('a-token', data.Result.Token)
        localStorage.setItem('a-avatarUrl', data.Result.AvatarUrl)
        axios.defaults.headers.common['A-Token'] = data.Result.Token

        const rights = data.Result.Rights.split(';')
        appSettings.setRights(rights)

        appSettings.setAdminFullName(data.Result.FullName)
        appSettings.setAdminAvatarUrl(data.Result.AvatarUrl)
        appSettings.setAdminRole(data.Result.RoleName)
    }

    useEffect(() => {
        setFocus('UserName')
    }, [setFocus])

    useEffect(() => {
        const a_token = localStorage.getItem('a-token')
        if (a_token !== null && a_token !== '') {
            const url = '/admin/user/login-token'
            axios.post(url).then(({ data }) => {
                if (data.IsSuccess) {
                    setInfo(data)
                    history.replace(from);
                }
                else {
                    setIsBusy(false)
                }
            })
        }
        else {
            setIsBusy(false)
        }

        // eslint-disable-next-line
    }, [])

    const onLogin = (formData) => {
        setIsBusy(true)
        const url = '/admin/user/login'
        axios.post(url, formData).then(({ data }) => {
            if (data.IsSuccess) {
                setInfo(data)
                history.replace(from)
            }
            else {
                if (data.Message !== '' || data.Message !== null) {
                    setMessage(data.Message)
                    setTimeout(() => {
                        setMessage('')
                    }, 3_000)
                }
            }
            setIsBusy(false)
        })
    }

    return (
        <div className="authentication-bg">

            <div className="account-pages mb-5" style={{ marginTop: '7.5rem' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-4 col-lg-5">
                            <div className="card">

                                <div className="card-header pt-3 pb-3 text-center bg-light">
                                    <span>
                                        <span><img src="/assets/images/logo-dark.png" alt="Logo" height={Config.adminLoginLogoHeight} /></span>
                                    </span>
                                </div>

                                <div className="card-body p-4">

                                    <div className="text-center w-75 m-auto">
                                        <h4 className="text-dark-50 text-center mt-0 font-weight-bold">Đăng nhập</h4>
                                    </div>

                                    <form onSubmit={handleSubmit(formData => onLogin(formData))}>

                                        <div className="position-relative mb-2">
                                            <label className="form-label">Tài khoản</label>
                                            <input {...register('UserName')} className="form-control" placeholder="Tài khoản" autoComplete="off"
                                                disabled={isBusy} />
                                            {errors.UserName && <div className="validation-message">{errors.UserName.message}</div>}
                                        </div>

                                        <div className="position-relative mb-2">
                                            <label className="form-label">Mật khẩu</label>
                                            <input {...register('Password')} type="password" className="form-control" placeholder="Mật khẩu" autoComplete="off"
                                                disabled={isBusy} />
                                            {errors.Password && <div className="validation-message">{errors.Password.message}</div>}
                                        </div>

                                        {
                                            message !== '' ? <div>
                                                <div className="alert alert-danger alert-dismissible fade show">
                                                    <button type="button" className="btn-close"
                                                        onClick={() => {
                                                            setMessage('')
                                                        }}
                                                    ></button>
                                                    {message}
                                                </div>
                                            </div> : ''
                                        }

                                        <div className="form-group mb-0 text-center">
                                            <SaveButton
                                                title="Đăng nhập"
                                                isBusy={isBusy}
                                                icon={<IoKeyOutline className="mt--3-px me-1 font-17" />}
                                            />
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

            <footer className="footer footer-alt">
                Copyright &copy; {new Date().getFullYear()} Rado Vietnam
            </footer>
        </div >
    )
}

export default LoginPage