import React from 'react'
import PropTypes from 'prop-types'
import { EmptyRow, LoadingRow, FormatNumber } from '../../../../../components'

const renderTable = (data, pageItem, pageIndex) => {
    if (data === null) return <LoadingRow colSpan={6} />
    else if (data.length === 0) return <EmptyRow colSpan={6} />
    else return data.map((item, index) => <tr key={item.PartnerBalanceHistoryId}>
        <td className="text-center">
            <FormatNumber>
                {(pageItem * (pageIndex - 1)) + index + 1}
            </FormatNumber>
        </td>
        <td>{item.Detail}</td>
        <td>{item.Note}</td>
        <td className="text-end text-primary">
            <FormatNumber>
                {item.Amount}
            </FormatNumber>
        </td>
        <td>{renderType(item.Amount)}</td>
        <td>
            {item.CreatedDate}
            <small className="text-muted"> {item.CreatedTime}</small>
        </td>
    </tr>)
}

const renderType = amount => {
    if (amount > 0) return <span className="badge bg-success">Cộng</span>
    else return <span className="badge bg-danger">Trừ</span>
}

const Table = (props) => {
    return (
        <div className="table-responsive">
            <table className="table table-centered mb-0">
                <thead>
                    <tr>
                        <th className="w-52-px">#</th>
                        <th>Giao dịch</th>
                        <th>Ghi chú</th>
                        <th className='text-end'>Số tiền</th>
                        <th>Loại</th>
                        <th>Ngày giao dịch</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTable(props.data, props.pageItem, props.pageIndex)}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.array,
    pageItem: PropTypes.number,
    pageIndex: PropTypes.number
}

Table.defaultProps = {
    data: null,
    pageItem: 0,
    pageIndex: 0
}

export default Table